import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Button } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import WifiIcon from '@material-ui/icons/Wifi'
import FlagIcon from '@material-ui/icons/Flag'
import WarningIcon from '@material-ui/icons/Warning'
import { colors } from 'config/colors'
import { ButtonCard } from 'components/atoms/styles/commonStyles'
import { SpinnerContainer } from '../members/newListStyle'

function BookingPanelCard({ item, setBookingSelected, updateData, setState, state, activeClient }) {
  const [overBtn, setOver] = useState(false)

  let theName = item.member !== null ? `${item.member?.user.first_name} ${item.member?.user.last_name}` : '-'
  if (item.guest !== null) theName = `${item.guest.name} (${item.member?.user.first_name} ${item.member?.user.last_name})`
  if (item.guest !== null && item.member === null) theName = `${item.guest.name}`
  const start = item.planned_start?.split('T')
  const end = item.planned_end?.split('T')
  const theTimes = `${start[1].slice(0, 5)}-${end[1].slice(0, 5)}`

  function formatRes() {
    const waData = item.work_area?.name?.split(' ')
    const waLetters = []
    waData?.map(word => waLetters.push(word.charAt(0)))
    let res = ''
    let color = colors.gray
    switch (item.resource) {
      case null:
        res = waLetters.toString().replace(/,/g, '').toUpperCase()
        break
      case item.real_start !== null:
        color = colors.green
        break
      default:
        res = item.resource.name
    }
    if (item.real_start !== null) color = colors.green
    return <Grid style={{ fontSize: '16px', fontWeight: 700, color, margin: 0 }}>{res}</Grid>
  }

  function formatPrice() {
    let thePrice = ''
    switch (item.invoice_line?.status) {
      case 'pending_payment':
        thePrice = (
          <Grid style={{ fontSize: '16px', fontWeight: 900, color: colors.red }}>
            {item.membership === null ? `£${item.single_price}` : 'PRE-PAID'}
          </Grid>
        )
        break
      default:
        thePrice = (
          <Grid
            style={{ fontSize: '16px', fontWeight: 900, color: item.origin === 'trial' ? colors.gray : colors.green }}
          >
            {item.membership === null ? `£${item.single_price}` : 'PRE-PAID'}
          </Grid>
        )
    }
    if (item.origin === 'deference') thePrice = <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.gray }}>Complimentary</Grid>
    if (item.origin === 'trial') thePrice = <Grid style={{ fontSize: '16px', fontWeight: 900, color: colors.gray }}>Trial</Grid>
    if (item.origin === 'referral') {
      thePrice = (
        <Grid style={{ fontSize: '16px', fontWeight: 900, color: colors.gray }}>
          {`£${item.single_price - (item.single_price ? item.single_price : 0)}`}
        </Grid>
      )
    }

    return thePrice
  }

  function formatStatus() {
    let status = ''
    switch (item.status) {
      case 'active':
        status = ''
        break
      case 'finished':
        status = <FlagIcon />
        break
      case 'alert':
        status = <WarningIcon style={{ color: colors.red }} />
        break
      default:
        status = 'default'
    }
    return status
  }

  function overOptions() {
    let option = ''
    switch (item.resource) {
      case null:
        option = 'Assign'
        break
      default:
        option = 'Check In'
        if (item.real_start !== null) option = item.resource.name
    }

    return <Grid style={{ fontSize: '16px', fontWeight: 700 }}>{option}</Grid>
  }

  function updateModalData(e) {
    if (e.target.innerText !== 'Check In' && e.target.innerText !== 'Assign') updateData(item)
    else e.preventDefault()
  }

  useEffect(() => {}, [overBtn])
  let mainName = item.client?.name.toUpperCase()
  let secondaryName = theName
  let thirdName = ''
  if (item.client.slug === activeClient.slug) {
    if (item.team) {
      mainName = item.team?.name.toUpperCase()
    }
  } else {
    secondaryName = item.team ? item.team?.name.toUpperCase() : theName
    thirdName = item.team ? theName : ''
  }

  return (
    <Grid container style={{ boxShadow: '2px 3px 8px rgba(0, 0, 0, 0.2)' }}>
      {item !== undefined ? (
        <ButtonCard style={{ margin: 0, padding: 0 }} onClick={e => updateModalData(e)}>
          <Grid
            container
            direction='column'
            style={{ border: `1px solid ${colors.softgray}`, borderRadius: '5px', padding: '10px' }}
            sx={{ boxShadow: 2 }}
          >
            <Grid item>
              <Grid container direction='row' justify='space-between'>
                <Grid item>
                  <Grid container direction='column' justify='flex-start'>
                    <Grid item style={{ fontSize: '20px', textAlign: 'start' }}>
                      {mainName}
                    </Grid>
                    <Grid item style={{ fontSize: '16px', textAlign: 'start', color: colors.gray }}>
                      {secondaryName}
                    </Grid>
                    {thirdName !== '' && (
                      <Grid item style={{ fontSize: '16px', textAlign: 'start', color: colors.gray }}>
                        {thirdName}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' alignItems='center' spacing={2} style={{ marginRight: '5px' }}>
                    <Grid item>
                      <PeopleIcon color={colors.softgray} />
                    </Grid>
                    <Grid item>{theTimes}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginTop: '15px' }}>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <Button
                    style={{ width: '100%', margin: 0, padding: 0 }}
                    name='btn-resource'
                    onMouseOver={() => setOver(true)}
                    onMouseOut={() => setOver(false)}
                    onClick={() => {
                      if (state === 'selecting') setBookingSelected(item.code)
                      setState('assigning')
                    }}
                  >
                    {overBtn === false ? formatRes() : overOptions()}
                  </Button>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  {formatPrice()}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  <WifiIcon
                    style={{
                      color:
                        item.member?.presence === 'network_on' || item.member?.presence === 'checked_in'
                          ? colors.green
                          : colors.gray,
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  {formatStatus()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ButtonCard>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

BookingPanelCard.propTypes = {
  item: PropTypes.object,
  setBookingSelected: PropTypes.func,
  updateData: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.string,
  activeClient: PropTypes.object,
}

BookingPanelCard.defaultProps = {
  item: () => {},
  setBookingSelected: () => {},
  updateData: () => {},
  setState: () => {},
  state: 'selecting',
  activeClient: () => {},
}

export default BookingPanelCard
