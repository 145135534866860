import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Modal,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import formatDates from 'components/atoms/formatDates/formatDates'
import {
  getDashboardGuests,
  getDashboardMemberships,
  getDashboardOffices,
  getDashboardResources,
  getDashboardWorkAreas,
  getSingleBooking,
  newGetMembers,
  newGetTeams,
  updateBooking,
} from 'api'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import SingleBookingsList from 'components/organisms/bookings/singleBookingsList'
import SingleInvoicesList from 'components/organisms/invoices/singleInvoicesList'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import SingleOrdersList from 'components/organisms/members/singleOrdersList'
import { Autocomplete } from '@material-ui/lab'
import { SmallError } from '../resourcesAdd/formStyle'

function BookingSingle({ office }) {
  const params = useParams()
  const [theB, setBooking] = useState()

  function getBookingData() {
    getSingleBooking(params.code).then(r => setBooking(r))
  }
  useEffect(() => {
    if (theB === undefined) getBookingData()
    console.log(theB)
  }, [])

  const [teamsData, setTeamsData] = useState(null)
  const [membersData, setMembersData] = useState(null)
  const [msData, setMSData] = useState(null)
  const [resData, setResData] = useState(null)
  const [waData, setWAData] = useState(null)
  const [officesData, setOfficesData] = useState(null)
  const [guestsData, setGuestData] = useState(null)

  const query = {
    currentPage: 1,
    pageSize: 1000,
    filter: {
      layoutOffice: office.slug,
    },
  }

  function getTeamsData(data) {
    if (data !== undefined) query.filter.name = data
    newGetTeams(query).then(r => setTeamsData(r))
  }
  function getMembersData(data) {
    if (data !== undefined) query.filter.name = data
    newGetMembers(query).then(r => setMembersData(r))
  }
  function getMSData() {
    getDashboardMemberships(query).then(r => setMSData(r))
  }
  function getResData() {
    getDashboardResources(query).then(r => setResData(r))
  }
  function getWAData() {
    getDashboardWorkAreas(query).then(r => setWAData(r))
  }
  function getOfficesData() {
    getDashboardOffices(query).then(r => setOfficesData(r))
  }
  function getGuestsData() {
    getDashboardGuests(query).then(r => setGuestData(r))
  }
  useEffect(() => {
    if (teamsData === null) getTeamsData()
  }, [teamsData])
  useEffect(() => {
    if (membersData === null) getMembersData()
  }, [membersData])
  useEffect(() => {
    if (msData === null) getMSData()
  }, [msData])
  useEffect(() => {
    if (resData === null) getResData()
  }, [resData])
  useEffect(() => {
    if (waData === null) getWAData()
  }, [waData])
  useEffect(() => {
    if (officesData === null) getOfficesData()
  }, [officesData])
  useEffect(() => {
    if (guestsData === null) getGuestsData()
  }, [guestsData])

  const [errors, setErrors] = useState({
    booking_type: null,
    origin: null,
    status: null,
    office: null,
    work_area: null,
    planned_start: null,
    planned_end: null,
    member: null,
    guest: null,
    team: null,
  })

  const [modalFeedback, setModalFeedback] = useState(false)

  function updateTheBooking() {
    setModalFeedback(true)
    console.log(theB)
    const theBooking = {
      office: theB.office.slug,
      invoice_line: theB.invoice_line?.slug,
      work_area: theB.work_area.slug,
      planned_start: formatDates(theB.planned_start, 'day_start'),
      planned_end: formatDates(theB.planned_end, 'day_end'),
      member: theB.member !== null && theB.member !== undefined && theB.member !== '' ? theB.member.slug : null,
      guest: theB.guest !== null && theB.guest !== undefined && theB.guest !== '' ? theB.guest.slug : null,
      team: theB.team !== null && theB.team !== undefined && theB.team !== '' ? theB.team.slug : null,
      resource:
        theB.resource !== null && theB.resource !== undefined && theB.resource !== '' ? theB.resource.slug : null,
      membership:
        theB.membership !== null && theB.membership !== undefined && theB.membership !== ''
          ? theB.membership.slug
          : null,
    }
    console.log(theBooking)
    updateBooking(theB.code, theBooking).then(
      setTimeout(() => {
        setModalFeedback(false)
      }, 1000)
    )
  }

  function checkInBooking() {
    setModalFeedback(true)
    const theStart = formatDates(new Date(), 'time')
    const theBooking = {
      ...theB,
      office: theB.office.slug,
      invoice_line: theB.invoice_line?.slug,
      work_area: theB.work_area.slug,
      planned_start: theB.planned_start,
      planned_end: theB.planned_end,
      real_start: theStart,
      member: theB.member !== null && theB.member !== undefined && theB.member !== '' ? theB.member.slug : null,
      guest: theB.guest !== null && theB.guest !== undefined && theB.guest !== '' ? theB.guest.slug : null,
      team: theB.team !== null && theB.team !== undefined && theB.team !== '' ? theB.team.slug : null,
      resource:
        theB.resource !== null && theB.resource !== undefined && theB.resource !== '' ? theB.resource.slug : null,
      membership:
        theB.membership !== null && theB.membership !== undefined && theB.membership !== ''
          ? theB.membership.slug
          : null,
    }
    updateBooking(theB.code, theBooking).then(
      setTimeout(() => {
        setModalFeedback(true)
        window.location.reload()
      }, 2000)
    )
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      booking_type: 'Booking type is required',
      origin: 'Origin is required',
      status: 'Status is required',
      office: 'Office is required',
      work_area: 'Work area is required',
      planned_start: 'Planned Start is required',
      planned_end: 'Planned End is required',
      badChoose: 'Select a member, team or guest is mandatory',
      badTime: 'Planned end can not be before planned start',
    }

    const typeRes = theB.booking_type === ('' || undefined || null) ? textErrors.booking_type : null
    const originRes = theB.origin === ('' || undefined || null) ? textErrors.origin : null
    const statusRes = theB.status === ('' || undefined || null) ? textErrors.status : null
    const officeRes = theB.office === ('' || undefined || null) ? textErrors.office : null
    const waRes = theB.work_area === ('' || undefined || null) ? textErrors.work_area : null
    const startRes = theB.planned_start === ('' || undefined || null) ? textErrors.planned_start : null
    let endRes = theB.planned_end === ('' || undefined || null) ? textErrors.planned_end : null
    if (theB.planned_end < theB.planned_start) endRes = textErrors.badTime
    let memberRes = null
    let guestRes = null
    let teamRes = null
    if (
      theB.member === ('' || undefined || null) &&
      theB.guest === ('' || undefined || null) &&
      theB.team === ('' || undefined || null)
    ) {
      memberRes = textErrors.badChoose
      guestRes = textErrors.badChoose
      teamRes = textErrors.badChoose
    }

    setErrors({
      booking_type: typeRes,
      origin: originRes,
      status: statusRes,
      office: officeRes,
      work_area: waRes,
      planned_start: startRes,
      planned_end: endRes,
      member: memberRes,
      guest: guestRes,
      team: teamRes,
    })

    if (
      typeRes === null &&
      originRes === null &&
      statusRes === null &&
      officeRes === null &&
      waRes === null &&
      startRes === null &&
      endRes === null &&
      memberRes === null &&
      guestRes === null &&
      teamRes === null
    ) canUpdate = true
    if (canUpdate === true) updateTheBooking()
  }

  useEffect(() => {}, [errors])

  function cancelBooking(type) {
    updateBooking(theB.code, {
      status: type,
    }).then(
      setTimeout(() => {
        window.location.reload()
      }, 500)
    )
  }

  let theMS = []
  if (msData !== undefined) theMS = msData?.memberships
  if (theB?.team !== null && theMS !== undefined) theMS = theMS.filter(ms => ms?.team?.slug === theB?.team?.slug)

  return (
    <Grid container>
      <Modal open={modalFeedback === true}>
        <FeedbackModal type='booking-update-single' />
      </Modal>
      {theB !== undefined ? (
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Grid container justify='space-between' spacing={1}>
              {theB.status === 'finished' || theB.status === 'cancelled' ? (
                ''
              ) : (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant='contained'
                        onClick={() => cancelBooking('cancelled')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        onClick={() => cancelBooking('refund')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                      >
                        Cancel & Refund
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant='outlined' color='secondary' onClick={() => checkInBooking()}>
                      Check In
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary' onClick={() => validate()}>
                      Update Booking
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SingleTitleSection>General Info</SingleTitleSection>
          <Grid item>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginTop: '-7px', marginLeft: '14px' }}>Booking Type</InputLabel>
                  <Select
                    variant='outlined'
                    label='Booking Type'
                    value={theB.booking_type}
                    onChange={e => setBooking({ ...theB, booking_type: e.target.value })}
                    error={errors.booking_type !== null}
                    disabled
                  >
                    <MenuItem value='hot_desk'>Hot Desk</MenuItem>
                    <MenuItem value='desk'>Desk</MenuItem>
                    <MenuItem value='meeting_room'>Meeting Room</MenuItem>
                    <MenuItem value='suite'>Private office</MenuItem>
                  </Select>
                  {errors.booking_type !== null ? <SmallError>{errors.booking_type}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    label='Create Date'
                    value={theB.create_date}
                    format='yyyy-MM-dd'
                    autoOk
                    onChange={value => setBooking({ ...theB, booking_type: value })}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Origin</InputLabel>
                  <Select
                    variant='outlined'
                    value={theB.origin}
                    label='Origin'
                    onChange={e => setBooking({ ...theB, origin: e.target.value })}
                    error={errors.origin !== null}
                  >
                    <MenuItem value='regular'>Regular</MenuItem>
                    <MenuItem value='referral'>Referral</MenuItem>
                    <MenuItem value='trial'>Trial</MenuItem>
                    <MenuItem value='complimentary'>Complimentary</MenuItem>
                  </Select>
                  {errors.origin !== null ? <SmallError>{errors.origin}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Status</InputLabel>
                  <Select
                    variant='outlined'
                    value={theB.status}
                    label='Status'
                    onChange={e => setBooking({ ...theB, status: e.target.value })}
                    error={errors.status !== null}
                  >
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='inactive'>Inactive</MenuItem>
                    <MenuItem value='alert'>Alert</MenuItem>
                    <MenuItem value='finished'>Finished</MenuItem>
                    <MenuItem value='cancelled'>Cancelled</MenuItem>
                  </Select>
                  {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    variant='outlined'
                    label='Single Price'
                    value={theB.single_price}
                    type='number'
                    onChange={e => (e.target.value >= 0 ? setBooking({ ...theB, single_price: e.target.value }) : 0)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    disablePast
                    label='Planned Start'
                    value={theB.planned_start}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, planned_start: value })}
                    error={errors.planned_start !== null}
                  />
                  {errors.planned_start !== null ? <SmallError>{errors.planned_start}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    disablePast
                    inputVariant='outlined'
                    label='Planned End'
                    value={theB.planned_end}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, planned_end: value })}
                    error={errors.planned_end !== null}
                  />
                  {errors.planned_end !== null ? <SmallError>{errors.planned_end}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    disablePast
                    inputVariant='outlined'
                    label='Real Start'
                    value={theB.real_start}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, real_start: value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    disablePast
                    inputVariant='outlined'
                    label='Real End'
                    value={theB.real_end}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, real_end: value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Office' error={errors.office !== null} />
                    )}
                    value={theB.office !== (undefined || null) ? theB.office : null}
                    options={officesData !== null ? officesData.offices : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, office: data })
                      else setBooking({ ...theB, office: null })
                    }}
                    error={errors.office !== null}
                  />
                  {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Work Area' error={errors.work_area !== null} />
                    )}
                    value={theB.work_area !== (undefined || null) ? theB.work_area : null}
                    options={waData !== null ? waData.work_areas : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, work_area: data })
                      else setBooking({ ...theB, work_area: null })
                    }}
                    error={errors.work_area !== null}
                  />
                  {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => <TextField variant='outlined' {...p} label='Resource' />}
                    value={theB.resource !== (undefined || null) ? theB.resource : null}
                    options={resData !== null ? resData.resources : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, resource: data })
                      else setBooking({ ...theB, resource: null })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Comments'
                onChange={e => setBooking({ ...theB, comments: e.target.value })}
                multiline
                rows={3}
                rowsMax={3}
              />
            </FormControl>
          </Grid>
          <SingleTitleSection>Member Info</SingleTitleSection>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Member' error={errors.member !== null} />
                    )}
                    value={theB.member !== (undefined || null) ? theB.member : null}
                    options={
                      membersData !== null ? membersData.members : [{ user: { first_name: 'No', last_name: 'data' } }]
                    }
                    getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                    onChange={(e, data) => {
                      console.log(data)
                      if (data !== null) setBooking({ ...theB, member: data })
                      else setBooking({ ...theB, member: null })
                    }}
                    onInputChange={(e, data) => getMembersData(data)}
                    error={errors.member !== null}
                  />
                  {errors.member !== null ? <SmallError>{errors.member}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Guest' error={errors.guest !== null} />
                    )}
                    value={theB.guest !== (undefined || null) ? theB.guest : null}
                    options={guestsData !== null ? guestsData.guests : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      console.log(data)
                      if (data !== null) setBooking({ ...theB, guest: data })
                      else setBooking({ ...theB, guest: null })
                    }}
                    error={errors.guest !== null}
                  />
                  {errors.guest !== null ? <SmallError>{errors.guest}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => <TextField variant='outlined' {...p} label='Team' error={errors.team !== null} />}
                    value={theB.team !== (undefined || null) ? theB.team : null}
                    options={teamsData !== null ? teamsData.teams : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, team: data })
                      else setBooking({ ...theB, team: null })
                    }}
                    onInputChange={(e, data) => getTeamsData(data)}
                    error={errors.team !== null}
                  />
                  {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => <TextField variant='outlined' {...p} label='Membership Plan' />}
                    value={theB.membership !== (undefined || null) ? theB.membership : null}
                    options={msData !== null ? theMS : [{ plan_membership: { name: 'no data' } }]}
                    getOptionLabel={option => option.plan_membership.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, membership: data })
                      else setBooking({ ...theB, membership: null })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {theB.invoice_line !== null ? <SingleInvoicesList invoices={[theB.invoice_line]} /> : ''}
          {theB?.orders !== undefined ? <SingleOrdersList orders={theB?.orders} /> : ''}
          {theB?.related_bookings !== undefined ? <SingleBookingsList bookings={theB.related_bookings} /> : ''}
        </Grid>
      ) : (
        <CircularProgress color='secondary' />
      )}
    </Grid>
  )
}

BookingSingle.propTypes = {
  office: PropTypes.object,
}

BookingSingle.defaultProps = {
  office: {},
}

export default BookingSingle
