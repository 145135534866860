function formatWords(value) {
  let word = value
  if (value === 'open_space') word = 'Open Space'
  if (value === 'office') word = 'Office'
  if (value === 'outodoor') word = 'Outdoor'
  if (value === 'hot_desk') word = 'Hot Desk'
  if (value === 'meeting_room') word = 'Meeting Room'
  if (value === 'active') word = 'Active'
  if (value === 'desk') word = 'Desk'
  if (value === 'inactive') word = 'Inactive'
  if (value === 'suite') word = 'Suite'
  if (value === 'out') word = 'Out'
  if (value === 'checked_in') word = 'Checked in'
  if (value === 'pending_payment') word = 'Pending'
  if (value === 'payment_pending') word = 'Pending'
  if (value === 'paid') word = 'Paid'
  if (value === 'pre_paid') word = 'Pre paid'
  if (value === 'former') word = 'Former'
  if (value === 'portal') word = 'Portal'
  if (value === 'false') word = 'False'
  if (value === 'true') word = 'True'
  if (value === 'open') word = 'Open'
  if (value === 'void') word = 'Void'
  if (value === 'booked') word = 'Booked'
  if (value === 'available') word = 'Available'
  if (value === 'bookings') word = 'Bookings'
  if (value === 'community') word = 'Community'
  if (value === 'fandd') word = 'Food & Drinks'
  if (value === 'marketing') word = 'Marketing'
  if (value === 'space') word = 'Space'
  if (value === 'dashboard') word = 'Dashboard'
  if (value === 'users') word = 'Members'
  if (value === 'work') word = 'Work'
  if (value === 'event') word = 'Event'
  if (value === 'finished') word = 'Finished'
  if (value === 'pending') word = 'Pending'
  if (value === 'office_manager') word = 'Office Manager'
  if (value === 'club_manager') word = 'Club Manager'
  if (value === 'club_owner') word = 'Club Owner'
  if (value === 'private') word = 'Private'
  if (value === 'public') word = 'Public'

  return word
}

export default formatWords
