import { FormControl, TextField, Button, Grid, Checkbox, Modal, CircularProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import { FormContainer } from 'components/pages/resourcesAdd/formStyle'
import { Autocomplete } from '@material-ui/lab'
import { colors } from 'config/colors'
import {
  calculateBookingPrice,
  getFloorResources,
  getGuestByTeam,
  getMembersByTeam,
  getSpaces,
  newGetTeams,
  setBooking,
  updateBooking,
  getClientsReferral,
} from 'api'
import FloorMap from 'components/molecules/floorMap/floorMap'
import { SmallButton, SpinnerContainer } from '../members/newListStyle'
import PanelInfo from './PanelInfoDer'
import { ModalCard } from './modalStyles'

function CreateBookingDesk({ office, activeClient }) {
  const [teamsData, setTeamsData] = useState()
  const [theBooking, setBookingData] = useState({
    start: new Date(),
  })
  const [spaces, setSpacesData] = useState({})
  const [selectedDesk, setSelectedData] = useState([])
  const [resourcesData, setResourcesData] = useState()

  const [membersRows, setMembersRows] = useState()
  const [guestsRows, setGuestsRows] = useState()
  const [prepaidsRows, setPrepaidsRows] = useState()
  const [priceInfo, setPrice] = useState()

  const [finalBookings, setFinal] = useState([])

  const [infoPanel, setPanel] = useState()
  const [filterPanel, setFilterPanel] = useState('all')

  const [dragResource, setDragResource] = useState()
  const [modalBooking, setModalBooking] = useState(false)

  const [bookingSelected, setBookingSelected] = useState()
  const [floorMap, setFloorMap] = useState({})

  const [state, setState] = useState('selecting')

  const [theClients, setClients] = useState()
  const [theTeams, setTeams] = useState()

  const [emptySearch] = useState([{ name: 'Type something' }])
  const [searchClient, setSearchClient] = useState('')
  const [searchTeam, setSearchTeam] = useState('')

  useEffect(() => {}, [state])

  function getResourcesData(data) {
    const query = {
      filter: {
        floor: data?.floor === undefined || data?.floor === '' ? office?.floors[0]?.slug : data?.floor,
      },
    }
    getFloorResources(query).then(response => {
      setResourcesData(response)
    })
  }

  function getTeamData(info) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: info !== undefined ? info : '',
        order: 'name',
        layoutOffice: office.slug,
      },
    }
    newGetTeams(query).then(response => setTeamsData(response))
  }

  function formatDates(date, type) {
    if (date !== undefined && date !== null && date !== '') {
      const dateSplit = date.toString().split(' ')
      let t = 'T18:00:00'
      if (type === 'start') t = 'T09:00:00'
      if (type === 'start_day') t = 'T00:00:00'
      if (type === 'end_day') t = 'T23:59:59'
      const slash = '-'
      if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
      if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
      if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
      if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
      if (dateSplit[1] === 'May') dateSplit[1] = '05'
      if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
      if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
      if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
      if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
      if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
      if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
      if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
      const dateFormatted = `${dateSplit[3] + slash + dateSplit[1] + slash + dateSplit[2] + t}`
      return dateFormatted
    }
    return ''
  }

  function getDataSpaces(data) {
    console.log('Data looking fos spaces', theBooking?.start)
    if (office?.floors === undefined) return
    const query = {
      start:
        data?.start === undefined ? formatDates(theBooking?.start, 'start_day') : formatDates(data?.start, 'start_day'),
      end: data?.start === undefined ? formatDates(theBooking?.start, 'end_day') : formatDates(data?.start, 'end_day'),
      office: office.slug,
      bookingType: 'hot_desk',
      team: data?.team === undefined ? theBooking?.team : data?.team,
      client: data?.client === undefined ? theBooking?.client : data?.client,
      floor: data?.floor === undefined || data?.floor === '' ? office?.floors[0]?.slug : data?.floor,
    }
    getSpaces(query).then(r => setSpacesData(r))
  }

  function formatResRows(slugsArr) {
    if (slugsArr !== undefined && resourcesData !== undefined) {
      const resourcesArr = slugsArr
      const finalArr = []
      resourcesData.resources.map(res => {
        resourcesArr.map(slug => {
          if (slug === res.slug) finalArr.push(res)
          return null
        })
        return null
      })
      return finalArr
    }
    return null
  }

  function createBookingsArr(arr) {
    const newBooks = []
    arr.map((b, i) => {
      let bookingPrice = 0
      if (priceInfo && Object.keys(priceInfo).length > i && !finalBookings[i]?.pre_paid) {
        bookingPrice = priceInfo.pending_after_prepaid[i]
      }
      const book = {
        id: i,
        start: { dateTime: formatDates(theBooking?.start !== undefined ? theBooking.start : new Date(), 'start') },
        end: { dateTime: formatDates(theBooking?.start !== undefined ? theBooking.start : new Date()) },
        office: office.slug,
        price: bookingPrice,
        payNow: false,
        booking_type: 'hot_desk',
        member: finalBookings[i]?.member || '',
        resource: b,
        floor: finalBookings[i]?.floor || '',
        team: finalBookings[i]?.team || theBooking?.team,
        client: finalBookings[i]?.client || theBooking?.client,
        work_area: '',
        pre_paid: finalBookings[i]?.pre_paid || '',
        paid: finalBookings[i]?.paid || false,
        complimentary: finalBookings[i]?.complimentary || false,
        guest: finalBookings[i]?.guest || '',
      }
      newBooks.push(book)
      return ''
    })
    setFinal(newBooks)
  }

  function asignResToBooking(resSlug) {
    console.log('se asigna el res al booking')
    if (bookingSelected !== undefined) {
      setDragResource(resSlug)
    }
  }

  function asignResToBookingFake(resSlug) {
    setDragResource(resSlug)
  }

  function asignBookingToResource() {
    asignResToBookingFake()
  }

  function getSelectedDesk(data, type) {
    console.log('get seleceted desk')
    const arr = selectedDesk
    if (data !== undefined && type === 'drop' && arr.length < 1) {
      arr.push(data)
      return ''
    }
    if (data !== undefined && type === true) arr.push(data)
    if (data !== undefined && type === false) {
      arr.map((slug, i) => {
        if (data === slug) arr.splice(i, 1)
        return ''
      })
    }
    const newArr = []
    arr.map(slug => {
      newArr.push(slug)
      return ''
    })
    const filterArr = newArr.filter((item, index) => newArr.indexOf(item) === index)
    if (state === 'selecting') setSelectedData(filterArr)
    createBookingsArr(filterArr)
    formatResRows()
    return filterArr
  }

  function getMembersData(slug) {
    getMembersByTeam(slug).then(r => setMembersRows(r))
  }

  function getGuestData() {
    console.log(theBooking?.team)
    if (theBooking?.team !== undefined && theBooking?.team !== '') getGuestByTeam(theBooking?.team).then(r => setGuestsRows(r))
  }

  function createDataBookings(index, data, field) {
    const bookings = finalBookings
    if (field === 'member') {
      bookings[index].member = data
      let members = []
      membersRows.map(m => {
        if (m.slug === data) {
          members = membersRows.filter(mem => !(mem.slug === m.slug && mem.role !== 'referral'))
          setMembersRows(members)
        }
        return setMembersRows(members)
      })
    }
    if (field === 'pre_paid') {
      bookings[index].pre_paid = data
      let prepaidsArr = []
      prepaidsRows.map(pre => {
        if (pre.code === data) {
          prepaidsArr = prepaidsRows.filter(p => p.code !== pre.code)
          setPrepaidsRows(prepaidsArr)
        }
        return ''
      })
    }
    if (field === 'guest') {
      bookings[index].guest = data
      let guestsArr = []
      guestsRows?.map(g => {
        if (g.slug === data) {
          guestsArr = guestsRows?.filter(p => p.slug !== g.slug)
          setGuestsRows(guestsArr)
        }
        return ''
      })
    }
    if (field === 'already_paid') bookings[index].paid = data
    if (field === 'complimentary') bookings[index].complimentary = data
    if (bookings[index].team === undefined || bookings[index].team === '') bookings[index].team = theBooking.team
    setFinal(bookings)
  }

  function sendBooking() {
    setBooking(finalBookings).then(() => {
      setSelectedData([])
      setSpacesData({})
    })
  }

  function getPanelInfo(data, type) {
    console.log('get panel info', type)
    let bookingInfo = { default: true }
    if (data !== undefined && type === 'resourceInfo') {
      spaces.bookings.map(booking => {
        if (booking.resource?.slug === data) {
          bookingInfo = booking
        }
        return null
      })
    }
    if (data !== undefined && type === 'bookingInfo' && state !== 'assigning') {
      spaces.bookings.map(booking => {
        if (booking.code === data) {
          bookingInfo = booking
        }
        return null
      })
    }
    setPanel(bookingInfo)
  }

  function updateBookingRes() {
    updateBooking(bookingSelected, { resource: dragResource }).then(r => {
      if (r.status === 200) {
        setPanel({ default: true })
        setState('selecting')
        setSelectedData([])
        getResourcesData()
      }
    })
  }

  function checkFields() {
    if (teamsData === undefined) getTeamData()
    if (spaces === undefined) getDataSpaces()
    if (guestsRows === undefined) getGuestData()
  }

  function getPrices() {
    if (theBooking?.team !== undefined && theBooking?.team !== '') {
      const infoPrices = {
        team: theBooking?.team !== undefined ? theBooking.team : '',
        office: office?.slug !== undefined ? office.slug : '',
        bookingType: 'hot_desk',
        bookingSize: selectedDesk?.length !== undefined ? selectedDesk.length : '',
      }
      calculateBookingPrice(infoPrices).then(r => {
        setPrice(r)
        setPrepaidsRows(r?.selected_prepaids)
      })
    }
  }

  function compareDates() {
    let response = ''
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (formatDates(new Date(), 'start') === formatDates(theBooking?.start, 'start')) response = 'today'
    if (formatDates(tomorrow, 'start') === formatDates(theBooking?.start, 'start')) response = 'tomorrow'
    return response
  }

  function getFloor() {
    let theFloor = office.floors[0]
    if (theBooking?.floor !== undefined) {
      office.floors.map(f => {
        if (f.slug === theBooking.floor) theFloor = f
        return ''
      })
    }
    if (theFloor !== undefined) setFloorMap(JSON.parse(theFloor.floor_map))
  }

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => {
      setClients(r)
    })
  }

  function getTeamsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => {
      //getMembersData(firstTeam?.slug)
      setTeams(r)
    })
  }
  // Use effects
  useEffect(() => {
    getClientsData()
  }, [])

  useEffect(() => {}, [infoPanel])

  useEffect(() => {}, [bookingSelected])

  useEffect(() => {
    if (bookingSelected !== undefined && dragResource !== undefined) updateBookingRes()
  }, [bookingSelected, dragResource])

  useEffect(() => {
    checkFields()
  }, [theBooking, spaces, selectedDesk, finalBookings, office])

  useEffect(() => {
    getPrices()
  }, [theBooking, office, selectedDesk, infoPanel])

  useEffect(() => {
    setSpacesData({})
    getFloor()
  }, [theBooking, office])

  useEffect(() => {
    createBookingsArr(selectedDesk)
  }, [theBooking, priceInfo])

  useEffect(() => {
    getResourcesData()
    getDataSpaces()
  }, [office, selectedDesk])

  useEffect(() => {
    //cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        //if (searchClient.filter) setClientFilter(searchClient.filter)
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        //if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  const formControl = {
    marginTop: '25px',
    marginRight: '15px',
    marginBottom: '25px',
  }

  const smallStyle = {
    padding: 0,
    margin: 0,
  }

  const smallBtnToday = {
    width: '100%',
    maxHeight: '24px',
    padding: '5px',
    margin: '1px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: compareDates() === 'today' ? `${colors.orange}` : `${colors.softgray}`,
    color: compareDates() === 'today' ? 'white' : 'black',
  }

  const smallBtnTomorrow = {
    width: '100%',
    maxHeight: '24px',
    padding: '5px',
    margin: '1px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: compareDates() === 'tomorrow' ? `${colors.orange}` : `${colors.softgray}`,
    color: compareDates() === 'tomorrow' ? 'white' : 'black',
  }

  const card = {
    border: `1px solid ${colors.softgray}`,
    borderRadius: '5px',
    margin: '5px',
  }

  return (
    <FormContainer>
      <Grid container direction='row' justify='space-between'>
        <Grid item xs={8}>
          <Grid container direction='column'>
            <Grid item>
              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item>
                  <Grid container direction='row'>
                    {office &&
                      office.floors?.map(floor => (
                        <Grid>
                          <Button
                            variant='contained'
                            onClick={() => {
                              setBookingData(
                                theBooking?.floor !== floor.slug
                                  ? { ...theBooking, floor: floor.slug }
                                  : { ...theBooking, floor: '' }
                              )
                              getDataSpaces(
                                theBooking?.floor !== floor.slug
                                  ? { ...theBooking, floor: floor.slug }
                                  : { ...theBooking, floor: '' }
                              )
                            }}
                            style={{ margin: '10px 10px 10px 0' }}
                            color={theBooking?.floor === floor.slug || office.floors.length === 1 ? 'secondary' : ''}
                          >
                            {floor.name}
                          </Button>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' justify='center' style={{ marginRight: '25px', padding: '10px 0' }}>
                    <Grid item>
                      <FormControl>
                        <DatePicker
                          value={theBooking?.start}
                          onChange={value => {
                            if (value !== undefined) {
                              setBookingData({ ...theBooking, start: value })
                              getDataSpaces({ ...theBooking, start: value })
                            }
                          }}
                          name='start'
                          variant='inline'
                          inputVariant='outlined'
                          helperText={null}
                          format='yyyy-MM-dd'
                          label='Day'
                          autoOk
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container direction='column' justify='center' style={{ marginLeft: '10px' }}>
                        <SmallButton
                          style={smallBtnToday}
                          onClick={() => {
                            setBookingData({ ...theBooking, start: new Date() })
                            getDataSpaces({ ...theBooking, start: new Date() })
                          }}
                        >
                          <small style={smallStyle}>Today</small>
                        </SmallButton>
                        <SmallButton
                          style={smallBtnTomorrow}
                          onClick={() => {
                            const today = new Date()
                            const tomorrow = new Date(today)
                            tomorrow.setDate(tomorrow.getDate() + 1)
                            setBookingData({ ...theBooking, start: tomorrow })
                            getDataSpaces({ ...theBooking, start: tomorrow })
                            return ''
                          }}
                        >
                          <small style={smallStyle}>Tomorrow</small>
                        </SmallButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                {spaces && resourcesData ? (
                  <FloorMap
                    spaces={spaces}
                    resources={resourcesData}
                    getSelectedDesk={getSelectedDesk}
                    selectedDesk={selectedDesk}
                    getPanelInfo={getPanelInfo}
                    asignResToBooking={asignResToBooking}
                    setModalBooking={setModalBooking}
                    floorMap={floorMap}
                    state={state}
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction='column' style={{ marginLeft: '10px' }}>
            <Grid item xs={12} style={{ margin: '15px 0', fontSize: '15px' }}>
              <Grid
                container
                direction='row'
                justify='space-around'
                style={{ borderBottom: `1px solid ${colors.softgray}`, paddingBottom: '25px' }}
                alignItems='center'
              >
                <Grid item>Num Bookings: {spaces.num_bookings}</Grid>
                <Grid item>Occupancy: {Math.floor(spaces.occupancy * 100)} %</Grid>
                <Grid item>Pending Orders: -</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' justify='space-around'>
                <Grid item xs={6}>
                  <FormControl style={{ width: '100%' }}>
                    <Autocomplete
                      options={theClients ? theClients?.clients : emptySearch}
                      getOptionLabel={option => option.name}
                      renderInput={params => <TextField {...params} label='Clients' variant='outlined' />}
                      onInputChange={(e, value) => {
                        //cleanErrors()
                        setSearchClient({
                          client: value,
                          filter: e.target.innerText,
                        })
                      }}
                      onChange={(e, data) => {
                        console.log(data)
                        if (data !== null) {
                          setBookingData({ ...theBooking, client: data?.slug })
                          getDataSpaces({ ...theBooking, client: data?.slug })
                          getPrices()
                        } else {
                          setBookingData({ ...theBooking, client: '' })
                          getDataSpaces({ ...theBooking, client: '' })
                          setClients(null)
                        }
                      }}
                      loading
                      loadingText='Searching...'
                      noOptionsText='No Results'
                      //error={errors.client !== null}
                    />
                    {/*errors.team === null ? '' : <SmallError>{errors.team}</SmallError>*/}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{ width: '100%' }}>
                    <Autocomplete
                      options={theTeams ? theTeams?.teams : emptySearch}
                      getOptionLabel={option => option.name}
                      renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
                      onInputChange={(e, value) => {
                        setSearchTeam({
                          team: value,
                          filter: e.target.innerText,
                        })
                      }}
                      onChange={(e, data) => {
                        if (data !== null) {
                          setBookingData({ ...theBooking, team: data?.slug })
                          getDataSpaces({ ...theBooking, team: data?.slug })
                          getMembersData(data.slug)
                          getGuestData()
                          getPrices()
                          //validateBooking({ ...theBooking, team: team.slug })
                        } else {
                          setBookingData({ ...theBooking, team: '' })
                          getDataSpaces({ ...theBooking, team: '' })
                          setTeams(null)
                          //validateBooking({...theBooking, team: '' })
                        }
                      }}
                    />
                    {/*errors.team === null ? '' : <SmallError>{errors.team}</SmallError>*/}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direciont='row' justify='space-around'>
                <Grid item xs={4}>
                  <Button
                    variant={filterPanel === 'all' ? 'contained' : 'outlined'}
                    style={{ width: '100%' }}
                    onClick={() => setFilterPanel('all')}
                    color={filterPanel === 'all' ? 'secondary' : ''}
                  >
                    All
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant={filterPanel === 'assigned' ? 'contained' : 'outlined'}
                    style={{ width: '100%' }}
                    onClick={() => setFilterPanel('assigned')}
                    color={filterPanel === 'assigned' ? 'secondary' : ''}
                  >
                    Assigned
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant={filterPanel === 'unassigned' ? 'contained' : 'outlined'}
                    style={{ width: '100%' }}
                    onClick={() => setFilterPanel('unassigned')}
                    color={filterPanel === 'unassigned' ? 'secondary' : ''}
                  >
                    Pending
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {spaces !== undefined ? (
                    <Grid container direction='row' justify='center'>
                      <Grid item xs={12}>
                        {selectedDesk?.length > 0 ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <Button
                                variant='outlined'
                                style={{ width: '100%', marginTop: '7px' }}
                                onClick={() => setSelectedData([])}
                              >
                                Clear
                              </Button>
                            </Grid>
                            {formatResRows(selectedDesk).map((res, i) => (
                              <Grid item xs={12} key={i}>
                                <Grid container key={i} style={card} direction='column' spacing={2} xs={12}>
                                  <Grid item xs={12}>
                                    <h3>{res.name}</h3>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container direction='row' spacing={1}>
                                      <Grid item xs={6}>
                                        <Autocomplete
                                          disabled={
                                            theBooking?.team === undefined || theBooking?.team === '' ? true : ''
                                          }
                                          name='autocompleteMember'
                                          id='autocomplete_members'
                                          options={membersRows}
                                          getOptionLabel={option =>
                                            `${option.user.first_name} ${option.user.last_name}                 ${option.slug}`
                                          }
                                          renderInput={params => (
                                            <TextField {...params} label='Member' variant='outlined' />
                                          )}
                                          renderOption={option => (
                                            <h5>{`${option.user.first_name} ${option.user.last_name}`}</h5>
                                          )}
                                          onInputChange={(event, option) => {
                                            if (event.target.innerText !== undefined) {
                                              const format = option?.split(' ')
                                              createDataBookings(i, format[format.length - 1], 'member')
                                            } else {
                                              createDataBookings(i, null, 'member')
                                            }
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Autocomplete
                                          disabled={prepaidsRows === undefined ? true : ''}
                                          name='autocompletePrepaids'
                                          id='autocomplete_prepaids'
                                          options={prepaidsRows === undefined ? '' : prepaidsRows}
                                          getOptionLabel={option => `${option.single_price} ${option.code}`}
                                          renderOption={option => {
                                            const theDate = option.create_date?.split('T')
                                            return <h5>{`Hot Desk - ${theDate[0]} - £${option.single_price}`}</h5>
                                          }}
                                          renderInput={params => (
                                            <TextField {...params} label='Price' variant='outlined' />
                                          )}
                                          onChange={(event, option) => {
                                            if (event !== undefined && option !== undefined && option !== null) {
                                              createDataBookings(i, option.code, 'pre_paid')
                                            } else {
                                              createDataBookings(i, null, 'pre_paid')
                                            }
                                            return ''
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container direction='row' justify='space-evenly'>
                                      <Grid item>
                                        <Grid container direction='row' alignItems='center'>
                                          <Grid item>
                                            <Checkbox
                                              name='already_paid'
                                              onChange={event => {
                                                createDataBookings(i, event.target.checked, event.target.name)
                                              }}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <p>Already Paid</p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid container direction='row' alignItems='center'>
                                          <Grid item>
                                            <Checkbox
                                              name='complimentary'
                                              onChange={event => {
                                                createDataBookings(i, event.target.checked, event.target.name)
                                              }}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <p>Complimentary</p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                            <Grid container direction='row' alignItems='center' justify='center'>
                              <Grid item>
                                <Button
                                  disabled={selectedDesk.length === 0}
                                  variant='contained'
                                  color='secondary'
                                  onClick={() => sendBooking()}
                                  style={formControl}
                                >
                                  {priceInfo?.full_price !== undefined ? `£ ${priceInfo.full_price}.00` : 'Book'}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <PanelInfo
                            info={infoPanel}
                            spaces={spaces}
                            setSpacesData={setSpacesData}
                            office={office}
                            activeClient={activeClient}
                            asignBookingToResource={asignBookingToResource}
                            setBookingSelected={setBookingSelected}
                            filter={filterPanel}
                            day={theBooking?.start === undefined ? new Date() : theBooking.start}
                            getPanelInfo={getPanelInfo}
                            setState={setState}
                            state={state}
                            updateList={getDataSpaces}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={modalBooking === true}>
        <ModalCard>
          <Grid container>
            <Grid item>
              <h1>Creating Booking...</h1>
            </Grid>
            <Grid item>
              <SpinnerContainer>
                <CircularProgress color='secondary' />
              </SpinnerContainer>
            </Grid>
          </Grid>
        </ModalCard>
      </Modal>
    </FormContainer>
  )
}

CreateBookingDesk.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
}

CreateBookingDesk.defaultProps = {
  office: '',
  activeClient: '',
}

export default CreateBookingDesk
