import React, { useEffect, useState } from 'react'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import Badge from '@material-ui/core/Badge'
import { useTranslation } from 'react-i18next'
import formatWords from 'components/atoms/formatWords/formatWords'
import logo from 'assets/icons/WLogo.svg'
import { useLocation } from 'react-router-dom'
import routes from 'config/routes'
import { logout } from 'api/utils'
import { Container, Content, HeaderContainer, ItemText, Logo, MenuItem, MenuLink, MyAvatar } from './styled'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grey[500],
  },
}))

const Header = () => (
  <HeaderContainer>
    <Logo src={logo} />
  </HeaderContainer>
)

const Item = ({ active, to, classes, label, Icon }) => (
  <MenuLink to={to} active={active}>
    <MenuItem button>
      {Icon && (
        <ListItemIcon>
          <Icon className={classes.icon} style={{ color: active ? '#fff' : '#9e9e9e' }} />
        </ListItemIcon>
      )}
      <ItemText primary={label} active={active} />
    </MenuItem>
  </MenuLink>
)

Item.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  to: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

Item.defaultProps = {
  active: false,
  to: '',
  classes: '',
  label: '',
  Icon: null,
}

const Notifications = ({ className }) => (
  <Badge badgeContent={4} color='error' className={className}>
    <NotificationsNoneOutlinedIcon />
  </Badge>
)

Notifications.propTypes = {
  className: PropTypes.string,
}

Notifications.defaultProps = {
  className: '',
}

export default function PermanentDrawerLeft({ className, options }) {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [data, setData] = useState()

  function getData() {
    getOffices().then(r => setData(r))
  }
  useEffect(() => {
    if (data === undefined) getData()
  }, [data])

  const AccountAvatar = () => (
    <MyAvatar width={24} alt='User' src='https://i1.sndcdn.com/avatars-000420004584-jf2ck4-t500x500.jpg' />
  )

  const filterOptions = []
  if (data !== undefined) data?.web_sections?.map(ws => filterOptions.push(formatWords(ws.code)))
  const filterData = []
  options.map(o => {
    filterOptions.map(f => {
      if (f === o.label) filterData.push(o)
      return ''
    })
    return ''
  })

  return (
    <Container variant='permanent' anchor='left' className={className}>
      <Header />
      <Content>
        <List>
          {filterData.map(({ id, label, to, Icon }) => (
            <Item key={id} to={to} active={pathname.includes(to)} label={label} Icon={Icon} classes={classes} />
          ))}
        </List>
        <List>
          <MenuItem button>
            <ListItemIcon>
              <Notifications className={classes.icon} />
            </ListItemIcon>
            <ItemText primary={t('drawer.notifications')} />
          </MenuItem>
          <Item Icon={AccountAvatar} to={routes.profile} label='Account' classes={classes} />
          <MenuItem button onClick={() => logout()}>
            <ItemText primary='LOGOUT' />
          </MenuItem>
        </List>
      </Content>
    </Container>
  )
}

PermanentDrawerLeft.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
}

PermanentDrawerLeft.defaultProps = {
  className: '',
  options: [],
}
