import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDashboardOffices, getOffices } from 'api'
import { CircularProgress, Grid, Button, Modal } from '@material-ui/core'
import { SpinnerContainer } from 'components/template/layout/styled'
import DefaultAvatar from 'components/molecules/defaultAvatar/defaultAvatar'
import ModalCreateCompany from './modalCreateCompany'
import {
  CardContainer,
  ClubCard,
  ListSmallItem,
  ListTitle,
  NoStyleRow,
  VerticalContainer,
} from '../members/newListStyle'
import { AvatarWrapper, ProfileImgContent } from '../members/profileStyle'

function OfficesList() {
  const [officesData, setOffices] = useState()
  const [clientData, setClientData] = useState()
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 20,
    thePage: 1,
    pagesTotal: '',
  })
  const noLinkStyle = { textDecoration: 'none' }

  const [modalCreate, setModalCreate] = useState(false)

  function getClientData() {
    getOffices().then(r => setClientData(r))
  }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.num_results <= info.page_size ? '1' : info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getOfficesData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
      }
    } else {
      query = filterData
    }
    getDashboardOffices(query)
      .then(response => {
        setOffices(response)
        getPagination(response)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {}, [pagination])

  useEffect(() => {
    if (clientData === undefined) getClientData()
  }, [clientData])

  useEffect(() => {
    if (officesData === undefined) getOfficesData()
  }, [officesData])

  function handleModalClose() {
    setModalCreate(false)
    setTimeout(() => {
      getOfficesData()
    }, 1500)
  }

  console.log(clientData)

  if (officesData !== undefined) {
    return (
      <VerticalContainer>
        <Modal open={modalCreate === true}>
          <ModalCreateCompany toClose={handleModalClose} />
        </Modal>
        <Grid container style={{ marginLeft: '25px', marginTop: '25px' }} justify='space-between'>
          <Grid item>
            <Grid container>
              <Grid item>
                <AvatarWrapper>
                  <ProfileImgContent style={{ border: '1px solid black' }}>
                    {clientData?.active_client.picture !== null ? (
                      <img
                        src={clientData?.active_client.picture}
                        alt={clientData?.active_client.name}
                        style={{ width: '100px', borderRadius: '50px' }}
                      />
                    ) : (
                      <DefaultAvatar first={clientData?.active_client.name} last={clientData?.active_client.name} />
                    )}
                  </ProfileImgContent>
                </AvatarWrapper>
              </Grid>
              <Grid item style={{ marginLeft: '25px' }}>
                <Grid container direction='column' spacing={1}>
                  <Grid item style={{ fontSize: '20px', color: 'black', fontWeight: 600 }}>
                    {`${clientData?.active_client.name}`}
                  </Grid>
                  <Grid item style={{ color: 'black', fontWeight: 500 }}>
                    Email - web - phone - subdomain
                  </Grid>
                  <Grid item>Description</Grid>
                  <Grid item>Socoms</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginRight: '25px' }}>
            <Button variant='outlined'>Edit</Button>
          </Grid>
        </Grid>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item>
            <h5>Locations</h5>
          </Grid>
          <Grid item>
            <Button variant='contained' color='secondary' onClick={() => setModalCreate(true)}>
              Add location
            </Button>
          </Grid>
        </Grid>
        <CardContainer>
          {officesData.offices.map((office, i) => (
            <Link to={`/users/company/${office.slug}`} key={i} style={noLinkStyle}>
              <ClubCard>
                <ListTitle style={{ fontSize: '20px', marginBottom: '10px' }}>
                  <strong>{office.name}</strong>
                </ListTitle>
                <ListSmallItem style={{ fontSize: '15px', marginBottom: '5px', width: '100%', color: 'black' }}>
                  {office.street}
                </ListSmallItem>
                <NoStyleRow>
                  <ListSmallItem>Capacity: {office.max_occupancy}</ListSmallItem>
                  <ListSmallItem style={{ textAlign: 'end' }}>Max Free: {office.max_free_visits}</ListSmallItem>
                </NoStyleRow>
              </ClubCard>
            </Link>
          ))}
        </CardContainer>
      </VerticalContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default OfficesList
