import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { colors } from 'config/colors'

export const BoxColumn = styled(Box)`
  border: 1px solid ${colors.softgrey};
  border-radius: 5px;
  display: flex;
  flex-direction: 'column';
`
export const BoxRow = styled(Box)`
  height: 20px;
  display: flex;
  padding: 5px 5px 10px 5px;
  align-items: center;
`

export const BoxItemTitle = styled(Box)`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 700;
`

export const BoxItemTitleLG = styled(Box)`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 700;
`

export const BoxItem = styled(Box)`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${colors.gray};
`

export const BoxItemLG = styled(Box)`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${colors.gray};
`
export const BoxText = styled.p`
  align-text: left;
`
