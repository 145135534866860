import { useState, useEffect } from 'react'
import {
  getDashboardWorkAreas,
  getGuestByTeam,
  getMembersByTeam,
  newGetTeams,
  getResourcesForWorkArea,
  updateBooking,
  getSingleBooking,
} from 'api'
import PropTypes from 'prop-types'
import {
  Modal,
  Grid,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
} from '@material-ui/core'
//import { colors } from 'config/colors'
import { Autocomplete } from '@material-ui/lab'
import { DateTimePicker } from '@material-ui/pickers'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import formatDates from '../../atoms/formatDates/formatDates'
import FeedbackModal from '../../molecules/modals/feedbackModal'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalUpdateDesk({ infoBooking, office, closeModal }) {
  const [teamsData, setTeamsData] = useState()
  const [teamMembers, setTeamMembers] = useState()
  const [waData, setWAData] = useState()
  const [teamGuests, setTeamGuests] = useState()
  const [resourcesData, setResourcesData] = useState()

  const [theBooking, setBookingData] = useState()

  const [canRender, setRender] = useState(false)

  function setBookingInfo() {
    if (resourcesData !== undefined) {
      const theResource = resourcesData.filter(r => r.slug === infoBooking.resource?.slug)
      setBookingData({
        ...theBooking,
        resource: theResource[0] || null,
      })
    }
  }

  function setBookingDataContent() {
    getSingleBooking(infoBooking).then(r => {
      setBookingData({
        code: r.code,
        work_area: r.work_area,
        member: r.member,
        team: r.team || null,
        create_date: r.create_date,
        status: r.status,
        price: r.single_price,
        guest: r.guest,
        planned_start: r.planned_start,
        planned_end: r.planned_end,
        real_start: r.real_start,
        real_end: r.real_end,
        origin: 'regular',
        resource: r.resource || null,
      })
    })
  }

  const [feedback, setFeedBack] = useState({
    show: false,
    data: null,
  })

  const [errors, setErrors] = useState({
    team: null,
    work_area: null,
    booking_size: null,
    planned_start: null,
    planned_end: null,
    resource: null,
  })

  function getData(data) {
    const query = {
      currentPage: 1,
      pageSize: 1000,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => setTeamsData(r))
    getDashboardWorkAreas(query).then(r => setWAData(r))
  }

  function getResourcesData(data) {
    const noneOption = { name: 'None', resource_type: 'hot_desk' }
    if (data !== undefined) {
      getResourcesForWorkArea(data).then(r => {
        r.unshift(noneOption)
        setResourcesData(r)
      })
    }
    if (data === undefined && theBooking !== undefined) {
      getResourcesForWorkArea(theBooking?.work_area?.slug).then(r => {
        r.unshift(noneOption)
        setResourcesData(r)
      })
    }
  }

  function getMembersData(data) {
    const noneMember = {
      user: {
        first_name: 'None',
        last_name: '',
      },
    }
    if (theBooking?.team !== undefined) {
      const noneGuest = { name: 'None' }
      getMembersByTeam(data !== undefined ? data : theBooking?.team?.slug).then(r => {
        r.unshift(noneMember)
        setTeamMembers(r)
      })
      getGuestByTeam(data !== undefined ? data : theBooking?.team?.slug)
        .then(r => {
          r.unshift(noneGuest)
          setTeamGuests(r)
        })
        .catch(e => console.log(e))
    }
  }

  function updateBookings() {
    setFeedBack({ show: true, data: null })
    let theMember = theBooking.member !== null ? theBooking.member.slug : 'none'
    let theGuest = theBooking.guest !== null ? theBooking.guest.slug : 'none'
    let theResource = theBooking.resource !== null ? theBooking.resource.slug : 'none'
    if (theBooking.resource?.name === 'None') theResource = 'none'
    if (theBooking.member?.user?.first_name === 'None') theMember = 'none'
    if (theBooking.guest?.name === 'None') theGuest = 'none'
    const booking = {
      team: theBooking.team.slug,
      member: theMember,
      work_area: theBooking.work_area.slug,
      resource: theResource,
      start: { dateTime: theBooking.planned_start },
      end: { dateTime: theBooking.planned_end },
      status: theBooking.status,
      origin: theBooking.origin,
      price: theBooking.price,
      guest: theGuest,
    }

    updateBooking(infoBooking, booking).then(r => {
      if (r.status === 200) {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        setFeedBack({
          show: true,
          data: r,
        })
      }
    })
  }

  function validate(data) {
    let canUpdate = false
    const textErrors = {
      team: 'Team is required',
      work_area: 'Work Area is required',
      //resource: 'Resource is required',
      planned_start: 'Planned start date is required',
      planned_end: 'Planned end date is required',
      badPlannedTime: 'Planned End date can not be before Planned start time',
      badRealTime: 'Real End date can not be before Real start time',
    }

    const teamRes = data.team === null ? textErrors.team : null
    const waRes = data.work_area === null ? textErrors.work_area : null
    const plannedStartRes =
      data.planned_start === undefined || data.planned_start === null ? textErrors.planned_start : null
    let plannedEndRes = data.planned_end === undefined || data.planned_end === null ? textErrors.planned_end : null
    if (data.planned_end <= data.planned_start) plannedEndRes = textErrors.badPlannedTime
    //const resourceRes = data.resource === undefined || data.resource === '' || data.resource === null ? textErrors.resource : null

    setErrors({
      team: teamRes,
      work_area: waRes,
      planned_end: plannedEndRes,
      planned_start: plannedStartRes,
      resource: null,
    })

    if (teamRes === null && waRes === null && plannedStartRes === null && plannedEndRes === null) canUpdate = true
    if (canUpdate === true) updateBookings()
  }

  const [checkModal, setCheckModal] = useState('none')

  useEffect(() => {
    if (theBooking === undefined) setBookingDataContent()
    if (theBooking?.resource === undefined) setBookingInfo()
    if (teamsData === undefined) getData()
    if (teamMembers === undefined && teamGuests === undefined) getMembersData()
    if (resourcesData === undefined) getResourcesData()
    if (
      teamsData !== undefined &&
      teamMembers !== undefined &&
      waData !== undefined &&
      teamGuests !== undefined &&
      resourcesData !== undefined &&
      theBooking !== undefined
    ) {
      setRender(true)
      setCheckModal('none')
    }
  }, [theBooking, resourcesData, canRender, teamsData, teamMembers, resourcesData])

  useEffect(() => {}, [errors])

  function modalCheck(check) {
    let checking = ''
    switch (check) {
      case 'in':
        checking = 'Checking in...'
        break
      case 'out':
        checking = 'Checking out...'
        break
      default:
        checking = 'Something wrong...'
    }
    return checking
  }

  function checkBookingPresence(check) {
    setCheckModal(check)
    if (check === 'in') {
      const checkInBooking = {
        ...theBooking,
        member: theBooking.member.slug,
        team: theBooking.team.slug,
        guest: theBooking.guest?.slug,
        work_area: theBooking.work_area.slug,
        real_start: formatDates(new Date(), 'time'),
        resource: theBooking.resource?.slug,
      }
      updateBooking(theBooking.code, checkInBooking)
        .then(
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        )
        .catch(e => console.log(e))
    }

    if (check === 'out') {
      const checkInBooking = {
        ...theBooking,
        member: theBooking.member.slug,
        team: theBooking.team.slug,
        guest: theBooking.guest?.slug,
        work_area: theBooking.work_area.slug,
        real_start: formatDates(new Date(), 'time'),
        status: 'finished',
        resource: theBooking.resource?.slug,
      }
      updateBooking(theBooking.code, checkInBooking)
        .then(
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        )
        .catch(e => console.log(e))
    }
  }

  useEffect(() => {}, [checkModal])

  const [cancel, setCancel] = useState(false)
  const [cancelModal, setCancelModal] = useState({
    show: false,
    type: '',
  })
  useEffect(() => {}, [cancel])

  function cancelBooking(type) {
    setCancelModal({ show: true, type })
    updateBooking(infoBooking, {
      ...theBooking,
      status: type,
      member: theBooking.member?.slug,
      team: theBooking.team?.slug,
      resource: theBooking.resource?.slug,
      work_area: theBooking.work_area?.slug,
      guest: theBooking.guest?.slug,
    }).then(() => {
      closeModal()
    })
  }

  let cancelText = ''
  if (cancelModal.type === 'refund') cancelText = 'Your Booking has been cancelled, the refund has been made satisfactorily.'
  if (cancelModal.type === 'cancelled') cancelText = 'Your Booking has been cancelled.'

  return (
    <ModalCard>
      <Modal open={cancelModal.show === true}>
        <ModalCard>
          <Grid container direction='column' justify='center' alignItems='center' spacing={3}>
            <Grid item style={{ fontWeight: 500, fontSize: '40px', marginTop: '15px' }}>
              Booking cancelled
            </Grid>
            <Grid item style={{ fontSize: '20px' }}>
              {cancelText}
            </Grid>
            <Grid item style={{ fontSize: '20px' }}>
              You will be redirect to Bookings Map in few seconds...
            </Grid>
            <Grid item>
              <Grid container style={{ marginTop: '25px', marginBottom: '25px' }}>
                <CircularProgress color='secondary' />
              </Grid>
            </Grid>
          </Grid>
        </ModalCard>
      </Modal>
      <Modal open={checkModal === 'in' || checkModal === 'out'}>
        <ModalCard>
          <Grid container direction='column' justify='space-between' spacing={3} alignItems='center'>
            <Grid item xs={12}>
              <h3>{modalCheck(checkModal)}</h3>
            </Grid>
            <Grid item xs={12}>
              <CircularProgress color='secondary' />
            </Grid>
          </Grid>
        </ModalCard>
      </Modal>
      <Modal open={feedback.show === true}>
        <FeedbackModal type='booking-update' />
      </Modal>
      {canRender === true ? (
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              style={{ marginBottom: '18px' }}
            >
              <Grid item>
                <ModalTitle>Update Desk Booking</ModalTitle>
              </Grid>
              <Grid item>
                <Button onClick={() => closeModal()}>x</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-around' spacing={2}>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    options={teamsData?.teams !== undefined ? teamsData.teams : [{ name: 'No data' }]}
                    value={theBooking !== undefined ? theBooking?.team : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label='Team' variant='outlined' error={errors.team !== null} />
                    )}
                    variant='outlined'
                    onChange={(e, data) => {
                      if (data !== undefined) {
                        setBookingData({ ...theBooking, team: data })
                        getMembersData(data?.slug)
                      } else {
                        setBookingData({ ...theBooking, team: infoBooking.team })
                      }
                    }}
                    onInputChange={(e, data) => getData(data)}
                    error={errors.team !== null}
                  />
                  {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    label='Work Area'
                    name='work_area'
                    value={theBooking !== undefined ? theBooking?.work_area : [{ name: 'No data' }]}
                    getOptionSelected={(option, value) => option.slug === value.slug}
                    options={waData?.work_areas !== undefined ? waData.work_areas : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label='Work Area' variant='outlined' error={errors.work_area !== null} />
                    )}
                    variant='outlined'
                    onChange={(e, data) => {
                      console.log('change')
                      if (data !== undefined) {
                        setBookingData({ ...theBooking, work_area: data })
                        getResourcesData(data?.slug)
                      } else {
                        setBookingData({ ...theBooking, work_area: null })
                        getResourcesData()
                      }
                    }}
                    error={errors.work_area !== null}
                  />
                </FormControl>
                {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    label='Resource'
                    name='resource'
                    value={theBooking !== undefined ? theBooking.resource : [{ name: 'No value' }]}
                    options={
                      resourcesData !== undefined
                        ? resourcesData.filter(r => r.resource_type === 'hot_desk')
                        : [{ name: 'No data' }]
                    }
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label='Resource' variant='outlined' error={errors.resource !== null} />
                    )}
                    variant='outlined'
                    onChange={(e, data) => {
                      console.log(data)
                      if (data !== undefined) setBookingData({ ...theBooking, resource: data })
                      else setBookingData({ ...theBooking, resource: infoBooking.resource })
                    }}
                    error={errors.resource !== null}
                  />
                </FormControl>
                {errors.resource !== null ? <SmallError>{errors.resource}</SmallError> : ''}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-around' spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    value={theBooking?.planned_start}
                    format='yyyy/MM/dd HH:mm'
                    name='planned_start'
                    inputVariant='outlined'
                    minutesStep={5}
                    onChange={value => setBookingData({ ...theBooking, planned_start: formatDates(value, 'time') })}
                    label='Planned Start'
                    disablePast
                    error={errors.planned_start !== null}
                  />
                  {errors.planned_start !== null ? <SmallError>{errors.planned_start}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    value={theBooking?.planned_end}
                    format='yyyy/MM/dd HH:mm'
                    name='planned_end'
                    minutesStep={5}
                    inputVariant='outlined'
                    onChange={value => setBookingData({ ...theBooking, planned_end: formatDates(value, 'time') })}
                    label='Planned End'
                    disablePast
                    error={errors.planned_end !== null}
                  />
                  {errors.planned_end !== null ? <SmallError>{errors.planned_end}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    label='Real Start'
                    format='yyyy/MM/dd HH:mm'
                    inputVariant='outlined'
                    name='real_start'
                    minutesStep={5}
                    value={theBooking?.real_start}
                    renderInput={params => <TextField {...params} variant='outlined' />}
                    onChange={value => setBookingData({ ...theBooking, real_start: formatDates(value, 'time') })}
                    disablePast
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    label='Real End'
                    format='yyyy/MM/dd HH:mm'
                    name='real_end'
                    inputVariant='outlined'
                    minutesStep={5}
                    value={theBooking?.real_end}
                    renderInput={params => <TextField {...params} variant='outlined' />}
                    onChange={value => setBookingData({ ...theBooking, real_end: formatDates(value, 'time') })}
                    disablePast
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-around' spacing={2}>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    disabled={theBooking?.team === undefined}
                    label='Member'
                    name='member'
                    options={teamMembers !== undefined ? teamMembers : [{ name: 'No data members' }]}
                    value={theBooking !== undefined ? theBooking.member : [{ name: 'No data members' }]}
                    getOptionLabel={option => `${option.user?.first_name} ${option.user?.last_name}`}
                    renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                    variant='outlined'
                    onChange={(e, data) => {
                      if (data !== undefined && data !== null) setBookingData({ ...theBooking, member: data })
                      else setBookingData({ ...theBooking, member: infoBooking.member })
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    label='Guest'
                    name='guest'
                    options={teamGuests !== undefined ? teamGuests : [{ name: 'No guest data' }]}
                    value={theBooking !== undefined ? theBooking.guest : [{ name: 'No guest data' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params} label='Guest' variant='outlined' />}
                    variant='outlined'
                    onChange={(e, data) => {
                      if (data !== undefined) setBookingData({ ...theBooking, guest: data })
                      else setBookingData({ ...theBooking, guest: infoBooking.guest })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-around' spacing={2} alignItems='center'>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Origin</InputLabel>
                  <Select
                    label='Origin'
                    variant='outlined'
                    value={theBooking !== undefined ? theBooking?.origin : 'regular'}
                    name='origin'
                  >
                    <MenuItem value='regular'>Regular</MenuItem>
                    <MenuItem value='referral'>Referral</MenuItem>
                    <MenuItem value='cumplimentary'>Cumplimentary</MenuItem>
                    <MenuItem value='trial'>Trial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Status</InputLabel>
                  <Select
                    label='Status'
                    variant='outlined'
                    value={theBooking !== undefined ? theBooking?.status : 'active'}
                    name='status'
                  >
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='pre_paid'>Pre Paid</MenuItem>
                    <MenuItem value='cancelled'>Cancelled</MenuItem>
                    <MenuItem value='finished'>Finished</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Price'
                    variant='outlined'
                    type='number'
                    value={theBooking !== undefined ? theBooking.price : ''}
                    name='price'
                    inputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-between'>
              <Grid item>
                {cancel === true ? (
                  <Grid container direction='row' spacing={1}>
                    <Grid item>
                      <Button variant='contained' color='secondary' onClick={() => setCancel(false)}>
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button variant='contained' color='secondary' onClick={() => setCancel(true)}>
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                {cancel === true ? (
                  ''
                ) : (
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() =>
                      theBooking?.real_start === null ? checkBookingPresence('in') : checkBookingPresence('out')
                    }
                  >
                    {theBooking?.real_start === null ? 'Check In' : 'Check Out'}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {cancel === true ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button variant='outlined' color='secondary' onClick={() => cancelBooking('cancelled')}>
                        Cancel Booking
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant='contained' color='secondary' onClick={() => cancelBooking('refund')}>
                        Cancel & Refund
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button color='secondary' variant='contained' onClick={() => validate(theBooking)}>
                    Update Booking
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='column' justify='space-between' spacing={3} alignItems='center'>
          <Grid item xs={12}>
            <h3>Loading data...</h3>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress color='secondary' />
          </Grid>
        </Grid>
      )}
    </ModalCard>
  )
}

ModalUpdateDesk.propTypes = {
  infoBooking: PropTypes.object,
  office: PropTypes.object,
  closeModal: PropTypes.func,
}

ModalUpdateDesk.defaultProps = {
  infoBooking: {},
  office: {},
  closeModal: () => {},
}

export default ModalUpdateDesk
