import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import map from 'lodash/map'
import { withTheme } from 'styled-components'
import { useQuery } from 'react-query'
// eslint-disable-next-line no-unused-vars
import { getOffices, updateActiveOffice } from 'api'
import { OFFICES } from 'api/constants'
import LayoutView from './view'
import Spinner from './spinner'

//const ALL_OFFICES = { id: 'all', text: 'All', slug: 'all', floors: [] }
const fetchOffices = async () => {
  const data = await getOffices()
  //data.offices.push(ALL_OFFICES)
  return data
}

const Layout = ({
  children,
  theme,
  setOffice,
  office,
  setSections,
  sections,
  breadcrumb,
  setActiveClient,
  activeClient,
  ...props
}) => {
  const { data, isLoading, refetch } = useQuery(OFFICES, fetchOffices, { enabled: false })
  useEffect(() => {
    refetch()
  }, [])

  const [officesOptions, setOfficeOptions] = useState()
  const [webSections, setWebSections] = useState()

  useEffect(() => {
    if (data) {
      const options = map(data?.offices, ({ slug, name }) => ({
        id: slug,
        text: name,
      }))
      console.log('Setting things... ', data)
      //options = [ALL_OFFICES, ...options]
      setOfficeOptions(options)
      if (!office) {
        setOffice(data.active_office ? data.active_office : data.offices[0])
      }
      console.log(activeClient)
      if (!activeClient) {
        setActiveClient(data.active_client ? data.active_client : data.clients[0])
      }
      const sectionArray = []
      map(data?.web_sections, ({ code }) => sectionArray.push(code))
      setWebSections(sectionArray)
      if (sections.length === 0) {
        setSections(sectionArray)
      }
    }
  }, [data, office, setOffice, sections, activeClient, setActiveClient])

  const handleChangeOffice = slug => {
    const officeItem = find(data.offices, { slug })
    setOffice(officeItem)
    updateActiveOffice(slug)
  }
  if (!officesOptions || isLoading || !office || !webSections || !activeClient) return <Spinner />
  return (
    <LayoutView
      {...props}
      theme={theme}
      officesOptions={officesOptions}
      webSections={webSections}
      onChangeOffice={handleChangeOffice}
      office={office}
      activeClient={activeClient}
      breadcrumb={breadcrumb}
    >
      {children}
    </LayoutView>
  )
}

Layout.defaultProps = {
  children: <></>,
  office: null,
  sections: [],
  activeClient: null,
}

Layout.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object.isRequired,
  setOffice: PropTypes.func.isRequired,
  office: PropTypes.object,
  setSections: PropTypes.func.isRequired,
  sections: PropTypes.array,
  setActiveClient: PropTypes.func.isRequired,
  activeClient: PropTypes.object,
  breadcrumb: PropTypes.bool.isRequired,
}

export default withTheme(Layout)
