const routes = {
  login: '/login',
  dashboard: '/dashboard',
  dashboardOccupation: '/dashboard/occupation',
  dashboardBookings: '/dashboard/bookings',
  dashboardRevenue: '/dashboard/revenue',
  members: '/members',
  spaces: '/space',
  membersAdd: '/members/add',
  spacesAdd: '/space/add',
  spacesAddWA: '/space/addWorkArea',
  memberProfile: '/members/:slug',
  membersCompanies: '/members/companies',
  membersMembership: '/members/memberships',
  membersMembershipAdd: '/members/memberships/add',
  membersInvoices: '/members/invoices',
  profile: '/users',
  profileCompany: '/users/company',
  profileMembership: '/users/membership',
  profileStaff: '/users/staff',
  profilePayments: '/users/payments',
  profileIntegrations: '/users/integrations',
  profileTheme: '/users/theme',
  profileReferral: '/users/referral',
  staffSingle: '/users/staff/:slug',
  officeSingle: '/users/company/:slug',
  community: '/community',
  communityNotices: '/community/notices',
  communityEvents: '/community/events',
  space: '/space',
  spaceOffices: '/space/offices',
  spaceWorkAreas: '/space/work_areas',
  spacesWAUpdate: '/space/work_areas/update/:slug',
  bookings: '/bookings',
  bookingsAdd: '/bookings',
  bookingsAddMeeting: '/bookings/meeting_room',
  bookingsAddDesk: '/bookings/hot_desk',
  bookingSingle: '/bookings/:code',
  bookingsList: '/bookings/list',
  fandd: '/fandd',
  foodAndDrinks: '/fandd/orders',
  foodAndDrinksStock: '/fandd/stock',
  foodAndDrinksBar: '/fandd/bar',
  foodAndDrinksCategories: '/fandd/categories',
  memberMemberships: '/members/memberships',
  memberResources: '/members/resources',
  marketing: '/marketing',
  marketingOffer: '/marketing/offer',
  marketingOfferUser: '/marketing/offer_user',
  offerAdd: '/marketing/add',
  offerUserAdd: '/marketing/offer_user/add',
  invoiceAdd: '/members/invoices/add',
  membersGuests: '/members/guests',
  signUp: '/signup',
  checkMail: '/checkmail',
  onBoarding: '/on_boarding',
  error404: '/error/404',
}

export default routes
