import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from './colors'

const getTheme = theme => {
  const newTheme = theme ? theme[0] : null
  return createMuiTheme({
    palette: {
      primary: {
        main: newTheme?.navigation_color || colors.black,
        contrastText: colors.white,
      },
      secondary: {
        main: newTheme?.button_color || colors.orange,
        contrastText: colors.white,
      },
    },
    fonts: {
      primary: 'Lato',
    },
    typography: {
      useNextVariants: true,
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontSize: 16,
    },
    overrides: {
      MuiButton: {
        root: {
          minHeight: 48,
          fontWeight: 'bold',
          fontSize: 14,
          textTransform: 'capitalize',
        },
      },
      MuiTypography: {
        h2: {
          fontSize: 24,
          fontWeight: 700,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'transparent',
          border: `solid 1px ${colors.gray}`,
          borderRadius: 8,
          '&&.Mui-error': {
            borderColor: 'red',
          },
          '&&:before, &&:after ': {
            display: 'none',
          },
        },
      },
    },
  })
}

export { getTheme }
