import React, { Component } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { initTranslation } from 'assets/i18n'
import { interceptorToken } from 'api/utils'
import Context from 'context'
import 'App.css'
import Providers from './Providers'

const queryClient = new QueryClient()

class App extends Component {
  constructor(props) {
    super(props)
    console.log(props)

    this.setOffice = office => {
      console.log(office)
      this.setState({
        office,
      })
    }

    this.setSections = sections => {
      this.setState({
        sections,
      })
    }

    this.setActiveClient = activeClient => {
      console.log(activeClient)
      this.setState({
        activeClient,
      })
    }

    this.state = {
      office: null,
      setOffice: this.setOffice,
      sections: [],
      setSections: this.setSections,
      activeClient: null,
      setActiveClient: this.setActiveClient,
    }
    interceptorToken()
    initTranslation()
  }

  render() {
    const { office, setOffice, sections, setSections, activeClient, setActiveClient } = this.state
    return (
      <Context.Provider
        value={{
          ...this.state,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Providers
            office={office}
            sections={sections}
            activeClient={activeClient}
            setOffice={setOffice}
            setSections={setSections}
            setActiveClient={setActiveClient}
          />
        </QueryClientProvider>
      </Context.Provider>
    )
  }
}

export default App
