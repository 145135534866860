import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import {
  getBookings,
  getDashboardGuests,
  getDashboardOffices,
  getDashboardResources,
  getDashboardWorkAreas,
  newGetMembers,
  newGetTeams,
  getClientsReferral,
} from 'api'
import PropTypes from 'prop-types'
import { Autocomplete, Pagination } from '@material-ui/lab'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { ScrollView } from 'devextreme-react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { colors } from 'config/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import formatWords from 'components/atoms/formatWords/formatWords'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import formatDates from 'components/atoms/formatDates/formatDates'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from 'components/organisms/members/newListStyle'
import { BoxRow, BoxItemTitle, BoxItem } from 'components/organisms/invoices/scrollListStyles'
import SearchInput from '../../atoms/searchInput'

function BookingsList({ office }) {
  console.log('Office info ', office)
  const location = useLocation()
  const [paginate, setPaginate] = useState(true)
  const [bookingsData, setDataBookings] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    client: '',
    order: '',
    status: '',
    payment: '',
    origin: '',
    booking_type: '',
    team: '',
    member: '',
    guest: '',
    office: office.slug,
    work_area: '',
    resource: '',
    start: formatDates(new Date(), 'start_day'),
    end: null,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 25,
    thePage: 1,
    pagesTotal: '',
  })

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    console.log('Calling to get booking in list')
    getBookings(query).then(response => {
      getPagination(response)
      setDataBookings(response)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    console.log('Data from pagination')
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, name: letters },
    }
    console.log('Data from filter')
    getData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = bookingsData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    console.log('Data from order')
    getData(query)
  }

  // ADD FILTERS

  const [clientsData, setClientsData] = useState({ clients: [] })
  const [teamsData, setTeamsData] = useState({ teams: [] })
  const [membersData, setMembersData] = useState({ members: [] })
  const [guestData, setGuestData] = useState({ guests: [] })
  const [officesData, setOfficesData] = useState({ offices: [] })
  const [waData, setWAData] = useState({ workAreas: [] })
  const [resourcesData, setResourcesData] = useState({ resources: [] })
  const [dateFilter, setDateFilter] = useState('today')

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 25,
      filter: {
        name: data !== undefined ? data : '',
      },
    }
    console.log(query)
    getClientsReferral(query).then(r => setClientsData(r))
  }

  function getTeamsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => setTeamsData(r))
  }

  function getMembersData(data) {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {
        name: data !== undefined ? data : '',
        team: theFilter.team,
      },
    }
    newGetMembers(query).then(r => setMembersData(r))
  }

  function getMembersForTeam(slug) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        team: slug,
      },
    }
    newGetMembers(query).then(r => setMembersData(r))
  }

  function getGuestData() {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {},
    }
    getDashboardGuests(query).then(r => setGuestData(r))
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function getWAData() {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {},
    }
    getDashboardWorkAreas(query).then(r => setWAData(r))
  }

  function getResourcesData() {
    const query = {
      currentPage: 1,
      pageSize: 10,
      filter: {},
    }
    getDashboardResources(query).then(r => setResourcesData(r))
  }

  useEffect(() => {
    getOfficesData()
    getClientsData()
  }, [])

  useEffect(() => {
    setDataBookings(null)
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: theFilter,
    }
    console.log('Data from use Effect filter ', theFilter)
    getData(query)
  }, [theFilter])

  useEffect(() => {
    console.log(membersData)
    if (location.state?.slug !== undefined && membersData !== undefined) {
      const filterMember = membersData?.members?.filter(m => m.slug === location.state.slug)
      if (filterMember !== undefined) {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()
        const nextYear = new Date(year + 1, month, day)
        const pastYear = new Date(year - 1, month, day)
        setFilter({
          ...theFilter,
          member: filterMember[0].slug,
          end: formatDates(nextYear, 'end_day'),
          start: formatDates(pastYear, 'start_day'),
        })
      }
    }
  }, [location, membersData])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // COUNTER
  const [searchClient, setSearchClient] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  const [searchTeam, setSearchTeam] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  const [searchMember, setSearchMember] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchMember !== undefined && searchMember !== '') {
        if (searchMember.member) getMembersData(searchMember.member)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchMember])

  const [searchGuest, setSearchGuest] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchGuest !== undefined && searchGuest !== '') {
        if (searchGuest.filter) setFilter({ ...theFilter, name: searchGuest.filter })
        if (searchGuest.guest) getGuestData(searchGuest.guest)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchGuest])

  const [searchWA, setSearchWA] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchWA !== undefined && searchWA !== '') {
        if (searchWA.filter) setFilter({ ...theFilter, name: searchWA.filter })
        if (searchWA.wa) getWAData(searchWA.wa)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWA])

  const [searchRes, setSearchRes] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchRes !== undefined && searchRes !== '') {
        if (searchRes.filter) setFilter({ ...theFilter, name: searchRes.filter })
        if (searchRes.res) getResourcesData(searchRes.res)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchRes])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  // RENDER

  //let theResources = resourcesData !== undefined ? resourcesData.resources : [{ name: 'No data' }]
  //if (theFilter.office !== '') theResources = theResources?.filter(res => res.office?.slug === theFilter.office)
  //if (theFilter.work_area !== '') theResources = theResources?.filter(res => res.work_area?.slug === theFilter.work_area)

  const space = ' '
  const slash = '-'

  const today = new Date()
  const yesterday = new Date(today)
  const tomorrow = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by member or team...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
        <BoxRow>
          <ButtonGroup style={{ marginLeft: '15px', width: '100%' }}>
            <Button
              variant='contained'
              color={dateFilter === 'yesterday' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('yesterday')
                setFilter({ ...theFilter, start: formatDates(yesterday, 'start'), end: formatDates(yesterday) })
              }}
            >
              Yesterday
            </Button>
            <Button
              variant='contained'
              color={dateFilter === 'today' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('today')
                setFilter({ ...theFilter, start: formatDates(today, 'start'), end: formatDates(today) })
              }}
            >
              Today
            </Button>
            <Button
              variant='contained'
              color={dateFilter === 'tomorrow' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('tomorrow')
                setFilter({ ...theFilter, start: formatDates(tomorrow, 'start'), end: formatDates(tomorrow) })
              }}
            >
              Tomorrow
            </Button>
          </ButtonGroup>
        </BoxRow>
      </BoxRow>
      <BoxRow style={{ marginTop: '35px' }}>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            name='office'
            options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
            onChange={(e, data) => {
              if (data?.slug) {
                setFilter({ ...theFilter, office: data.slug })
              } else {
                setFilter({ ...theFilter, office: 'all' })
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
            defaultValue={office}
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            name='autocomplete-clients'
            options={clientsData?.clients?.length > 0 ? clientsData?.clients : [{ name: 'Find broker...' }]}
            getOptionLabel={option => option.name}
            renderInput={params => (<TextField {...params} label='Broker' variant='outlined' />)}
            onInputChange={(e, value) => {
              console.log(e.target, value)
              setSearchClient({
                client: value,
                filter: e.target.innerText,
              })
            }}
            onChange={(e, data) => {
              if (data?.slug) {
                console.log('Selected client ', data)
                setFilter({ ...theFilter, client: data.slug })
              } else {
                console.log('Cleaning team ', data)
                setFilter({ ...theFilter, client: '' })
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            options={teamsData?.teams?.length > 0 ? teamsData?.teams : [{ name: 'Find team...' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
            onInputChange={(e, value) => {
              console.log(e.target, value)
              setSearchTeam({
                team: value,
                filter: e.target.innerText,
              })
            }}
            onChange={(e, data) => {
              if (data?.slug) {
                console.log('Selected team ', data)
                getMembersForTeam(data.slug)
                setFilter({ ...theFilter, team: data.slug })
              } else {
                console.log('Cleaning team ', data)
                setFilter({ ...theFilter, team: '' })
                setMembersData({ members: [] })
                setTeamsData({ teams: [] })
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            options={membersData?.members?.length > 0 ? membersData?.members : [{ name: 'Find member...' }]}
            getOptionLabel={option => (option.user ? `${option.user?.first_name} ${option.user?.last_name}` : '')}
            renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
            onInputChange={(e, value) => {
              console.log(e.target, value)
              setSearchMember({
                member: value,
                filter: e.target.innerText,
              })
            }}
            onChange={(e, data) => {
              if (data?.slug) {
                setFilter({ ...theFilter, member: data.slug })
              } else {
                console.log('Cleaning team ', data)
                setFilter({ ...theFilter, member: '' })
                if (theFilter?.team !== '') getMembersForTeam(theFilter?.team)
                else setMembersData({ members: [] })
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            options={guestData?.guests?.length > 0 ? guestData?.guests : [{ name: 'Find guest...' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Guest' variant='outlined' />}
            onInputChange={(e, value) => {
              setSearchGuest({
                guest: value,
                filter: e.target.innerText,
              })
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            options={waData?.work_areas?.length > 0 ? waData?.work_areas : [{ name: 'Find team...' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Work Area' variant='outlined' />}
            onInputChange={(e, value) => {
              setSearchWA({
                wa: value,
                filter: e.target.innerText,
              })
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            options={resourcesData?.resources?.length > 0 ? resourcesData?.resources : [{ name: 'Find team...' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Resource' variant='outlined' />}
            onChange={(e, data) => {
              if (data !== null) {
                setFilter({ ...theFilter, resource: data.slug })
              } else {
                setFilter({ ...theFilter, resource: '' })
              }
            }}
            onInputChange={(e, value) => {
              setSearchRes({
                res: value,
                filter: e.target.innerText,
              })
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
      </BoxRow>
      <BoxRow style={{ marginTop: '35px' }}>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <DatePicker
            format='yyyy-MM-dd'
            value={theFilter.start}
            label='Start'
            inputVariant='outlined'
            onChange={value => setFilter({ ...theFilter, start: formatDates(value, 'start_day') })}
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <DatePicker
            clearable
            format='yyyy-MM-dd'
            value={theFilter.end}
            label='End'
            inputVariant='outlined'
            onChange={value => setFilter({ ...theFilter, end: formatDates(value, 'end_day') })}
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Status</InputLabel>
          <Select
            variant='outlined'
            label='Status'
            onChange={e => setFilter({ ...theFilter, status: e.target.value })}
            autoOk
            defaultValue=''
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='pre_paid'>Pre Paid</MenuItem>
            <MenuItem value='cancelled'>Cancelled</MenuItem>
            <MenuItem value='finished'>Finished</MenuItem>
            <MenuItem value='alert'>Alert</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Payment</InputLabel>
          <Select
            variant='outlined'
            label='Payment'
            onChange={e => setFilter({ ...theFilter, payment: e.target.value })}
            defaultValue={theFilter.payment}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='finished'>Finished</MenuItem>
            <MenuItem value='pre_paid'>Pre Paid</MenuItem>
            <MenuItem value='cancelled'>Cancelled</MenuItem>
            <MenuItem value='alert'>Alert</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Origin</InputLabel>
          <Select
            variant='outlined'
            label='Origin'
            onChange={e => setFilter({ ...theFilter, origin: e.target.value })}
            defaultValue={theFilter.origin}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='regular'>Regular</MenuItem>
            <MenuItem value='trial'>Trial</MenuItem>
            <MenuItem value='complimentary'>Complimentary</MenuItem>
            <MenuItem value='referral'>Referral</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Booking Type</InputLabel>
          <Select
            variant='outlined'
            label='Booking Type'
            onChange={e => setFilter({ ...theFilter, booking_type: e.target.value })}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='hot_desk'>Hot Desk</MenuItem>
            <MenuItem value='meeting_room'>Meeting Room</MenuItem>
            <MenuItem value='desk'>Desk</MenuItem>
            <MenuItem value='suite'>Private Office</MenuItem>
          </Select>
        </FormControl>
      </BoxRow>
      <ScrollView direction='horizontal' style={{ marginTop: '15px' }} width={(windowSize.width * 82) / 100}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('member__user__first_name')}>
                    <strong>Member</strong>
                  </Button>
                  {bookingsData?.order === 'member__user__first_name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('member__name')}>
                    <strong>Team</strong>
                  </Button>
                  {bookingsData?.order === 'member__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Club</strong>
                  </Button>
                  {bookingsData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button>
                    <strong>Space</strong>
                  </Button>
                  {bookingsData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('planned_start')}>
                    <strong>Date</strong>
                  </Button>
                  {bookingsData?.order === 'planned_start' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('single_price')}>
                    <strong>Price</strong>
                  </Button>
                  {bookingsData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {bookingsData === undefined || bookingsData === null ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            bookingsData?.bookings.map((booking, index) => {
              const dateStart =
                booking.planned_start !== null ? booking?.planned_start?.split('T') : booking?.planned_start?.split('T')
              const dateEnd =
                booking.planned_end !== null ? booking?.planned_end?.split('T') : booking?.planned_end?.split('T')
              let theName = booking.member?.user?.first_name + space + booking.member?.user?.last_name
              if (booking.guest !== null) {
                theName = `${booking.guest.name} (G)`
              }
              if (theName === 'undefined undefined') theName = '-'
              let priceColor = colors.black
              let dateColor = colors.black
              let thePrice = booking.single_price
              if (booking.origin === 'complimentary') thePrice = 'Complimentary'
              if (booking.origin === 'trial') thePrice = 'Trial'
              if (booking.membership !== null) thePrice = 'Pre Paid'
              if (booking.invoice_line?.status === 'pending_payment') priceColor = colors.red
              if (booking.invoice_line?.status === 'paid') priceColor = colors.green
              if (booking.invoice_line === undefined) priceColor = colors.softgray
              if (booking.real_start !== null) dateColor = colors.green
              const bookingTime = new Date(booking.planned_start)
              if (booking.real_start === null && bookingTime < today) dateColor = colors.red
              if (booking.real_end !== null) dateColor = colors.softgray
              return (
                <Link to={`/bookings/${booking.code}`} style={{ textDecoration: 'none' }}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{booking.guest?.name === undefined ? theName : `${booking.guest.name} (G)`}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{booking.team?.name === undefined ? '-' : booking.team.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{booking?.office?.name ? booking?.office?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>
                          {booking?.resource?.name ? booking?.resource?.name : booking?.work_area?.name}
                        </ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem style={{ color: dateColor }}>
                          {dateStart === undefined
                            ? '-'
                            : `${dateStart[0]}, ${dateStart[1].slice(0, 5)} - ${dateEnd[1].slice(0, 5)}`}
                        </ListItem>
                      </BoxItem>
                      <BoxItem style={{ color: priceColor, fontWeight: 700 }}>{thePrice}</BoxItem>
                      <BoxItem>
                        {booking.status === 'alert' ? (
                          <WarningRoundedIcon style={{ color: colors.warning }} fontSize='small' />
                        ) : (
                          ''
                        )}
                        {booking?.origin === 'referral' ? (
                          <ShoppingBasketRoundedIcon fontSize='small' style={{ color: colors.gray }} />
                        ) : (
                          ''
                        )}
                      </BoxItem>
                      <BoxItem>
                        <btn variant='contained'>
                          <MoreVertRoundedIcon style={{ color: colors.gray }} fontSize='small' />
                        </btn>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

BookingsList.propTypes = {
  office: PropTypes.object,
}

BookingsList.defaultProps = {
  office: {},
}

export default BookingsList
