import React, { useEffect, useState } from 'react'
import { getOffices } from 'api'
import HeaderToolbar from 'components/organisms/headerToolbar'
import PropTypes from 'prop-types'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import CreateBookingMeeting from 'components/organisms/bookings/CreateBookingMeeting'
import CreateBookingDesk from 'components/organisms/bookings/CreateBookingDesk'
import { CircularProgress } from '@material-ui/core'
import { DashBoardContainer } from '../space/dashboardStyles'
import BookingsList from './bookingsList'

const DownActions = (pathname, office, permited, activeClient) => {
  console.log(activeClient)
  useEffect(() => {}, [office, activeClient])
  if (permited.includes('map') && pathname === routes.bookings) return <CreateBookingDesk office={office} activeClient={activeClient} />
  if (permited.includes('list') && pathname === routes.bookingsList) return <BookingsList office={office} />
  if (permited.includes('meeting_room') && pathname === routes.bookingsAddMeeting) return <CreateBookingMeeting office={office} activeClient={activeClient} />
  return ''
}

DownActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

DownActions.defaultProps = {
  pathname: '',
  t: '',
}

function CreateBookingDashboard(props) {
  const { office, activeClient } = props
  let { pathname } = useLocation()
  const { t } = useTranslation()
  console.log('In the Create dashboard ', props)
  //TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('map') ? { id: routes.bookings, to: routes.bookings, label: t('booking.addMap') } : '',
    permited.includes('list') ? { id: routes.bookingsList, to: routes.bookingsList, label: t('booking.list') } : '',
    permited.includes('meeting_room')
      ? { id: routes.bookingsAddMeeting, to: routes.bookingsAddMeeting, label: t('booking.addMeetingRoom') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  if (pathname === routes.bookings) {
    if (!permited.includes('map')) {
      if (permited.includes('meeting_room')) {
        pathname = routes.bookingsAddMeeting
      }
      if (permited.includes('list')) {
        pathname = routes.bookingsList
      }
    }
  }

  return (
    <DashBoardContainer>
      <HeaderToolbar tabValue={pathname} tabs={theTabs} />
      <DashBoardContainer>
        {pathname === routes.bookings || pathname === routes.bookingsAddMeeting || pathname === routes.bookingsList ? (
          DownActions(pathname, office, permited, activeClient)
        ) : (
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        )}
      </DashBoardContainer>
    </DashBoardContainer>
  )
}

CreateBookingDashboard.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
}

CreateBookingDashboard.defaultProps = {
  office: {},
  activeClient: {},
}
export default CreateBookingDashboard
