import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { setTeam } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { CloseIcon, ModalCard, ModalHeader, ModalItem, ModalTitle, SubmitBtn } from '../bookings/modalStyles'

function ModalCreateTeam(props) {
  const { toClose } = props
  const [theTeam, setTeamData] = useState()
  const [errors, setErrors] = useState({
    name: null,
    description: null,
    invoice_name: null,
    invoice_address: null,
    invoice_post_code: null,
    invoice_city: null,
  })
  function validation(data) {
    const textErrors = {
      name: 'Name is required',
      description: 'Description is required',
      invoice_name: 'Invoice Name is required',
      invoice_address: 'Invoice Address is required',
      invoice_post_code: 'Invoice Post Code is required',
      invoice_city: 'Invoice City is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const desRes = data?.description === undefined || data?.description === '' ? textErrors.description : null
    const invNameRes = data?.invoice_name === undefined || data?.invoice_name === '' ? textErrors.invoice_name : null
    const invAddressRes =
      data?.invoice_address === undefined || data?.invoice_address === '' ? textErrors.invoice_address : null
    const invCodeRes =
      data?.invoice_post_code === undefined || data?.invoice_post_code === '' ? textErrors.invoice_post_code : null
    const invCityRes = data?.invoice_city === undefined || data?.invoice_city === '' ? textErrors.invoice_city : null

    setErrors({
      name: nameRes,
      description: desRes,
      invoice_name: invNameRes,
      invoice_address: invAddressRes,
      invoice_post_code: invCodeRes,
      invoice_city: invCityRes,
    })
  }

  function createNewTeam() {
    if (
      errors.name === null &&
      errors.description === null &&
      errors.invoice_name === null &&
      errors.invoice_address === null &&
      errors.invoice_post_code === null &&
      errors.invoice_city === null
    ) {
      setTeam(theTeam)
        .then(response => {
          toClose()
          console.log(response)
        })
        .catch(error => console.log(error))
    }
  }

  function checkFields() {}
  useEffect(() => {
    checkFields()
  }, [theTeam, errors])
  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px' }}>Add Team</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <ModalItem>
        <TextField
          label='Name'
          variant='outlined'
          id='team-name-input-id'
          name='name'
          onChange={value => setTeamData({ ...theTeam, name: value.target.value })}
          error={errors.name !== null}
        />
        {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='Description'
          variant='outlined'
          id='team-description-input-id'
          name='description'
          onChange={value => setTeamData({ ...theTeam, description: value.target.value })}
          error={errors.description !== null}
        />
        {errors.description === null ? '' : <SmallError>{errors.description}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='Name in the Invoice'
          variant='outlined'
          id='team-invoice_name-input-id'
          name='invoice_name'
          onChange={value => setTeamData({ ...theTeam, invoice_name: value.target.value })}
          error={errors.invoice_name !== null}
        />
        {errors.invoice_name === null ? '' : <SmallError>{errors.invoice_name}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='Invoicing Address'
          variant='outlined'
          id='team-invoice_address-input-id'
          name='invoice_address'
          onChange={value => setTeamData({ ...theTeam, invoice_address: value.target.value })}
          error={errors.invoice_address !== null}
        />
        {errors.invoice_address === null ? '' : <SmallError>{errors.invoice_address}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='Invoicing Post Code'
          variant='outlined'
          id='team-invoice_post_code-input-id'
          name='invoice_post_code'
          onChange={value => setTeamData({ ...theTeam, invoice_post_code: value.target.value })}
          error={errors.invoice_post_code !== null}
        />
        {errors.invoice_post_code === null ? '' : <SmallError>{errors.invoice_post_code}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='City'
          variant='outlined'
          id='team-invoice_city-input-id'
          name='invoice_city'
          onChange={value => setTeamData({ ...theTeam, invoice_city: value.target.value })}
          error={errors.invoice_city !== null}
        />
        {errors.invoice_city === null ? '' : <SmallError>{errors.invoice_city}</SmallError>}
      </ModalItem>
      <SubmitBtn
        onClick={() => {
          validation(theTeam)
          createNewTeam()
        }}
      >
        Add new Team
      </SubmitBtn>
    </ModalCard>
  )
}

ModalCreateTeam.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateTeam.defaultProps = {
  toClose: () => {},
}

export default ModalCreateTeam
