import { Button, Select } from '@material-ui/core'
import { colors } from 'config/colors'
import styled from 'styled-components'
import Text from 'components/atoms/text/view'

export const ListContainer = styled.div``

export const ListHeader = styled.div`
  font-family: 'lato';
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: black;
  border-bottom: 1px solid grey;
  font-weight: 700;
`

export const ListRow = styled.div`
  font-family: 'Lato', serif;
  font-size: 13px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray};
  &:hover {
    color: white;
    background-color: #d7d7d7;
  }
`
export const PaginationNumbers = styled.span`
  font-family: 'lato';
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
`

export const ListIndex = styled.div`
  color: ${colors.gray};
  width: 25px;
`

export const ListItem = styled.div`
  color: ${colors.gray};
  width: 200px;
`

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
  font-size: 13px;
`
export const ListSmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  align-items: center;
  font-size: 13px;
  color: black;
`
export const ListSmallItem = styled.div`
  color: ${colors.gray};
  width: 100px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 150px 0;
`
export const FilterButton = styled(Button)`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 0 15px;
`

export const FilterInput = styled.div`
  margin: 0 15px;
`

export const FilterSelect = styled(Select)`
  width: 200px;
`

export const Title = styled.h1`
  font-family: 'lato';
  font-size: 15px;
  margin: 15px 0;
  color: #999;
`

export const InvoiceTitle = styled.h1`
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: 'Lato' !important;
  margin-bottom: 5px;
`

export const RowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
`

export const TeamName = styled(Text)`
  color: #6e6e6e;
  padding: 8px 0;
  font-weight: bold;
`
export const AddressText = styled(Text)`
  color: #b4b4b4;
  font-weight: bold;
`

export const DateText = styled(Text)`
  color: black;
  padding: 4px 0;
  font-weight: bold;
`

export const LineDetail = styled.p`
  color: black;
  font-size: 18px;
  font-family: 'Lato';
`
