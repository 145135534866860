import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { useDropzone } from 'react-dropzone'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  BgImage,
  DropArea,
  FormControlStyled,
  FormLabelStyled,
  PlaceholderTextStyled,
  Thumb,
  ThumbImage,
  ThumbInner,
  ThumbsContainer,
} from './styled'

const DropAreaInputView = ({ multiple, value, onChange, className, error, label, placeholderText, icon }) => {
  const { t } = useTranslation()
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple,
    onDrop: onChange,
  })

  console.log(value)

  const thumbs = () => (
    <Thumb key={value}>
      <ThumbInner>
        <ThumbImage src={value} alt='' />
      </ThumbInner>
    </Thumb>
  )

  /*
  useEffect(
    () => () => {
      value?.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [value]
  )
    */

  const oneImage = !multiple && value?.length > 0

  return (
    <FormControlStyled className={className} error={error}>
      {label && <FormLabelStyled>{label}</FormLabelStyled>}
      <DropArea {...getRootProps()} oneImage={oneImage}>
        <input {...getInputProps()} />
        {oneImage ? (
          <BgImage src={value[0].preview} />
        ) : (
          <>
            {' '}
            {icon || <AddIcon />}
            <PlaceholderTextStyled className='placeholderText'>
              {placeholderText || t('dropzoneInput.placeholder')}
            </PlaceholderTextStyled>
          </>
        )}
      </DropArea>
      {!oneImage && <ThumbsContainer>{thumbs}</ThumbsContainer>}
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControlStyled>
  )
}

DropAreaInputView.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  icon: PropTypes.node,
}

DropAreaInputView.defaultProps = {
  multiple: false,
  value: null,
  className: '',
  error: null,
  label: '',
  placeholderText: '',
  icon: null,
}

export default DropAreaInputView
