import React from 'react'
import Context from 'context'
import Container from './container'

const Layout = ({ ...props }) => (
  <Context.Consumer>
    {({ office, setOffice, sections, setSections, activeClient, setActiveClient }) => (
      <Container
        {...props}
        office={office}
        setOffice={setOffice}
        sections={sections}
        setSections={setSections}
        activeClient={activeClient}
        setActiveClient={setActiveClient}
      />
    )}
  </Context.Consumer>
)

export default Layout
