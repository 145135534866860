import React, { useEffect, useState } from 'react'
import LoginForm from 'components/organisms/loginForm'
import { getClientInfo } from 'api'
import bgLogin from 'assets/images/bgLogin.png'
import psl from 'psl'
import { useTranslation } from 'react-i18next'
import { Container, Header, Subtitle, Title, Wrapper } from './styled'

const Login = () => {
  const [loginInfo, setLoginInfo] = useState()
  const { t } = useTranslation()

  function getLoginInfo() {
    const parsed = psl.parse(window.location.hostname)
    getClientInfo(parsed.subdomain).then(r => setLoginInfo(r))
  }

  useEffect(() => {
    if (loginInfo === undefined) getLoginInfo()
  }, [loginInfo])

  return (
    //<Container style={{ backgroundImage: `url(${loginInfo?.picture !== undefined ? loginInfo.picture : bgLogin})` }}>
    <Container style={{ backgroundImage: `url(${bgLogin})` }}>
      <Wrapper elevation={0}>
        <Header>
          <Title variante='h1'>{t('login.title')}</Title>
          <Subtitle>{t('login.subtitle')}</Subtitle>
        </Header>
        <LoginForm />
      </Wrapper>
    </Container>
  )
}

export default Login
