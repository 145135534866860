import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
} from 'devextreme-react/chart'
import { ListContainer, ListHeader, ListRow, ListTitle, SpinnerContainer } from '../members/newListStyle'

function OfficeBookings({ stats }) {
  return (
    <Grid>
      {stats?.stats?.length > 0 ? (
        <ListContainer>
          <Chart palette='Blue' dataSource={stats?.graph_bookings} title='Desk bookings'>
            <CommonSeriesSettings argumentField='month' type='spline' />
            <CommonAxisSettings>
              <Grid />
            </CommonAxisSettings>
            {stats?.graph_bookings_legend?.map(item => (
              <Series key={item.value} valueField={item.value} name={item.name} />
            ))}
            <Margin bottom={20} />
            <ArgumentAxis allowDecimals={false} axisDivisionFactor={60}>
              <Label>
                <Format type='decimal' />
              </Label>
            </ArgumentAxis>
            <Legend verticalAlignment='top' horizontalAlignment='right' />
            <Export />
            <Tooltip />
          </Chart>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Month</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Bookings</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Regular</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Referral</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Trial</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Complementary</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {stats?.stats?.map((row, i) => {
            const date = row?.date_to?.split(' ')
            return (
              <ListRow key={i}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xs={1}>
                    <ListTitle>{date !== undefined ? date[0] : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{row !== undefined ? row?.num_bookings_desk : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{row !== undefined ? row?.regulars : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{row !== undefined ? row?.referrals : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{row !== undefined ? row?.num_trials : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{row !== undefined ? row?.deferences : '-'}</ListTitle>
                  </Grid>
                </Grid>
              </ListRow>
            )
          })}
        </ListContainer>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

OfficeBookings.propTypes = {
  stats: PropTypes.object,
}

OfficeBookings.defaultProps = {
  stats: {},
}

export default OfficeBookings
