import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { SnackbarProvider } from 'notistack'
import DateFnsUtils from '@date-io/date-fns'
import Routes from 'system/routes'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { getTheme } from 'config/theme'
import { getOffices } from 'api'
import 'App.css'
import { ReactQueryDevtools } from 'react-query/devtools'

//const ALL_OFFICES = { id: 'all', text: 'All', slug: 'all', floors: [] }
const Providers = ({ office, setOffice, sections, setSections, activeClient, setActiveClient }) => {
  const [theme, setTheme] = useState(null)
  const [data, setData] = useState(null)
  const [configLoaded, setConfigLoaded] = useState(false)

  const fetchOffices = async () => {
    const localData = await getOffices()
    // Satan did it, no me... WTF???
    if (localData.offices === undefined) {
      //localData.data.offices.push(ALL_OFFICES)
      setData(localData.data)
    } else {
      //localData.offices.push(ALL_OFFICES)
      setData(localData)
    }
  }

  useEffect(() => {
    console.log(data)
    if (data?.web_sections) {
      console.log(data.active_client)
      const sectionArray = []
      map(data?.web_sections, ({ code }) => sectionArray.push(code))
      setSections(sectionArray)
      setOffice(data.active_office ? data.active_office : data.offices[0])
      setActiveClient(data.active_client ? data.active_client : data.clients[0])
    }
    setTheme(getTheme(data))
    if (data) {
      setConfigLoaded(true)
    }
  }, [data])

  useEffect(() => {
    if (sections.length === 0) {
      const loggedIn = localStorage.getItem('token')
      if (loggedIn) {
        fetchOffices()
      } else {
        setConfigLoaded(true)
      }
    }
  }, [])

  if (configLoaded) {
    return (
      <>
        <ThemeProviderStyled theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SnackbarProvider maxSnack={3}>
                <Router>
                  <Routes office={office} sections={sections} activeClient={activeClient} />
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ThemeProviderStyled>
        <ReactQueryDevtools initialIsOpen={false} />
      </>
    )
  }
  return <></>
}

Providers.propTypes = {
  office: PropTypes.object,
  setOffice: PropTypes.func.isRequired,
  sections: PropTypes.array,
  setSections: PropTypes.func.isRequired,
  activeClient: PropTypes.object,
  setActiveClient: PropTypes.func.isRequired,
}

Providers.defaultProps = {
  office: null,
  sections: [],
  activeClient: null,
}

export default Providers
