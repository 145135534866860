import { getDashboardMemberships, newGetTeams, newGetMembers, getMemberships } from 'api'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ListItem,
} from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import SearchInput from 'components/atoms/searchInput'
import {
  FilterInput,
  ListContainer,
  ListHeader,
  ListRow,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from '../members/newListStyle'
import { BoxRow, BoxItemTitle, BoxItem } from '../invoices/scrollListStyles'

function NewMembershipsList({ layoutOffice }) {
  const [paginate, setPaginate] = useState(true)
  const [membershipsData, setData] = useState()
  const [theTeams, setTeams] = useState()
  const [theMembers, setMembers] = useState()
  const [membershipsPlans, setMembershipsPlans] = useState()

  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 15,
    thePage: 1,
    pagesTotal: '',
  })
  const [filterStatus, setFilterStatus] = useState('active')
  const [searchText, setSearchText] = useState('')
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none', color: 'black' }
  const [theFilter, setFilter] = useState({
    status: 'active',
    name: '',
    member: '',
    type: '',
    team: '',
    order: '-start',
    layoutOffice: layoutOffice?.slug,
  })

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getMembersData(data) {
    const query = {
      pageSize: 50,
      currentPage: 1,
      filter: {
        layoutOffice: theFilter.layoutOffice,
        name: data !== undefined ? data : '',
      },
    }
    newGetMembers(query).then(info => {
      setMembers(info.members)
      setPaginate(true)
    })
  }

  function getMembershipsData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardMemberships(query)
      .then(response => {
        setData(response)
        getPagination(response)
        setPaginate(true)
      })
      .catch(error => console.log(error))
  }

  function getMSPlansData() {
    getMemberships().then(response => setMembershipsPlans(response))
  }

  function getTeamsData(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: value === undefined ? '' : value,
        layoutOffice: theFilter.layoutOffice,
      },
    }
    newGetTeams(query)
      .then(response => setTeams(response))
      .catch(error => console.log(error))
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getMembershipsData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({
      ...theFilter,
      name: letters,
    })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: {
        ...theFilter,
        name: letters,
      },
    }
    getDashboardMemberships(query)
      .then(info => {
        setData(info)
        getPagination(info)
      })
      .catch(error => console.log(error))
  }

  function setTeamFilter(value) {
    console.log(value)
    let queryTeam = ''
    theTeams.teams.map(t => {
      if (value !== undefined && value !== '' && value !== null) {
        queryTeam = t.slug
      }
      return ''
    })
    if (queryTeam !== undefined || queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: queryTeam },
      }
      getMembershipsData(query)
    } else {
      setFilter({ ...theFilter, team: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: '' },
      }
      getMembershipsData(query)
    }
  }

  function setMemberFilter(value) {
    let queryMember = ''
    theMembers.map(member => {
      if (value !== undefined && value !== '' && value === `${member.user.first_name} ${member.user.last_name}`) {
        queryMember = member.slug
      }
      return ''
    })
    if (queryMember !== undefined && queryMember !== '') {
      setFilter({ ...theFilter, member: queryMember })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, member: queryMember },
      }
      getMembershipsData(query)
    } else {
      setFilter({ ...theFilter, member: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, member: '' },
      }
      getMembershipsData(query)
    }
  }

  function setPlanFilter(value) {
    let queryPlan = ''
    membershipsPlans.map(plan => {
      if (value !== undefined && value !== '' && value === plan.name) {
        queryPlan = plan.slug
      }
      return ''
    })
    if (queryPlan !== undefined && queryPlan !== '') {
      setFilter({ ...theFilter, membership: queryPlan })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, membership: queryPlan },
      }
      getMembershipsData(query)
    } else {
      setFilter({ ...theFilter, membership: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, membership: '' },
      }
      getMembershipsData(query)
    }
  }

  function setStatusFilter(status) {
    setFilter({
      ...theFilter,
      status,
      name: searchText,
    })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: {
        ...theFilter,
        status,
        name: searchText,
      },
    }
    getDashboardMemberships(query)
      .then(info => {
        setData(info)
        getPagination(info)
      })
      .catch(error => console.log(error))
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = membershipsData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getDashboardMemberships(query)
      .then(info => {
        setData(info)
        getPagination(info)
      })
      .catch(error => console.log(error))
  }

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getMembershipsData(query)
  }

  useEffect(() => {
    if (theMembers === undefined) getMembersData()
  }, [theMembers])

  useEffect(() => {
    if (theTeams === undefined) getTeamsData()
  }, [theTeams])

  useEffect(() => {
    if (membershipsData === undefined) getMembershipsData()
  }, [membershipsData])

  useEffect(() => {
    getMSPlansData()
  }, [])

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  useEffect(() => {}, [theFilter, pagination])

  const space = ' '

  // COUNTER

  const [searchTeam, setSearchTeam] = useState('')
  const [searchPlan, setSearchPlan] = useState('')
  const [searchMember, setSearchMember] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchPlan !== undefined && searchPlan !== '') {
        if (searchPlan.filter) setPlanFilter(searchPlan.filter)
        if (searchPlan.plan) getMSPlansData()
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchPlan])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchMember !== undefined && searchMember !== '') {
        if (searchMember.filter) setMemberFilter(searchMember.filter)
        if (searchMember.member) getMembersData(searchMember.member)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchMember])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
          setSearchText(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by member or team...'
          onChange={e => {
            setSearchFilter({ ...searchFilter, filter: e.target.value })
          }}
        />
        <FilterInput>
          <FormControl variant='outlined'>
            <Autocomplete
              options={theTeams?.teams}
              getOptionLabel={option => option.name}
              style={{ width: 150 }}
              renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
              onInputChange={(e, value) => {
                setSearchTeam({
                  team: value,
                  filter: e.target.innerText,
                })
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        </FilterInput>
        <FilterInput>
          <FormControl variant='outlined'>
            <Autocomplete
              name='autocompleteMembership'
              options={membershipsPlans}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label='Plan' variant='outlined' />}
              onInputChange={(e, value) => {
                setSearchPlan({
                  plan: value,
                  filter: e.target.innerText,
                })
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        </FilterInput>
        <FilterInput>
          <FormControl variant='outlined'>
            <Autocomplete
              options={
                theMembers !== undefined ? theMembers : [{ user: { first_name: 'Searching...', last_name: '' } }]
              }
              getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
              style={{ width: 150 }}
              renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
              onInputChange={(e, value) => {
                setSearchMember({
                  member: value !== undefined ? value : '',
                  filter: e.target.innerText,
                })
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        </FilterInput>
        <FilterInput>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label-status'>Status</InputLabel>
            <Select
              name='status'
              label='Status'
              value={filterStatus}
              onChange={value => {
                setFilterStatus(value.target.value)
                setStatusFilter(value.target.value)
              }}
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='finished'>Finished</MenuItem>
            </Select>
          </FormControl>
        </FilterInput>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('plan_membership__name')}>
                  <strong>Name</strong>
                </Button>
                {membershipsData?.order === 'plan_membership__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
            </BoxRow>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('member__user__first_name')}>
                <strong>Member</strong>
              </Button>
              {membershipsData?.order === 'member__user__first_name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('team__name')}>
                <strong>Team</strong>
              </Button>
              {membershipsData?.order === 'team__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('resource')}>
                <strong>Resource</strong>
              </Button>
              {membershipsData?.order === 'resource' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('base_price')}>
                <strong>Price</strong>
              </Button>
              {membershipsData?.order === 'base_price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('num_bookings')}>
                <strong>Bookings</strong>
              </Button>
              {membershipsData?.order === 'num_bookings' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('start')}>
                <strong>Start</strong>
              </Button>
              {membershipsData?.order === 'start' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('end')}>
                <strong>End</strong>
              </Button>
              {membershipsData?.order === 'end' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('recurrent')}>
                <strong>Recurrent</strong>
              </Button>
              {membershipsData?.order === 'recurrent' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
            <BoxItemTitle>
              <Button onClick={() => setOrderData('status')}>
                <strong>Status</strong>
              </Button>
              {membershipsData?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </BoxItemTitle>
          </ListHeader>
          {membershipsData === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            membershipsData.memberships.map(membership => {
              const startDate = membership.start ? membership.start?.split('T') : '-'
              const endDate = membership.end ? membership.end?.split('T') : '-'
              const theMember = membership?.member?.user?.first_name + space + membership?.member?.user?.last_name
              return (
                <Link to={`/members/memberships/${membership.slug}`} key={membership.slug} style={noLinkStyle}>
                  <ListRow>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{membership.plan_membership.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem style={{ textAlign: 'flex-start' }}>
                          {theMember !== undefined ? theMember : '-'}
                        </ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{membership?.team?.name ? membership.team.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{membership.resource !== null ? membership?.resource?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{membership.base_price}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{membership.num_bookings}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{startDate[0]}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{endDate[0]}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{formatWords(membership?.recurrent.toString())}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{formatWords(membership?.status)}</ListItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

NewMembershipsList.propTypes = {
  layoutOffice: PropTypes.object,
}

NewMembershipsList.defaultProps = {
  layoutOffice: null,
}
export default NewMembershipsList
