import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { getMembershipSingle } from 'api'
import { useParams } from 'react-router-dom'
import {
  ProfileContainer,
  ProfileDataContent,
  ProfileFirstContent,
  ProfileInput,
  ProfileTitle,
} from '../members/profileStyle'
import { ListRow, SpinnerContainer } from '../members/newListStyle'

function MembershipProfile() {
  const [membershipData, setMembershipData] = useState()
  const params = useParams()
  const { slug } = params

  function getMembershipData() {
    getMembershipSingle(slug)
      .then(response => setMembershipData(response))
      .catch(e => console.log(e))
  }

  useEffect(() => {
    if (membershipData === undefined) getMembershipData()
  }, [membershipData])

  if (membershipData !== undefined) {
    const createDate = membershipData?.create_date?.split('T')
    const startDate = membershipData?.start?.split('T')
    const endDate = membershipData?.end?.split('T')

    console.log(membershipData)

    return (
      <ProfileContainer>
        <ProfileTitle>{membershipData.plan_membership.name}</ProfileTitle>
        <ProfileFirstContent>
          <ProfileDataContent>
            <ProfileInput>Name: {membershipData?.plan_membership?.name}</ProfileInput>
            <ProfileInput>Status: {membershipData?.status}</ProfileInput>
            <ProfileInput>Price: {membershipData?.base_price}</ProfileInput>
            <ProfileInput>Recurrent: {membershipData?.recurrent === true ? 'Yes' : 'No'}</ProfileInput>
          </ProfileDataContent>
          <ProfileDataContent>
            <ProfileInput>Created Date: {createDate ? createDate[0] : '-'}</ProfileInput>
            <ProfileInput>Start Date: {startDate ? startDate[0] : '-'}</ProfileInput>
            <ProfileInput>End Date: {endDate ? endDate[0] : '-'}</ProfileInput>
          </ProfileDataContent>
          <ProfileDataContent>
            <ProfileInput>
              Office: {membershipData?.office !== null ? membershipData?.office?.name : 'No office data'}
            </ProfileInput>
            <ProfileInput>
              Work Area: {membershipData?.work_area !== null ? membershipData?.work_area.name : 'No work area data'}
            </ProfileInput>
            <ProfileInput>
              Resource: {membershipData?.resource !== null ? membershipData?.resource.name : 'No resource data'}
            </ProfileInput>
            <ProfileInput>Team: {membershipData?.team ? membershipData?.team.name : 'No team data'}</ProfileInput>
          </ProfileDataContent>
        </ProfileFirstContent>
        {membershipData.bookings.length > 0 ? (
          <Grid container direction='column'>
            <Grid item>
              <Grid container justify='space-between'>
                <Grid item>Club</Grid>
                <Grid item>Work Area</Grid>
                <Grid item>Resource</Grid>
                <Grid item>Planned Start</Grid>
                <Grid item>Real Start</Grid>
                <Grid item>Real End</Grid>
              </Grid>
            </Grid>
            {membershipData?.bookings.map(b => {
              const planned = b.planned_start !== null ? b.planned_start?.split('T') : '-'
              const realStart = b.real_start !== null ? b.real_start?.split('T') : '-'
              const realEnd = b.real_end !== null ? b.real_end?.split('T') : '-'
              return (
                <ListRow>
                  <Grid container justify='space-between'>
                    <Grid item>{b.office !== null ? b.office.name : '-'}</Grid>
                    <Grid item>{b.work_area !== null ? b.work_area.name : '-'}</Grid>
                    <Grid item>{b.resource !== null ? b.resource.name : '-'}</Grid>
                    <Grid item>{planned[0]}</Grid>
                    <Grid item>{realStart[0]}</Grid>
                    <Grid item>{realEnd[0]}</Grid>
                  </Grid>
                </ListRow>
              )
            })}
          </Grid>
        ) : (
          ''
        )}
      </ProfileContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default MembershipProfile
