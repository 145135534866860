import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Container, Grid, CircularProgress, Modal } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { colors } from 'config/colors'
import { ScrollView } from 'devextreme-react'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import ModalCreateDesk from './modalCreateDesk'
import ModalUpdateDesk from './modalUpdateDesk'
import BookingPanelCard from './bookingPanelCard'

function PanelInfo({
  info,
  spaces,
  setSpacesData,
  asignBookingToResource,
  setBookingSelected,
  filter,
  office,
  activeClient,
  day,
  getPanelInfo,
  setState,
  state,
  updateList,
}) {
  const [drag, setDrag] = useState(true)
  const [bookingDeskModal, setBookingDeskModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  function onDragStart(e) {
    const data = e.target.outerHTML
    const code = data?.split('"')
    asignBookingToResource(code[5])
  }

  useEffect(() => {}, [filter, bookingDeskModal, updateModal])

  useEffect(() => {
    if (drag === 'cacafuto') setDrag(true)
    document.removeEventListener('onDragStart', onDragStart)
    document.addEventListener('onDragStart', onDragStart)
  }, [info, drag])

  useEffect(() => {
    if (info.default === true) setUpdateModal(true)
  }, [updateModal, info])

  const panelStyle = {
    border: '1px solid #999',
    height: '100%',
    padding: '15px',
    backgroundColor: `${colors.softgray}`,
  }

  function updateData(item) {
    console.log('Update Booking list')
    getPanelInfo(item.code, 'bookingInfo')
  }

  function closeModal() {
    console.log('Clodign moda;')
    setSpacesData([])
    updateList()
    setBookingDeskModal(false)
    setUpdateModal(false)
    getPanelInfo()
  }

  if (info.default === undefined) {
    return (
      <Grid container>
        <Modal open={updateModal === true} toClose={updateModal === false}>
          <ModalUpdateDesk infoBooking={info.code} office={office} closeModal={closeModal} />
        </Modal>
      </Grid>
    )
  }

  if (drag === true) {
    let lista = []
    if (spaces && Object.keys(spaces).length > 0) {
      if (filter === 'unassigned') lista = spaces.bookings.filter(b => b.resource === null)
      if (filter === 'all' || filter === undefined) lista = spaces.bookings
      if (filter === 'assigned') lista = spaces.bookings.filter(b => b.resource !== null)
      return (
        <ScrollView showScrollbar='onScroll' height={window.innerHeight - window.innerHeight / 4}>
          <Modal open={bookingDeskModal} toClose={bookingDeskModal === false}>
            <ModalCreateDesk closeModal={closeModal} office={office} theDay={day} />
          </Modal>
          <Grid container direction='column' justify='center'>
            <Grid item>
              <Grid container direction='column'>
                <Grid item>
                  <Button
                    style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                    variant='outlined'
                    onClick={() => setBookingDeskModal(true)}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
                {lista?.map((item, i) => (
                  <Grid
                    key={i}
                    item
                    draggable
                    onDragStart={e => onDragStart(e)}
                    code={item.code}
                    style={{ margin: '6px 10px 8px 5px' }}
                  >
                    <BookingPanelCard
                      item={item}
                      updateData={updateData}
                      setBookingSelected={setBookingSelected}
                      setState={setState}
                      state={state}
                      activeClient={activeClient}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </ScrollView>
      )
    }
    return (
      <SpinnerContainer>
        <CircularProgress color='secondary' />
      </SpinnerContainer>
    )
  }
  return <Container style={panelStyle} />
}

PanelInfo.propTypes = {
  info: PropTypes.object,
  spaces: PropTypes.object,
  setSpacesData: PropTypes.func,
  asignBookingToResource: PropTypes.func,
  setBookingSelected: PropTypes.func,
  filter: PropTypes.string,
  office: PropTypes.object,
  activeClient: PropTypes.object,
  day: PropTypes.string,
  getPanelInfo: PropTypes.func,
  setState: PropTypes.string,
  state: PropTypes.string,
  updateList: PropTypes.func,
}

PanelInfo.defaultProps = {
  info: { default: true },
  spaces: {},
  setSpacesData: () => {},
  asignBookingToResource: () => {},
  setBookingSelected: () => {},
  filter: '',
  office: {},
  activeClient: {},
  day: '',
  getPanelInfo: () => {},
  setState: '',
  state: 'selecting',
  updateList: () => {},
}

export default PanelInfo
