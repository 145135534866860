import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { colors } from 'config/colors'
import VectorMap, { Layer, Label, Tooltip, Size } from 'devextreme-react/vector-map'
import formatWords from 'components/atoms/formatWords/formatWords'

const projectionSize = 400
const projection = {
  aspectRatio: 1,
  to: ([l, lt]) => [l / projectionSize, lt / projectionSize],
  from: ([x, y]) => [x * projectionSize, y * projectionSize],
}

function FloorMap({
  spaces,
  resources,
  getSelectedDesk,
  selectedDesk,
  getPanelInfo,
  asignResToBooking,
  setModalBooking,
  floorMap,
  state,
}) {
  const [theDesks, setTheDesks] = useState()

  /* function getDeskByIndex(filter, data) {
    const vectorMapData = data[filter]
    console.log(vectorMapData)
    let index = '-'
    if (data !== undefined && vectorMapData?.index !== 0) index = vectorMapData?.index
    else return null
    console.log('Get Desk By index ', index)
    return index
  }*/

  function formatDesk(dataIndex) {
    const features = []
    const usedSpaces = spaces !== undefined ? spaces?.bookings : []
    if (resources !== undefined) {
      resources?.resources?.map(res => {
        if (res.resource_type === 'hot_desk' || res.resource_type === 'desk' || res.resource_type === 'bar_table') {
          let nextStatus = 'active'
          usedSpaces?.map(used => {
            res.status = ''
            //if (used?.resource?.slug === res?.slug) console.log('Found ', used?.resource?.name)
            if (used?.resource?.slug === res?.slug) nextStatus = 'booked'
            return res.status
          })
          res.status = nextStatus
          if (res.resource_type === 'desk') res.status = 'inactive'
          const size = 6
          const width = res.width * 2
          const length = res.length * 2
          const feature = {
            type: 'Feature',
            properties: {
              name: res.name,
              status: res.status,
              slug: res.slug,
              id: res.id,
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [(res.width + res.x * width) / size, (res.length + res.y * length) / size],
                  [(res.width + res.x * width) / size, (-res.length + res.y * length) / size],
                  [(-res.width + res.x * width) / size, (-res.length + res.y * length) / size],
                  [(-res.width + res.x * width) / size, (res.length + res.y * length) / size],
                ],
              ],
            },
          }
          return features.push(feature)
        }
        return ''
      })
    }
    const desks = {
      type: 'deskData',
      features,
    }
    desks.features.map((feature, i) => {
      if (dataIndex !== undefined && i === dataIndex && dataIndex !== 0 && dataIndex !== '-') {
        const overFeature = {
          name: feature.properties.name,
          status: 'over',
          slug: feature.properties.slug,
          id: feature.properties.id,
        }
        desks.features[i].properties = overFeature
        return ''
      }
      return ''
    })
    setTheDesks(desks)
    return desks
  }

  /*function dropping(e) {
    const vectormap = 'vectormap-data-1'
    console.log('Drop funcction')
    formatDesk(getDeskByIndex(vectormap, e.target))
    return ''
  }*/

  /* const dragendtest = useCallback((e) => {
    console.log('Drag test event', e.target)
    const vectormap = 'vectormap-data-1'
    const index = getDeskByIndex(vectormap, e.target)
    if (index !== null && index !== undefined) {
      console.log('This is A desk ', index)
      const desks = formatDesk()
      if (desks.features.length > 0 && index !== undefined && index !== '-') {
        console.log('Desk detected ', desks.features[index].properties.slug, desks.features[index].properties.name)
        //setModalBooking(true)
        setModalBooking(false)
        return asignResToBooking(desks.features[index].properties.slug)
      }
      console.log('Cannot finde resource for index ', index)
      //asignResToBooking()
      return ''
    }
    console.log('This is not a desk')
    return null
  }) */

  function customizeLayer(elements) {
    elements.forEach(element => {
      const status = element.attribute('status')
      const slug = element.attribute('slug')
      selectedDesk.map(resSlug => {
        if (slug === resSlug) element.selected(true)
        return ''
      })
      let theColor = `${colors.white}`
      if (status === 'booked') theColor = `${colors.orange}`
      if (status === 'inactive') theColor = `${colors.gray}`
      if (status === 'over') theColor = `${colors.green}`
      element.applySettings({
        color: theColor,
        hoveredColor: `${colors.orange}`,
        selectedColor: `${colors.green}`,
        fontSize: '10px',
      })
    })
  }

  function fillPanelInfo(target) {
    console.log('rellena el panel')
    getPanelInfo(target?.attribute('slug'), 'resourceInfo')
  }

  function clickArea(event) {
    if (state === 'assigning') console.log('click area assigning')
    if (state === 'selecting') console.log('click area selecting')
    const { target } = event
    //console.log('floor map desk selected', target?.attribute('slug'), target?.attribute('status'))
    if (target?.attribute('status') !== 'active' && state === 'selecting') fillPanelInfo(target)
    else asignResToBooking(target?.attribute('slug'))
    const theName = target?.attribute('name')
    const resArr = resources?.resources
    resArr?.map(res => {
      if (res.name === theName && res.status === 'active' && state === 'selecting') target?.selected(!target?.selected())
      return ''
    })
  }

  function deskSelection(event) {
    console.log('selecciona desk', state, event.target?.selected())
    const selected = event?.target?.selected()
    const deskSlug = event?.target?.attribute('slug')
    if (state === 'selecting') getSelectedDesk(deskSlug, selected)
  }

  /*  useEffect(() => {
    const dragend = e => {
      console.log('Drag test event', e.target)
      const vectormap = 'vectormap-data-1'
      const index = getDeskByIndex(vectormap, e.target)
      if (index !== null && index !== undefined) {
        console.log('This is A desk ', index)
        const desks = formatDesk()
        if (desks.features.length > 0 && index !== undefined && index !== '-') {
          console.log('Desk detected ', desks.features[index].properties.slug, desks.features[index].properties.name)
          //setModalBooking(true)
          setModalBooking(false)
          return asignResToBooking(desks.features[index].properties.slug)
        }
        console.log('Cannot finde resource for index ', index)
        //asignResToBooking()
        return ''
      }
      console.log('This is not a desk')
      return null
    }

    document.addEventListener('dragenter', dragend)
    return () => {
      document.removeEventListener('dragenter', dragend)
    }
  }, [])*/

  useEffect(() => {
    setModalBooking(false)
  }, [spaces])

  useEffect(() => {
    formatDesk()
  }, [spaces])

  useEffect(() => {}, [theDesks])

  function deskPopOver(arg) {
    const slug = arg?.attribute('slug')
    if (slug !== undefined && spaces.bookings) {
      const theDesk = resources.resources.filter(res => res?.slug === slug)
      const theBooking = spaces.bookings.filter(b => b?.resource?.slug === slug)
      return (
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <h4>{theDesk[0].name}</h4>
          </Grid>
          <Grid item>
            <h5>{theDesk[0].status === 'active' ? formatWords('available') : null}</h5>
          </Grid>
          {theDesk[0].status !== 'booked' ? (
            ''
          ) : (
            <Grid item>
              <Grid container direction='column'>
                <Grid item>
                  <h6 style={{ color: colors.gray }}>
                    Team:{' '}
                    {theBooking[0]?.member === null ? theBooking[0]?.team?.name : theBooking[0]?.member?.team.name}
                  </h6>
                </Grid>
                <Grid item>
                  {theBooking[0].member !== undefined ? (
                    <h6 style={{ color: colors.gray }}>
                      Member:{' '}
                      {theBooking[0]?.member === null
                        ? '-'
                        : `${theBooking[0]?.member.user.first_name} ${theBooking[0]?.member.user.last_name}`}
                    </h6>
                  ) : (
                    ''
                  )}
                  {theBooking[0].guest !== undefined ? (
                    <h6 style={{ color: colors.gray }}>
                      Guest: {theBooking[0]?.guest === null ? '-' : `${theBooking[0]?.guest?.name}`}
                    </h6>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item>
                  <h6 style={{ color: colors.gray }}>
                    Status:{' '}
                    {theBooking[0]?.invoice_line === null ? 'Pending' : formatWords(theBooking[0]?.invoice_line.status)}
                  </h6>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    }
    return null
  }
  return (
    <VectorMap
      id='vector-map'
      maxZoomFactor={20}
      projection={projection}
      onClick={event => clickArea(event)}
      controlBar={{ enabled: false }}
      onSelectionChanged={event => deskSelection(event)}
    >
      <Tooltip enabled contentRender={deskPopOver} />
      <Size height={window.innerHeight - window.innerHeight / 4} />
      <Layer
        dataSource={floorMap}
        hoverEnabled={false}
        name='room'
        borderWidth={2}
        color={colors.softgray}
        borderColor={colors.gray}
      />
      {/*<Layer dataSource={waData} name='rooms' borderWidth={1} borderColor={colors.gray} color='transparent' enabled>
        <Label enabled dataField='name' />
      </Layer>*/}
      <Layer
        dataSource={theDesks === undefined ? formatDesk() : theDesks}
        name='desks'
        borderWidth={1}
        borderColor={colors.gray}
        customize={customizeLayer}
        selectionMode='multiple'
      >
        <Label enabled dataField='name' />
      </Layer>
    </VectorMap>
  )
}

FloorMap.propTypes = {
  spaces: PropTypes.object,
  resources: PropTypes.object,
  getSelectedDesk: PropTypes.func,
  asignResToBooking: PropTypes.func,
  selectedDesk: PropTypes.array,
  getPanelInfo: PropTypes.func,
  setModalBooking: PropTypes.bool,
  floorMap: PropTypes.object,
  state: PropTypes.string,
}

FloorMap.defaultProps = {
  spaces: {},
  resources: {},
  getSelectedDesk: () => {},
  selectedDesk: [],
  getPanelInfo: () => {},
  asignResToBooking: () => {},
  setModalBooking: false,
  floorMap: {},
  state: 'selecting',
}

export default FloorMap
