import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { checkMemberMail, getDashboardOffices, newGetTeams, setMember, updateMember } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import { CloseIcon, Col2, ModalCard, ModalHeader, ModalItem, ModalTitle, SubmitBtn } from '../bookings/modalStyles'
import { ProfileInputDer } from './profileStyle'
import {
  //validateEmail,
  isEmptyObject,
} from '../../../utils/utils'

function ModalCreateMember(props) {
  const { t } = useTranslation()
  const { member, office } = props
  // eslint-disable-next-line no-unused-vars
  const validMail = useRef(!!member)
  const { toClose } = props
  const isDirty = useRef(false)
  const [theMember, setMemberData] = useState()

  useEffect(() => {
    if (member) {
      setMemberData({
        first_name: member.user.first_name,
        last_name: member.user.last_name,
        email: member.user.email,
        phone: member.user.phone,
        job_title: member.job_title,
        role: member.role,
        team: member.team,
        office: member.office.slug,
        status: member.status,
        allow_email: member.allow_email,
        allow_push: member.allow_push,
        auto_assign: member.auto_assign,
        auto_checking: member.auto_checking,
        validated: member.user.validated,
        onboarding: member.user.onboarding,
      })
    } else {
      setMemberData({})
    }
  }, [])

  const [theOffices, setOffices] = useState()
  const [theTeams, setTeams] = useState()
  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    team: null,
    office: null,
    email: null,
    phone: null,
    job_title: null,
    role: null,
    status: null,
    validated: null,
    onboarding: null,
  })

  function getTeamsData(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: value === undefined ? '' : value,
        layoutOffice: office !== undefined ? office?.slug : '',
      },
    }
    newGetTeams(query).then(response => {
      const teamOptions = []
      // eslint-disable-next-line no-restricted-syntax
      for (const team of response.teams) {
        teamOptions.push({
          name: team.name,
          slug: team.slug,
        })
      }
      setTeams(teamOptions)
    })
  }

  function getOfficesData(value) {
    const query = {
      pageSize: '100',
      currentPage: '1',
      filter: {
        name: value === undefined ? '' : value,
      },
    }
    getDashboardOffices(query).then(response => setOffices(response))
  }

  /*
  const checkEmail = async email => {
    try {
      const response = await checkMemberMail(theMember.email)
      console.log(response)
      if (response.message === 'not_present') {
        console.log('Email not found')
        validMail.current = true
      } else {
        validMail.current = false
      }
    } catch (error) {
      validMail.current = false
    } finally {
      setMemberData({ ...theMember, email })
    }
  }
  */

  const [mailExists, setMailExists] = useState(false)

  function checkMail(value) {
    checkMemberMail(value).then(r => {
      if (r.message === 'present') {
        setMailExists(true)
        setErrors({ ...errors, email: 'This mail already exists' })
      } else {
        setMailExists(false)
        setErrors({ ...errors, email: null })
      }
    })
  }

  useEffect(() => {}, [mailExists])

  const [feedback, setFeedback] = useState(false)

  function createNewMember() {
    if (member === null) {
      setMember({ ...theMember }).then(() => {
        setFeedback(true)
        setTimeout(() => toClose(), 1500)
      })
    } else {
      updateMember({ ...theMember, team: theMember.team.slug }).then(toClose)
      window.location.reload()
    }
  }

  const validation = async data => {
    if (!isDirty.current) return
    let canCreate = false
    const textErrors = {
      first_name: 'First name is required',
      last_name: 'Last name is required',
      team: 'Team is required',
      office: 'Office is required',
      badMail: 'A valid email is required',
      email: 'Email is required',
      phone: 'Phone is required',
      job_title: 'Job title is required',
      role: 'Role is required',
      emailExists: 'This mail already exists',
    }

    const teamRes = member && (data?.team === undefined || data?.team === '') ? textErrors.team : null
    const firstNameRes = data?.first_name === undefined || data?.first_name === '' ? textErrors.first_name : null
    const lastNameRes = data?.last_name === undefined || data?.last_name === '' ? textErrors.last_name : null
    const officeRes = data?.office === undefined || data?.office === '' ? textErrors.office : null
    let emailRes = data?.email === undefined || data?.email === '' ? textErrors.email : null
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const testMail = regex.test(data?.email)
    emailRes = testMail === false ? textErrors.badMail : null
    if (mailExists === true) emailRes = textErrors.emailExists
    const roleRes = data?.role === undefined || data?.role === '' ? textErrors.role : null

    setErrors({
      first_name: firstNameRes,
      last_name: lastNameRes,
      team: teamRes,
      office: officeRes,
      email: emailRes,
      role: roleRes,
      validated: null,
      onboarding: null,
      status: null,
      job_title: null,
      phone: null,
    })

    if (
      firstNameRes === null &&
      lastNameRes === null &&
      teamRes === null &&
      officeRes === null &&
      emailRes === null &&
      roleRes === null
    ) canCreate = true

    if (canCreate === true) createNewMember()
  }

  useEffect(() => {
    if (theMember && !isEmptyObject(theMember)) isDirty.current = true
  }, [theMember])

  function checkFields() {
    if (theTeams === undefined) getTeamsData()
    if (theOffices === undefined) getOfficesData()
  }

  useEffect(() => {
    checkFields()
  }, [theMember, errors])

  return (
    <ModalCard>
      <Modal open={feedback === true}>
        <FeedbackModal type='member' />
      </Modal>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{member ? 'Update Member' : 'Add Member'}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col2>
        <ModalItem>
          <TextField
            label='First name'
            variant='outlined'
            id='member-first-name-input-id'
            name='first_name'
            value={theMember?.first_name}
            onChange={value => setMemberData({ ...theMember, first_name: value.target.value })}
            error={errors.first_name !== null}
          />
          {errors.first_name === null ? '' : <SmallError>{errors.first_name}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            label='Last name'
            variant='outlined'
            id='member-last-name-input-id'
            name='last_name'
            value={theMember?.last_name}
            onChange={value => setMemberData({ ...theMember, last_name: value.target.value })}
            error={errors.last_name !== null}
          />
          {errors.last_name === null ? '' : <SmallError>{errors.last_name}</SmallError>}
        </ModalItem>
      </Col2>
      <ModalItem>
        <TextField
          label='Email'
          variant='outlined'
          id='member-email-input-id'
          name='email'
          type='email'
          disabled={!!member}
          value={theMember?.email}
          onChange={event => {
            setMemberData({ ...theMember, email: event.target.value })
            checkMail(event.target.value)
          }}
          error={errors.email !== null}
        />
        {errors.email === null ? '' : <SmallError>{errors.email}</SmallError>}
      </ModalItem>
      <Col2>
        <ModalItem>
          <TextField
            label='Phone'
            variant='outlined'
            id='member-phone-input-id'
            name='phone'
            value={theMember?.phone}
            onChange={value => setMemberData({ ...theMember, phone: value.target.value })}
            error={errors.phone !== null}
          />
          {errors.phone === null ? '' : <SmallError>{errors.phone}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            label='Job title'
            variant='outlined'
            id='member-job-input-id'
            name='job_title'
            value={theMember?.job_title}
            onChange={value => setMemberData({ ...theMember, job_title: value.target.value })}
            error={errors.job_title !== null}
          />
          {errors.job_title === null ? '' : <SmallError>{errors.job_title}</SmallError>}
        </ModalItem>
        <ModalItem>
          {theMember && (
            <FormControl variant='outlined'>
              <InputLabel id='filter-label-role'>Role</InputLabel>
              <Select
                label='Role'
                value={theMember?.role}
                onChange={value => {
                  setMemberData({ ...theMember, role: value.target.value })
                }}
                error={errors.role !== null}
              >
                <MenuItem value='team_admin'>Admin</MenuItem>
                <MenuItem value='team_member'>Member</MenuItem>
                <MenuItem value='referral'>Referral</MenuItem>
              </Select>
            </FormControl>
          )}
          {errors.role === null ? '' : <SmallError>{errors.role}</SmallError>}
        </ModalItem>

        <ModalItem>
          <FormControl>
            {theTeams && (
              <Autocomplete
                name='autocompleteTeams'
                options={theTeams !== undefined ? theTeams : ['no data']}
                getOptionLabel={option => option.name}
                defaultValue={theMember.team}
                value={theMember.team}
                renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
                onInputChange={(e, data) => getTeamsData(data)}
                onChange={(e, data) => {
                  if (data !== null) setMemberData({ ...theMember, team: data.slug })
                  else setMemberData({ ...theMember, team: '' })
                }}
                {...props}
              />
            )}
            {errors.team === null ? '' : <SmallError>{errors.team}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label-office'>Office</InputLabel>
            {theOffices && (
              <Select
                label='Office'
                value={theMember?.office}
                onChange={value => setMemberData({ ...theMember, office: value.target.value })}
                error={errors.office !== null}
              >
                {theOffices?.offices.map((o, i) => (
                  <MenuItem key={i} value={o.slug}>
                    {o.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          {errors.office === null ? '' : <SmallError>{errors.office}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label-status'>Status</InputLabel>
            <Select
              label='Status'
              value={theMember?.status}
              onChange={value => setMemberData({ ...theMember, status: value.target.value })}
              error={errors.status !== null}
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
            </Select>
          </FormControl>
          {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
        </ModalItem>
        <ModalItem>
          {theMember && (
            <FormControl variant='outlined'>
              <InputLabel id='filter-label-validation'>Validation</InputLabel>
              <Select
                name='user_validated'
                label='Validation'
                value={theMember?.validated}
                onChange={value => setMemberData({ ...theMember, validated: value.target.value })}
                error={errors.validated !== null}
              >
                <MenuItem value='0'>0</MenuItem>
                <MenuItem value='25'>25</MenuItem>
                <MenuItem value='50'>50</MenuItem>
                <MenuItem value='75'>75</MenuItem>
                <MenuItem value='100'>100</MenuItem>
              </Select>
            </FormControl>
          )}
          {errors.validated === null ? '' : <SmallError>{errors.validated}</SmallError>}
        </ModalItem>
        <ModalItem>
          {theMember && (
            <FormControl variant='outlined'>
              <InputLabel id='filter-label-onboarding'>Onboarding</InputLabel>
              <Select
                name='user_onboarding'
                label='Onboarding'
                value={theMember?.onboarding}
                onChange={value => setMemberData({ ...theMember, onboarding: value.target.value })}
                error={errors.onboarding !== null}
              >
                <MenuItem value='0'>0</MenuItem>
                <MenuItem value='25'>25</MenuItem>
                <MenuItem value='50'>50</MenuItem>
                <MenuItem value='75'>75</MenuItem>
                <MenuItem value='100'>100</MenuItem>
              </Select>
            </FormControl>
          )}
          {errors.onboarding === null ? '' : <SmallError>{errors.onboarding}</SmallError>}
        </ModalItem>

        <ModalItem>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={theMember?.allow_email === 'YES'}
                onChange={value =>
                  setMemberData({
                    ...theMember,
                    allow_email: value.target.checked ? 'YES' : 'NO',
                  })
                }
                name='allow_email'
              />
            }
            label={<ProfileInputDer>{t('memberProfile.allowMail')}</ProfileInputDer>}
          />
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={theMember?.allow_push === 'YES'}
                onChange={value =>
                  setMemberData({
                    ...theMember,
                    allow_push: value.target.checked ? 'YES' : 'NO',
                  })
                }
                name='allow_push'
              />
            }
            label={<ProfileInputDer>{t('memberProfile.allowPush')}</ProfileInputDer>}
          />
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={theMember?.notify_now === 'YES'}
                onChange={value =>
                  setMemberData({
                    ...theMember,
                    notify_now: value.target.checked ? 'YES' : 'NO',
                  })
                }
                name='notify_now'
              />
            }
            label={<ProfileInputDer>{t('memberProfile.notifyNow')}</ProfileInputDer>}
          />
        </ModalItem>
        <ModalItem>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={theMember?.auto_assign === 'YES'}
                onChange={value =>
                  setMemberData({
                    ...theMember,
                    auto_assign: value.target.checked ? 'YES' : 'NO',
                  })
                }
                name='auto_assign'
              />
            }
            label={<ProfileInputDer>{t('memberProfile.autoAsignDesk')}</ProfileInputDer>}
          />
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={theMember?.auto_checking === 'YES'}
                onChange={value =>
                  setMemberData({
                    ...theMember,
                    auto_checking: value.target.checked ? 'YES' : 'NO',
                  })
                }
                name='auto_checking'
              />
            }
            label={<ProfileInputDer>{t('memberProfile.autoCheckin')}</ProfileInputDer>}
          />
        </ModalItem>
      </Col2>
      <SubmitBtn
        onClick={() => {
          isDirty.current = true
          validation(theMember)
        }}
      >
        {member ? 'Update Member' : 'Add new Member'}
      </SubmitBtn>
    </ModalCard>
  )
}

ModalCreateMember.propTypes = {
  toClose: PropTypes.func,
  member: PropTypes.object,
  office: PropTypes.object,
}

ModalCreateMember.defaultProps = {
  toClose: () => {},
  member: null,
  office: {},
}

export default ModalCreateMember
