import { colors } from 'config/colors'
import styled from 'styled-components'
import Close from '@material-ui/icons/Close'

export const ModalCard = styled.div`
  background-color: white;
  margin: 50px auto;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 50%;
  overflow-y: scroll;
  max-height: 75vh;
  padding: 30px;
`
export const ModalCardBroad = styled.div`
  background-color: white;
  margin: 50px auto;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 66%;
  overflow-y: scroll;
  max-height: 75vh;
  padding: 30px;
`

export const ModalHeader = styled.div`
  justify-content: space-between;
  display: flex;
  font-weight: bold;
  padding: 10px 20px;
`
export const Text = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 10px 20px;
`

export const CloseIcon = styled(Close)`
  cursor: pointer;
`

export const ModalTitle = styled.h1`
  font-family: 'Lato';
  font-size: 25px !important;
  font-weight: 900 !important;
`

export const ModalButton = styled.button`
  background-color: ${colors.orange};
  padding: 18px;
  font-family: 'Lato';
  font-size: 25px;
  font-weight: 700;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  &:hover {
    background-color: ${colors.gray};
  }
`

export const ColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const RowAround = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 15px;
  font-family: 'Lato';
`

export const ModalItem = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
`

export const SubmitBtn = styled.button`
  background-color: ${colors.orange};
  font-family: 'Lato';
  color: white;
  margin: 15px;
  padding: 15px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid ${colors.orange};
  border-radius: 5px;
  &:hover {
    background-color: ${colors.gray};
    border: 1px solid ${colors.gray};
  }
`
export const DeleteButton = styled.button`
  background-color: red;
  font-family: 'Lato';
  color: white;
  margin: 15px;
  margin-left: 150px;
  padding: 15px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid red;
  border-radius: 5px;
  &:hover {
    background-color: ${colors.gray};
    border: 1px solid ${colors.gray};
  }
`
export const CloseBtn = styled.button`
  font-family: 'Lato';
  padding: 15px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
`
export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
export const Col2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
export const Col3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
export const Col4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
