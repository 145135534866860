import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, CircularProgress, TextField, Select } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getDashboardInvoices, newGetTeams } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { ScrollView } from 'devextreme-react'
import formatWords from '../../atoms/formatWords/formatWords'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListSmallItem,
  ListSmallTitle,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from './invoicesStyles'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from './scrollListStyles'

function InvoicesList({ layoutOffice }) {
  const [dataInvoices, setDataInvoices] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    status: '',
    type: '',
    team: '',
    order: '-date',
    layoutOffice: layoutOffice?.slug,
  })
  const [paginate, setPaginate] = useState(true)
  const [theTeams, setTeams] = useState()
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: '15',
    thePage: '1',
    pagesTotal: '',
  })
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none' }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.num_results <= info.page_size ? '1' : info.current_page,
      pagesTotal: info.num_pages,
    })
  }
  const space = ' '

  function getDataInvoices(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardInvoices(query)
      .then(response => {
        setDataInvoices(response)
        getPagination(response)
        setPaginate(true)
      })
      .catch(error => console.log(error))
  }

  function setDataFilter(filterData) {
    const letters = filterData.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getDataInvoices(query)
  }

  function filterByBtn(filterData) {
    if (filterData !== undefined) {
      let newValue = filterData.target.innerText.toLowerCase()
      if (newValue === 'pending') newValue = 'pending_payment'
      if (theFilter.status === newValue) {
        setFilter({ ...theFilter, status: '' })
        const query = {
          pageSize: pagination.resultsByPage,
          currentPage: pagination.thePage,
          filter: { ...theFilter, status: '' },
        }
        getDataInvoices(query)
      } else {
        setFilter({ ...theFilter, status: newValue })
        const query = {
          pageSize: pagination.resultsByPage,
          currentPage: pagination.thePage,
          filter: { ...theFilter, status: newValue },
        }
        getDataInvoices(query)
      }
    }
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getDataInvoices(query)
    setPaginate(false)
  }

  function filterByType(event) {
    const typeValue = event.target.value
    if (typeValue !== 'none') {
      setFilter({ ...theFilter, type: typeValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, type: typeValue },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, type: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, type: '' },
      }
      getDataInvoices(query)
    }
  }

  function setTeamFilter(value) {
    let queryTeam = ''
    theTeams.teams.map(team => {
      if (value !== undefined && value !== '' && value === team.name) {
        queryTeam = team.slug
      }
      return ''
    })
    if (queryTeam !== undefined && queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: queryTeam },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, team: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: '' },
      }
      getDataInvoices(query)
    }
  }

  function getDataTeams(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: value === undefined ? '' : value,
        layoutOffice: layoutOffice.slug,
      },
    }
    newGetTeams(query)
      .then(response => setTeams(response))
      .catch(error => console.log(error))
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = dataInvoices?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter, order: theOrder },
    }
    getDataInvoices(query)
  }

  useEffect(() => {}, [pagination, theFilter])

  useEffect(() => {
    if (dataInvoices === undefined) getDataInvoices()
  }, [dataInvoices])

  useEffect(() => {
    if (theTeams === undefined) getDataTeams()
  }, [theTeams])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getDataInvoices(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // CALL TIMEOUTS
  const [searchTeam, setSearchTeam] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getDataTeams(searchTeam.team)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by text...'
          onChange={e => setSearchFilter({ ...searchFilter, filter: e.target.value })}
        />
        <ButtonGroup variant='contained' style={{ margin: '0 15px', padding: 0 }}>
          <Button
            size='small'
            value='pending_payment'
            onClick={event => filterByBtn(event)}
            color={theFilter.status === 'pending_payment' ? 'secondary' : ''}
          >
            Pending
          </Button>
          <Button
            size='small'
            value='paid'
            onClick={event => filterByBtn(event)}
            color={theFilter.status === 'paid' ? 'secondary' : ''}
          >
            Paid
          </Button>
          <Button
            size='small'
            value='void'
            onClick={event => filterByBtn(event)}
            color={theFilter.status === 'void' ? 'secondary' : ''}
          >
            Void
          </Button>
          <Button
            size='small'
            value='open'
            onClick={event => filterByBtn(event)}
            color={theFilter.status === 'open' ? 'secondary' : ''}
          >
            Open
          </Button>
        </ButtonGroup>
        <FormControl variant='outlined' style={{ width: '25%', marginRight: '15px' }}>
          <InputLabel id='filter-label'>Type Filter</InputLabel>
          <Select onChange={event => filterByType(event)} label='Type filter'>
            <MenuItem value='none'>None</MenuItem>
            <MenuItem value='work'>Work</MenuItem>
            <MenuItem value='bar'>Bar</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '25%' }}>
          <Autocomplete
            id='autocomplete-teams-filter'
            options={theTeams?.teams}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Team Filter' variant='outlined' />}
            onInputChange={(e, value) => {
              setSearchTeam({
                team: value,
                filter: e.target.innerText,
              })
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Invoice</strong>
                  </Button>
                  {dataInvoices?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {dataInvoices?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <Button onClick={() => setOrderData('net_price')}>
                    <strong>Amount</strong>
                  </Button>
                  {dataInvoices?.order === 'net_price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('team__name')}>
                    <strong>Team</strong>
                  </Button>
                  {dataInvoices?.order === 'team__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('create_date')}>
                    <strong>Date</strong>
                  </Button>
                  {dataInvoices?.order === 'create_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_due')}>
                    <strong>Due Date</strong>
                  </Button>
                  {dataInvoices?.order === 'date_due' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <ListTitle>
                    <Button onClick={() => setOrderData('lines')}>
                      <strong>Lines</strong>
                    </Button>
                    {dataInvoices?.order === 'lines' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListTitle>
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('xero')}>
                    <strong>Xero</strong>
                  </Button>
                  {dataInvoices?.order === 'xero' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {dataInvoices === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            dataInvoices.invoices?.map((invoice, index) => {
              const dateStart = invoice.create_date?.split('T')
              const dueDate = invoice.date_due ? invoice.date_due?.split('T') : ['-']

              return (
                <Link to={`/members/invoices/${invoice.slug}`} key={invoice.slug} style={noLinkStyle}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{invoice.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{formatWords(invoice.status)}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.net_price}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.team?.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dateStart[0]}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dueDate[0]}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.invoice_lines.length}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.xero_id ? 'ok' : '-'}</ListSmallItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

InvoicesList.propTypes = {
  layoutOffice: PropTypes.object,
}

InvoicesList.defaultProps = {
  layoutOffice: null,
}

export default InvoicesList
