import React, { useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { getOffers } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ScrollView } from 'devextreme-react'
import debounce from 'lodash/debounce'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from './offerListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function OfferList(props) {
  const { layoutOffice } = props
  const [offers, setOffers] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'code',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const noLinkStyle = { textDecoration: 'none' }
  const slash = '-'
  const space = ' '

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getOffersData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getOffers(query)
      .then(info => {
        setOffers(info)
        getPagination(info)
      })
      .catch(error => console.log(error))
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const prevPage = parseInt(pagination.thePage, 10) - 1
      page = prevPage.toString()
    }

    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getOffersData(query)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getOffersData(query)
  }

  const delayedQuery = useCallback(
    debounce(q => setDataFilter(q), 300),
    []
  )

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = offers?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getOffersData(query)
  }

  useEffect(() => {}, [theFilter, pagination])

  useEffect(() => {
    if (offers === undefined) getOffersData()
  }, [offers])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getOffersData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <BoxRow syle={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by code, title, subtitle, description...'
          onChange={value => delayedQuery(value.target.value)}
        />
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('code')}>
                    <strong>Code</strong>
                  </Button>
                  {offers?.order === 'code' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('price')}>
                    <strong>Price</strong>
                  </Button>
                  {offers?.order === 'price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('discount')}>
                    <strong>Discount</strong>
                  </Button>
                  {offers?.order === 'discount' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {offers?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_start')}>
                    <strong>Start</strong>
                  </Button>
                  {offers?.order === 'date_start' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_end')}>
                    <strong>End</strong>
                  </Button>
                  {offers?.order === 'date_end' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('screen_after_accept')}>
                    <strong>Screen After Accept</strong>
                  </Button>
                  {offers?.order === 'screen_after_accept' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {offers === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            offers.offers &&
            offers.offers.map(offer => (
              <Link to={{ pathname: `/marketing/${offer.slug}`, state: offer }} key={offer.slug} style={noLinkStyle}>
                <ListRow>
                  <BoxRow>
                    <BoxItem>
                      <ListItem>{offer.code}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.price}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.discount}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.status}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>
                        {offer.date_start ? `${offer.date_start?.split('+')[0].replace('T', ' ')}` : '-'}
                      </ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.date_end ? `${offer.date_end?.split('+')[0].replace('T', ' ')}` : '-'}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.screen_after_accept}</ListItem>
                    </BoxItem>
                  </BoxRow>
                </ListRow>
              </Link>
            ))
          )}
        </ListContainer>
      </ScrollView>
      <PaginationContainer>
        <Pagination
          color='secondary'
          size='small'
          onClick={value => setPagePagination(value)}
          count={pagination.pagesTotal}
          page={parseInt(pagination.thePage, 10)}
        />
        <PaginationNumbers>
          {pagination.resultsByPage * pagination.thePage -
            pagination.resultsByPage +
            slash +
            (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
              ? pagination.resultsByPage * pagination.thePage
              : pagination.resultsTotal) +
            space}
          of {pagination.resultsTotal}
        </PaginationNumbers>
      </PaginationContainer>
    </ListContainer>
  )
}

OfferList.propTypes = {
  layoutOffice: PropTypes.object,
}

OfferList.defaultProps = {
  layoutOffice: null,
}

export default OfferList
