import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import get from 'lodash/get'
import { getDashboard, getOffices } from 'api'
import {
  DASHBOARD_BOOKINGS_KEY,
  DASHBOARD_BOOKINGS_MR_KEY,
  DASHBOARD_FINANCIAL_KEY,
  DASHBOARD_INVOICES_KEY,
  DASHBOARD_MEMBERS_KEY,
  DASHBOARD_MEMBERSHIPS_KEY,
  DASHBOARD_OCCUPANCY_KEY,
} from 'api/constants'
import { CircularProgress, Grid } from '@material-ui/core'
import { MEASURES, STATS_PERIODS, CLUB_SEARCH_RANGE } from 'config/constants'
import Selector from 'components/atoms/selector'
import RangedDatePicker from 'components/atoms/rangedDatePicker/rangeDatePicker'
import map from 'lodash/map'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SpinnerContainer } from '../members/newListStyle'
import { FilterContainer } from './styled'
import DashboardInfoView from './view'

const fetchDashboardInfo = async (office, measure, period, date, range) => {
  const data = await getDashboard(measure, { club: range === 'all' ? range : office.slug, period, date })
  return data
}

const DashboardInfo = ({ office, clubRange, setClubRange, ...props }) => {
  const history = useHistory()

  const { t } = useTranslation()

  const [period, setPeriod] = useState(STATS_PERIODS.today)

  const [dates, setDates] = useState()

  const bookingsData = useQuery([DASHBOARD_BOOKINGS_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.bookings, period, dates, clubRange)
  )
  const bookingsMrData = useQuery([DASHBOARD_BOOKINGS_MR_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.bookings_mr, period, dates, clubRange)
  )
  const occupancyData = useQuery([DASHBOARD_OCCUPANCY_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.occupancy, period, dates, clubRange)
  )
  const membersData = useQuery([DASHBOARD_MEMBERS_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.members, period, dates, clubRange)
  )
  const financialData = useQuery([DASHBOARD_FINANCIAL_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.financial, period, dates, clubRange)
  )
  const invoicesData = useQuery([DASHBOARD_INVOICES_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.invoices, period, dates, clubRange)
  )
  const membershipsData = useQuery([DASHBOARD_MEMBERSHIPS_KEY, office, period, dates, clubRange], () =>
    fetchDashboardInfo(office, MEASURES.memberships, period, dates, clubRange)
  )

  function getData() {
    getOffices().then(r => {
      if (r.active_client === null) {
        history.push('/on_boarding')
      }
      if (r.active_client?.role === 'pending') {
        history.push('/on_boarding')
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const data = {
    bookings: { isLoading: bookingsData.isLoading, data: get(bookingsData, 'data[0]') },
    bookings_mr: { isLoading: bookingsMrData.isLoading, data: get(bookingsMrData, 'data[0]') },
    occupancy: { isLoading: occupancyData.isLoading, data: get(occupancyData, 'data[0]') },
    members: { isLoading: membersData.isLoading, data: get(membersData, 'data[0]') },
    financial: { isLoading: financialData.isLoading, data: get(financialData, 'data[0]') },
    invoices: { isLoading: invoicesData.isLoading, data: get(invoicesData, 'data[0]') },
    memberships: { isLoading: membershipsData.isLoading, data: get(membershipsData, 'data[0]') },
  }

  const handleChangePeriod = newPeriod => {
    setPeriod(newPeriod)
  }
  const handleChangeClub = newClub => {
    setClubRange(newClub)
  }
  const onChangeDates = newDates => {
    setDates(newDates)
  }

  function setMonths(date) {
    const translateMonths = {
      jan: '01',
      feb: '02',
      mar: '03',
      apr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      aug: '08',
      sep: '09',
      oct: '10',
      nov: '11',
      dec: '12',
    }
    if (date[0] === 'Jan') {
      date.shift([0])
      date.push(translateMonths.jan)
    }
    if (date[0] === 'Feb') {
      date.shift([0])
      date.push(translateMonths.feb)
    }
    if (date[0] === 'Mar') {
      date.shift([0])
      date.push(translateMonths.mar)
    }
    if (date[0] === 'Apr') {
      date.shift([0])
      date.push(translateMonths.apr)
    }
    if (date[0] === 'May') {
      date.shift([0])
      date.push(translateMonths.may)
    }
    if (date[0] === 'Jun') {
      date.shift([0])
      date.push(translateMonths.jun)
    }
    if (date[0] === 'Jul') {
      date.shift([0])
      date.push(translateMonths.jul)
    }
    if (date[0] === 'Aug') {
      date.shift([0])
      date.push(translateMonths.aug)
    }
    if (date[0] === 'Sep') {
      date.shift([0])
      date.push(translateMonths.sep)
    }
    if (date[0] === 'Oct') {
      date.shift([0])
      date.push(translateMonths.oct)
    }
    if (date[0] === 'Nov') {
      date.shift([0])
      date.push(translateMonths.nov)
    }
    if (date[0] === 'Dec') {
      date.shift([0])
      date.push(translateMonths.dec)
    }
    const month = date[2]
    const day = date[0]
    const year = date[1]
    const theDate = `${year}-${month}-${day}`
    return theDate
  }

  const setDatesToFetch = (dateStart, dateEnd) => {
    const startingDate = dateStart.toString()?.split(' ').splice(1, 3)
    const finishingDate = dateEnd.toString()?.split(' ').splice(1, 3)
    const startDate = setMonths(startingDate)
    const endDate = setMonths(finishingDate)
    const theDatesToFetch = {
      start: startDate,
      end: endDate,
    }
    onChangeDates(theDatesToFetch)
  }

  const periodOptions = map(Object.keys(STATS_PERIODS), item => ({ id: item, text: t(`periods.${item}`) }))
  const clubOptions = map(Object.keys(CLUB_SEARCH_RANGE), item => ({ id: item, text: t(`clubsRange.${item}`) }))
  console.log(clubOptions)
  if (data !== undefined) {
    return (
      <Grid style={{ marginTop: '8px' }}>
        <FilterContainer>
          <Selector value={clubRange} options={clubOptions} onChange={handleChangeClub} />
          <Selector value={period} options={periodOptions} onChange={handleChangePeriod} />
          {period === 'custom' ? <RangedDatePicker setDatesToFetch={setDatesToFetch} /> : ''}
        </FilterContainer>
        <DashboardInfoView {...props} data={data} period={period} dates={dates} office={office} />
      </Grid>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

DashboardInfo.propTypes = {
  office: PropTypes.object.isRequired,
  clubRange: PropTypes.object.isRequired,
  setClubRange: PropTypes.object.isRequired,
}

export default DashboardInfo
