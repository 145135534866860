import { createContext } from 'react'

const Context = createContext({
  office: null,
  setOffice: () => null,
  sections: [],
  setSections: () => this.setSections,
  activeClient: null,
  setActiveClient: () => null,
})

export default Context
