import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { SET_INVOICE } from 'api/mutations'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import validate from './validate'
import View from './view'

const AddInvoiceForm = ({ ...props }) => {
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  //const history = useHistory()
  const mutateLogin = useMutation(SET_INVOICE, {
    onSuccess: () => {
      enqueueSnackbar(t('invoices.success'), {
        variant: 'success',
      })
      history.goBack()
    },
    onError: () => {
      enqueueSnackbar(t('invoices.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    mutateLogin.mutate({
      ...data,
      date_due: format(new Date(data.date_due), 'yyyy-MM-dd hh:ii'),
      date: format(new Date(data.date), 'yyyy-MM-dd hh:ii'),
    })
  }

  return <View {...props} onSubmit={handleSubmit(onSubmit)} control={control} errors={errors} />
}

export default AddInvoiceForm
