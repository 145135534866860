import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { newGetMembers, setNewInvoiceLine, updateInvoiceLine, deleteInvoiceLine } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import React, { useEffect, useRef, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker } from '@material-ui/pickers'
import {
  CloseIcon,
  Col2,
  Col3,
  ModalCard,
  ModalHeader,
  ModalItem,
  ModalTitle,
  SubmitBtn,
  DeleteButton,
} from '../bookings/modalStyles'
import { LineDetail } from './invoicesStyles'
// eslint-disable-next-line import/named
import { calculateGrossPrice, calculateTotalLine, calculateGrossTotalLine } from '../../../utils/utils'

function ModalCreateInvoiceLine(props) {
  // const { t } = useTranslation()
  const { invoice, line, office } = props
  const { toClose } = props
  console.log(line)
  const [lineData, setLineData] = useState({
    invoice: line ? invoice.id : invoice.slug,
    team: `${invoice.team.slug}`,
    name: line?.name,
    date: line ? line.create_date : new Date(),
    units: line?.units,
    vat: line ? line.vat : '20',
    payment_type: line?.payment_type,
    payment_reference: line?.payment_reference,
    unit_price: line?.unit_price,
    status: line?.status,
  })

  const [members, setMembers] = useState([])

  const validated = useRef(false)

  const [errors, setErrors] = useState({
    name: null,
    member: null,
    payment_type: null,
    date: null,
    units: null,
    vat: null,
    unit_price: null,
    payment_reference: null,
    status: null,
  })

  function validation(data) {
    console.log(data)
    const textErrors = {
      name: 'Name is required',
      payment_type: 'Payment type is required',
      date: 'Date is required',
      units: 'Units is required',
      vat: 'Vat is required',
      unit_price: 'Unit Price is required',
      status: 'Status is required',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const paymentTypeRes =
      data?.payment_type === undefined || data?.payment_type === '' ? textErrors.payment_type : null
    const dateRes = data?.date === undefined || data?.date === '' ? textErrors.date : null
    const unitsRes = data?.units === undefined || data?.units === 0 ? textErrors.units : null
    const vatRes = data?.vat === undefined || data?.vat === 0 ? textErrors.vat : null
    const unitPriceRes = data?.unit_price === undefined || data?.unit_price === 0 ? textErrors.unit_price : null
    const statusRes = data?.status === undefined || data?.status === 0 ? textErrors.status : null

    setErrors({
      name: nameRes,
      payment_type: paymentTypeRes,
      date: dateRes,
      units: unitsRes,
      vat: vatRes,
      unit_price: unitPriceRes,
      status: statusRes,
    })

    validated.current =
      nameRes === null &&
      paymentTypeRes === null &&
      dateRes === null &&
      unitsRes === null &&
      vatRes === null &&
      unitPriceRes === null &&
      statusRes === null
  }

  const findMember = (id, memberList) => {
    const member = memberList.filter(m => m.id === id)
    return member.length ? member[0] : null
  }

  function getDataMembers(data) {
    newGetMembers({
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: office,
        name: data !== undefined ? data : '',
      },
    }).then(response => {
      if (line) {
        setLineData({ ...lineData, member: findMember(line.member, response.members) })
      }
      setMembers(response.members)
    })
  }

  useEffect(() => {
    getDataMembers()
  }, [])

  function createNewLine() {
    if (validated.current) {
      // eslint-disable-next-line camelcase
      const unit_gross_price = calculateGrossPrice(lineData.unit_price, lineData.vat)
      // eslint-disable-next-line camelcase
      const line_gross_price = calculateGrossTotalLine(lineData.unit_price, lineData.vat, lineData.units)
      // eslint-disable-next-line camelcase
      const line_price = calculateTotalLine(lineData.unit_price, lineData.units)
      const data = {
        ...lineData,
        unit_gross_price,
        line_gross_price,
        line_price,
        member: line ? lineData.member?.id : lineData.member?.slug,
      }
      if (line === null) {
        setNewInvoiceLine(data).then(() => {
          toClose()
          window.location.reload()
        })
      } else {
        updateInvoiceLine(data, line.slug).then(() => {
          toClose()
          window.location.reload()
        })
      }
    }
  }

  function deleteLine() {
    deleteInvoiceLine(line.slug).then(() => {
      toClose()
      window.location.reload()
    })
  }

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{line ? 'Update Line' : 'Add Line'}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col2>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='invoice-line-name-id'
            name='name'
            value={lineData?.name}
            onChange={value => setLineData({ ...lineData, name: value.target.value })}
            error={errors.name !== null}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
        {((members && line && lineData?.member !== undefined) || (members && !line)) && (
          <ModalItem>
            <FormControl error={errors.member !== null}>
              <Autocomplete
                disabled={!members}
                name='member'
                defaultValue={lineData?.member}
                options={members}
                getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                renderInput={p => <TextField {...p} label='Member' variant='outlined' error={errors.member !== null} />}
                onChange={(e, data) => {
                  setLineData({ ...lineData, member: data })
                }}
                onInputChange={(e, data) => getDataMembers(data)}
                {...props}
              />
              {errors.member === null ? '' : <SmallError>{errors.member}</SmallError>}
            </FormControl>
          </ModalItem>
        )}
      </Col2>
      <Col2>
        <ModalItem>
          <FormControl error={errors.payment_type !== null}>
            <InputLabel style={{ marginLeft: '15px' }}>Type</InputLabel>
            <Select
              defaultValue={line ? line.payment_type : null}
              value={lineData?.payment_type}
              label='Type'
              variant='outlined'
              onChange={value => {
                if (value.target.value) {
                  setLineData({ ...lineData, payment_type: value.target.value })
                }
              }}
            >
              <MenuItem value='work'>Work</MenuItem>
              <MenuItem value='bar'>Bar</MenuItem>
              <MenuItem value='event'>Event</MenuItem>
              <MenuItem value='service'>Service</MenuItem>
            </Select>
            {errors.payment_type === null ? '' : <SmallError>{errors.payment_type}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl>
            <DatePicker
              variant='outlined'
              inputVariant='outlined'
              label='Date'
              name='date'
              format='dd/MM/yyyy'
              error={errors?.date !== null}
              value={lineData?.date}
              onChange={value => setLineData({ ...lineData, date: value })}
            />
            {errors.date === null ? '' : <SmallError>{errors.date}</SmallError>}
          </FormControl>
        </ModalItem>
      </Col2>
      <Col3>
        <ModalItem>
          <TextField
            type='number'
            label='Units'
            variant='outlined'
            id='invoice-units-id'
            name='units'
            defaultValue={line?.units}
            value={lineData?.units}
            onChange={value => setLineData({ ...lineData, units: value.target.value })}
            error={errors.units !== null}
          />
          {errors.units === null ? '' : <SmallError>{errors.units}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            type='number'
            label='Public Price'
            variant='outlined'
            id='invoice-unit-price-id'
            name='unit_price'
            defaultValue={line?.unit_price}
            value={lineData?.unit_price}
            onChange={value => setLineData({ ...lineData, unit_price: value.target.value })}
            error={errors.unit_price !== null}
          />
          {errors.unit_price === null ? '' : <SmallError>{errors.unit_price}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            type='number'
            label='% Tax rate'
            variant='outlined'
            id='invoice-vat-id'
            name='vat'
            defaultValue={line ? line.vat : 20}
            value={line?.vat}
            onChange={value => setLineData({ ...lineData, vat: `${value.target.value}` })}
            error={errors.vat !== null}
          />
          {errors.vat === null ? '' : <SmallError>{errors.vat}</SmallError>}
        </ModalItem>
      </Col3>
      {lineData?.vat && lineData?.unit_price && lineData?.units && (
        <ModalItem>
          <LineDetail>
            {`Unit Pice: £${calculateGrossPrice(lineData.unit_price, lineData.vat)} Total: £${calculateTotalLine(
              lineData.unit_price,
              lineData.units
            )}`}
          </LineDetail>
        </ModalItem>
      )}
      <Col2>
        <ModalItem>
          <FormControl error={errors.status !== null}>
            <InputLabel style={{ marginLeft: '15px' }}>Status</InputLabel>
            <Select
              label='Status'
              variant='outlined'
              defaultValue={line?.status}
              value={lineData?.status}
              onChange={value => {
                if (value.target.value) {
                  setLineData({ ...lineData, status: value.target.value })
                }
              }}
            >
              <MenuItem value='new'>New</MenuItem>
              <MenuItem value='payment_pending'>Pending</MenuItem>
              <MenuItem value='paid'>Paid</MenuItem>
              <MenuItem value='void'>Void</MenuItem>
            </Select>
            {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <TextField
            label='Reference'
            variant='outlined'
            id='invoice-line-reference'
            name='name'
            value={lineData?.payment_reference}
            onChange={value => setLineData({ ...lineData, payment_reference: value.target.value })}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
      </Col2>
      <SubmitBtn
        onClick={() => {
          validation(lineData)
          createNewLine()
        }}
      >
        {line ? 'Update Line' : 'Add Line'}
      </SubmitBtn>
      {line && <DeleteButton onClick={deleteLine}>Delete Line</DeleteButton>}
    </ModalCard>
  )
}

ModalCreateInvoiceLine.propTypes = {
  toClose: PropTypes.func,
  invoice: PropTypes.object,
  line: PropTypes.object,
  office: PropTypes.string,
}

ModalCreateInvoiceLine.defaultProps = {
  toClose: () => {},
  invoice: null,
  line: null,
  office: '',
}

export default ModalCreateInvoiceLine
