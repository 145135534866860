export const MEASURES = {
  occupancy: 'occupancy',
  bookings: 'bookings',
  bookings_mr: 'bookings_mr',
  members: 'members',
  financial: 'financial',
  invoices: 'invoices',
  orders: 'orders',
  memberships: 'memberships',
  officeStats: 'office_stats',
}

export const STATS_PERIODS = {
  today: 'today',
  yesterday: 'yesterday',
  week: 'week',
  month: 'month',
  monthLast: 'last month',
  month3: '3month',
  month6: '6month',
  custom: 'custom',
}

export const CLUB_SEARCH_RANGE = {
  this: 'this',
  all: 'all',
}
