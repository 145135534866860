import React, { useState, useEffect } from 'react'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { ScrollView } from 'devextreme-react'
import PropTypes from 'prop-types'
import { BoxRow, BoxItemTitle, BoxItem } from '../invoices/scrollListStyles'
import { ListRow, ListContainer, ListHeader, ListItem } from './newListStyle'

function SingleOrdersList({ orders, type }) {
  const center = { margin: '0 auto' }

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      {type === 'noHead' ? '' : <SingleTitleSection>Orders</SingleTitleSection>}
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>Products</BoxItemTitle>
              <BoxItemTitle>Member</BoxItemTitle>
              <BoxItemTitle>Resource</BoxItemTitle>
              <BoxItemTitle>Ordered</BoxItemTitle>
              <BoxItemTitle>Items</BoxItemTitle>
              <BoxItemTitle>Total Cost</BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {orders.length > 0 ? (
            orders.map(o => {
              console.log(o)
              const theDate = o.open_date?.split('T')
              const theProducts = []
              let itemQty = 0
              o.order_lines.map(ol => {
                itemQty += ol.quantity
                const product = {
                  quantity: ol.quantity,
                  name: ol.product.name,
                  price: ol.product.price,
                }
                return theProducts.push(product)
              })
              return (
                <ListRow>
                  <BoxRow>
                    <BoxItem>{theProducts.map(p => `${p.name} (x${p.quantity}), `)}</BoxItem>
                    <BoxItem>{`${o.member.user.first_name} ${o.member.user.last_name}`}</BoxItem>
                    <BoxItem>{o.booking !== null ? o.booking.resource.name : '-'}</BoxItem>
                    <BoxItem>{theDate[0]}</BoxItem>
                    <BoxItem>{itemQty}</BoxItem>
                    <BoxItem>{o.price !== null ? o.price : 0}</BoxItem>
                  </BoxRow>
                </ListRow>
              )
            })
          ) : (
            <ListRow>
              <ListItem style={center}>
                <strong>No orders data</strong>
              </ListItem>
            </ListRow>
          )}
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

SingleOrdersList.propTypes = {
  orders: PropTypes.array,
  type: PropTypes.string,
}

SingleOrdersList.defaultProps = {
  orders: [],
  type: '',
}

export default SingleOrdersList
