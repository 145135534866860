import * as yup from 'yup'

const validate = yup.object().shape({
  team_slug: yup.string().required(),
  date: yup.string().required(),
  invoice_data: yup.string().required(),
  date_due: yup.string().required(),
  type: yup.string().required(),
  status: yup.string().required(),
})

export default validate
