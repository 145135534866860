import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import { getMemberships, newGetMembers, newGetTeams, setMembership } from 'api'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Grid,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { SpinnerContainer } from 'components/template/layout/styled'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Controller } from 'react-hook-form'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { Container, ModalConfirmCreate, ModalText, ModalTitle, Title, Toolbar } from './styled'
import TextInputView from '../../atoms/textInput/view'
import { formatDate } from '../../../utils/utils'

const AddMembershipsFormView = ({ control, register, office }) => {
  const { t } = useTranslation()
  const [teamsData, setTeams] = useState()
  const [membershipsPlans, setMembershipsPlans] = useState()
  const [membership, setMembershipData] = useState({
    recurrent: 'false',
    numUnits: 1,
    status: 'active',
  })
  const [members, setMembers] = useState([])
  const [errors, setErrors] = useState({
    team: null,
    plan: null,
    price: null,
    start: null,
    end: null,
    member: null,
    recurrent: null,
    numUnits: null,
    free_days: null,
    free_credits: null,
  })
  const history = useHistory()
  const [modal, setModal] = useState(false)

  function getTeamsData(data) {
    const query = {
      pageSize: 1400,
      currentPage: 1,
      filter: {
        name: data !== undefined ? data : '',
        layoutOffice: office.slug,
      },
    }
    newGetTeams(query).then(response => {
      const teamsArr = response.teams.filter(team => team.name !== null)
      setTeams(teamsArr)
    })
  }

  useEffect(() => {
    if (teamsData === undefined) getTeamsData()
  }, [teamsData])

  function getMSData(data) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: data !== undefined ? data : '',
        layoutOffice: office.slug,
      },
    }
    getMemberships(query).then(response => setMembershipsPlans(response))
  }

  function getMembersData(data) {
    console.log('Get member data')
    newGetMembers({
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: data !== undefined ? data : '',
      },
    }).then(r => setMembers(r.members.length >= 0 ? r.members : ['No members']))
  }

  useEffect(() => {
    console.log(members.length)
    if (members.length === 0) getMembersData()
  }, [teamsData])
  useEffect(() => {
    if (membershipsPlans === undefined) getMSData()
  }, [teamsData])

  function getEndDate(info) {
    const value = info === 0 || info === undefined ? 30 : info
    const result = new Date()
    result.setDate(result.getDate() + value)
    return result
  }

  function formatFinalDate(date) {
    const theDate = date?.toString()?.split(' ')
    if (date !== undefined) {
      if (theDate[1] === 'Jan') theDate[1] = '01'
      if (theDate[1] === 'Feb') theDate[1] = '02'
      if (theDate[1] === 'Mar') theDate[1] = '03'
      if (theDate[1] === 'Apr') theDate[1] = '04'
      if (theDate[1] === 'May') theDate[1] = '05'
      if (theDate[1] === 'Jun') theDate[1] = '06'
      if (theDate[1] === 'Jul') theDate[1] = '07'
      if (theDate[1] === 'Aug') theDate[1] = '08'
      if (theDate[1] === 'Sep') theDate[1] = '09'
      if (theDate[1] === 'Oct') theDate[1] = '10'
      if (theDate[1] === 'Nov') theDate[1] = '11'
      if (theDate[1] === 'Dec') theDate[1] = '12'
      if (date === membership.start) theDate[4] = '00:00:00'
      if (date === membership.end) theDate[4] = '23:59:59'
      const dateFormat = `${theDate[3]}-${theDate[1]}-${theDate[2]}T${theDate[4]}`
      return dateFormat
    }
    return ''
  }

  function createMembership() {
    setModal(true)
    const startDate = formatFinalDate(membership?.start)
    const endDate = formatFinalDate(membership?.end)
    setMembershipData({ ...membership, start: startDate, end: endDate })
    setMembership({ ...membership, start: startDate, end: endDate }).then(
      setTimeout(() => {
        history.push('../../members/memberships')
      }, 2000)
    )
  }

  function validatemembership(data) {
    const textErrors = {
      team: 'Team is required',
      plan: 'Membership Plan is required',
      price: 'Price is required',
      start: 'Start date is required',
      end: 'End date is required',
      member: 'Member is required',
      recurrent: 'Recurrency data is required',
      numUnits: 'Number of memberships is required and must be a positive number',
    }

    const unitsRes =
      data?.numUnits === undefined || data?.numUnits === '' || data?.numUnits <= 0 || data?.numUnits === !Number
        ? textErrors.numUnits
        : null
    const teamRes = data?.team === undefined || data?.team === '' ? textErrors.team : null
    const planRes = data?.plan === undefined || data?.plan === '' ? textErrors.plan : null
    const priceRes = data?.price === undefined || data?.price === '' ? textErrors.price : null
    const startRes = data?.start === undefined || data?.start === '' ? textErrors.start : null
    const endRes = data?.end === undefined || data?.end === '' ? textErrors.end : null
    const memberRes = data?.member === undefined || data?.member === '' ? textErrors.member : null
    const recurrentRes = data?.recurrent === undefined || data?.recurrent === '' ? textErrors.recurrent : null

    setErrors({
      ...errors,
      team: teamRes,
      plan: planRes,
      price: priceRes,
      start: startRes,
      end: endRes,
      member: memberRes,
      recurrent: recurrentRes,
      numUnits: unitsRes,
      free_days: null,
      free_credits: null,
    })

    if (
      teamRes === null &&
      planRes === null &&
      priceRes === null &&
      startRes === null &&
      endRes === null &&
      memberRes === null &&
      recurrentRes === null &&
      unitsRes === null &&
      errors.free_days === null &&
      errors.free_credits === null
    ) {
      createMembership()
    }
  }

  useEffect(() => {}, [membershipsPlans, membership, errors, modal])

  let theMembers = members
  if (theMembers && membership.team !== undefined) theMembers = theMembers.filter(m => (m.team !== null ? m.team.slug === membership.team : ''))

  console.log(theMembers)

  if (teamsData !== undefined) {
    return (
      <Container>
        <Toolbar>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '15px' }}>
            <Grid item>
              <Title>{t('members.form.addMembership')}</Title>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  validatemembership({ ...membership })
                }}
              >
                {t('members.addMembership')}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <Modal open={modal}>
          <ModalConfirmCreate>
            <ModalTitle>Membership Created</ModalTitle>
            <ModalText>You will be redirect to Memberships List...</ModalText>
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          </ModalConfirmCreate>
        </Modal>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    name='teamAutocomplete'
                    options={teamsData !== undefined ? teamsData : [{ name: 'No Options' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
                    onChange={(e, data) => {
                      if (data !== undefined) {
                        setMembershipData({ ...membership, team: data?.slug })
                      } else {
                        setMembershipData({ ...membership, team: '' })
                      }
                    }}
                    onInputChange={(e, data) => {
                      getTeamsData(data)
                    }}
                  />
                  {errors.team === null ? '' : <SmallError>{errors.team}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name='membership'
                  control={control}
                  onChange={([, data]) => console.log(data)}
                  render={({ onChange, ...props }) => (
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        name='autocompleteMembership'
                        error={errors.plan !== null}
                        options={membershipsPlans}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label='Membership Plan' variant='outlined' />}
                        onChange={(e, data) => {
                          if (data !== undefined) {
                            setMembershipData({
                              ...membership,
                              plan: data?.slug,
                              price: data?.base_price,
                              free_days: data?.free_days,
                              free_credits: data?.free_credits,
                              start: new Date(),
                              end: getEndDate(data?.length_in_month),
                              payNow: false,
                              numUnits: 1,
                            })
                          } else {
                            setMembershipData({
                              ...membership,
                              plan: '',
                              price: '',
                              free_days: '',
                              free_credits: '',
                              start: '',
                              end: '',
                              payNow: false,
                              numUnits: 0,
                            })
                          }
                        }}
                        {...props}
                      />
                      {errors.plan === null ? '' : <SmallError>{errors.plan}</SmallError>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name='member'
                  control={control}
                  render={({ onChange, ...props }) => (
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        disabled={membership?.team ? '' : true}
                        name='autocompleteMembers'
                        options={theMembers}
                        getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                        renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                        onChange={(e, data) => {
                          if (data !== null) {
                            setMembershipData({ ...membership, member: data.slug })
                            // eslint-disable-next-line react/prop-types
                            props.field.onChange(data?.id)
                          } else {
                            setMembershipData({ ...membership, member: '' })
                          }
                        }}
                        onInputChange={(e, data) => getMembersData(data)}
                        {...props}
                      />
                      {errors.member === null ? '' : <SmallError>{errors.member}</SmallError>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Status</InputLabel>
                  <Select
                    label='Status'
                    variant='outlined'
                    value={membership.status}
                    onChange={value => {
                      if (value.target.value) {
                        setMembershipData({ ...membership, status: value.target.value })
                      }
                    }}
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='finished'>Finish</MenuItem>
                    <MenuItem value='pending_payment'>Pending</MenuItem>
                    <MenuItem value='cancelled'>Cancel</MenuItem>
                  </Select>
                  {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DatePicker
                    {...register('start')}
                    variant='inline'
                    inputVariant='outlined'
                    label={t('members.form.dateStart')}
                    name='start'
                    format='dd-MM-yyyy'
                    error={errors?.start !== null}
                    value={membership?.start === undefined ? '01/01/2021' : formatDate(membership?.start)}
                    onChange={value => setMembershipData({ ...membership, start: value })}
                  />
                  {errors.start === null ? '' : <SmallError>{errors.start}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DatePicker
                    {...register('end')}
                    variant='inline'
                    inputVariant='outlined'
                    label={t('members.form.dateEnd')}
                    format='dd-MM-yyyy'
                    name='end'
                    error={errors?.end !== null}
                    value={membership?.end === undefined ? new Date() : formatDate(membership?.end)}
                    onChange={value => setMembershipData({ ...membership, end: value })}
                  />
                  {errors.end === null ? '' : <SmallError>{errors.end}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextInputView
                    {...register('free_days')}
                    label={t('members.form.freeDays')}
                    name='free_days'
                    control={control}
                    error={errors?.free_days !== null}
                    value={membership?.free_days}
                    onChange={event => setMembershipData({ ...membership, free_days: event.target.value })}
                  />
                  {errors.free_days === null ? '' : <SmallError>{errors.free_days}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Recurrent</InputLabel>
                  <Select
                    label='Recurrent'
                    variant='outlined'
                    value={membership.recurrent}
                    onChange={e => {
                      if (e.target.value) setMembershipData({ ...membership, recurrent: e.target.value })
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Select>
                  {errors.recurrent === null ? '' : <SmallError>{errors.recurrent}</SmallError>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextInputView
                    {...register('price')}
                    label={t('members.form.price')}
                    name='price'
                    value={membership?.price}
                    error={errors?.price !== null}
                    onChange={event => setMembershipData({ ...membership, price: event.target.value })}
                  />
                  {errors.price === null ? '' : <SmallError>{errors.price}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextInputView
                    label={t('members.form.freeCredits')}
                    name='free_credits'
                    control={control}
                    error={errors?.free_credits !== null}
                    value={membership?.free_credits}
                    onChange={event => setMembershipData({ ...membership, free_credits: event.target.value })}
                  />
                  {errors.free_credits === null ? '' : <SmallError>{errors.free_credits}</SmallError>}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    id='number_desk_id'
                    name='numUnits'
                    label='Units'
                    variant='outlined'
                    type='number'
                    min={0}
                    value={membership?.numUnits}
                    onChange={e => {
                      setMembershipData({
                        ...membership,
                        numUnits: e.target.value >= 0 ? parseInt(e.target.value, 10) : 0,
                      })
                    }}
                  />
                  {errors.numUnits === null ? '' : <SmallError>{errors.numUnits}</SmallError>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

AddMembershipsFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  office: PropTypes.object,
}

AddMembershipsFormView.defaultProps = {
  errors: null,
  office: {},
}

export default AddMembershipsFormView
