import { useHistory, useLocation } from 'react-router-dom'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import HeaderToolbar from 'components/organisms/headerToolbar'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'
import React, { useEffect, useState } from 'react'
import ResourcesList from 'components/organisms/resources/resourcesList'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { CircularContainer } from 'components/molecules/defaultAvatar/defaultAvatarStyles'
import WorkAreasList from 'components/organisms/workAreas/workAreasList'
import { DashBoardContainer } from './dashboardStyles'

const RigthActions = ({ pathname, t }) => {
  const history = useHistory()
  const handleClickOnLink = to => history.push(to)
  switch (pathname) {
    case routes.space:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.spacesAdd)}>
          {t('space.addResource')}
        </Button>
      )
    case routes.spaceWorkAreas:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.spacesAddWA)}>
          {t('space.addWA')}
        </Button>
      )
    default:
      return null
  }
}

const DownActions = (pathname, office) => {
  useEffect(() => {}, [office])
  if (pathname === routes.space) return <ResourcesList layoutOffice={office} />
  if (pathname === routes.spaceWorkAreas) return <WorkAreasList layoutOffice={office} />
  return ''
}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

DownActions.propTypes = {
  pathname: PropTypes.string,
  t: PropTypes.string,
}

DownActions.defaultProps = {
  pathname: '',
  t: '',
}

function SpaceDashboard(props) {
  const { handleClickOnLink, office } = props
  const { pathname } = useLocation()
  const { t } = useTranslation()

  //TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('resources') ? { id: routes.space, to: routes.space, label: t('space.allResources') } : '',
    permited.includes('work_area')
      ? { id: routes.spaceWorkAreas, to: routes.spaceWorkAreas, label: t('space.workAreas') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <DashBoardContainer>
      <HeaderToolbar
        tabValue={pathname}
        tabs={theTabs}
        rightActions={<RigthActions pathname={pathname} t={t} handleClickOnLink={handleClickOnLink} />}
      />
      <DashBoardContainer>
        {pathname === '/space' || pathname === routes.spaceWorkAreas || pathname === routes.spaceOffices ? (
          DownActions(pathname, office)
        ) : (
          <SpinnerContainer>
            <CircularContainer color='secondary' />
          </SpinnerContainer>
        )}
      </DashBoardContainer>
    </DashBoardContainer>
  )
}

SpaceDashboard.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  office: PropTypes.object,
}

SpaceDashboard.defaultProps = {
  office: {},
}

export default SpaceDashboard
