import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setDashboardStaff } from 'api'
import PropTypes from 'prop-types'
import { Grid, Button, TextField, FormControl, MenuItem, Select, InputLabel, Modal } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import FeedbackModal from '../../molecules/modals/feedbackModal'
import { ModalCard } from '../bookings/modalStyles'

function ModalCreateStaff({ toClose }) {
  const [staff, setStaff] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    job_title: '',
    role: 'member',
  })

  const [fbModal, setfbModal] = useState(false)
  const history = useHistory()

  useEffect(() => {}, [staff])

  function createStaffMember() {
    setfbModal(true)
    setDashboardStaff(staff)
      .then(setfbModal(false))
      .then(
        setTimeout(() => {
          history.push('/users/staff')
        }, 1500)
      )
  }

  useEffect(() => {}, [fbModal])

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
    role: null,
  })

  useEffect(() => {}, [errors])

  function validate() {
    let canCreate = false
    const textErrors = {
      first_name: 'Name is required',
      last_name: 'Surname is required',
      email: 'Email is required',
      badMail: 'Must be a valid mail',
      role: 'Role is required',
    }

    const nameRes = staff.first_name === '' ? textErrors.first_name : null
    const surnameRes = staff.last_name === '' ? textErrors.last_name : null
    const emailRes = staff.email === '' ? textErrors.email : null
    const roleRes = staff.role === '' ? textErrors.role : null

    setErrors({
      first_name: nameRes,
      last_name: surnameRes,
      email: emailRes,
      role: roleRes,
    })

    if (nameRes === null && surnameRes === null && emailRes === null && roleRes === null) canCreate = true
    if (canCreate === true) createStaffMember()
  }

  return (
    <ModalCard>
      <Modal open={fbModal === true}>
        <FeedbackModal type='staff-create' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '10px' }}>
            <Grid item>
              <h3>Create Staff Member</h3>
            </Grid>
            <Grid item>
              <Button onClick={() => toClose()}>X</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Name'
                  onChange={e => setStaff({ ...staff, first_name: e.target.value })}
                  value={staff.first_name}
                  error={errors.first_name !== null}
                />
                {errors.first_name !== null ? <SmallError>{errors.first_name}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Surname'
                  onChange={e => setStaff({ ...staff, last_name: e.target.value })}
                  value={staff.last_name}
                  error={errors.last_name !== null}
                />
                {errors.last_name !== null ? <SmallError>{errors.last_name}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Email'
                  onChange={e => setStaff({ ...staff, email: e.target.value })}
                  value={staff.email}
                  error={errors.email !== null}
                />
                {errors.email !== null ? <SmallError>{errors.email}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Phone'
                  onChange={e => setStaff({ ...staff, phone: e.target.value })}
                  value={staff.phone}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Job Title'
                  onChange={e => setStaff({ ...staff, job_title: e.target.value })}
                  value={staff.job_title}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Role</InputLabel>
                <Select
                  variant='outlined'
                  label='Role'
                  onChange={e => {
                    console.log(e.target.value)
                    setStaff({ ...staff, role: e.target.value })
                  }}
                  value={staff.role}
                >
                  <MenuItem value='club_admin'>Admin</MenuItem>
                  <MenuItem value='club_manager'>Manager</MenuItem>
                  <MenuItem value='club_staff' selected>
                    Staff
                  </MenuItem>
                </Select>
                {errors.role !== null ? <SmallError>{errors.role}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify='center' style={{ marginTop: '25px' }}>
            <Button variant='contained' color='secondary' onClick={() => validate()}>
              Create Staff Member
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateStaff.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateStaff.defaultProps = {
  toClose: () => {},
}

export default ModalCreateStaff
