import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { newGetMembers, newGetTeams } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ScrollView } from 'devextreme-react'
import SearchInput from 'components/atoms/searchInput'
import {
  FilterInput,
  FilterSelect,
  ListContainer,
  ListHeader,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from './newListStyle'
import { BoxItem, BoxItemLG, BoxItemTitle, BoxItemTitleLG, BoxRow, BoxText } from '../invoices/scrollListStyles'

function NewMemerList({ layoutOffice }) {
  const [theMembers, setMembers] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'user__first_name',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const [theTeams, setTeams] = useState()
  const noLinkStyle = { textDecoration: 'none' }
  const slash = '-'
  const space = ' '
  const [paginate, setPaginate] = useState(true)

  function getTeamsData(value) {
    const query = {
      pageSize: 25,
      currentPage: 1,
      filter: {
        name: value === undefined ? '' : value,
        layoutOffice: layoutOffice.slug,
      },
    }
    newGetTeams(query)
      .then(response => setTeams(response))
      .catch(error => console.log(error))
  }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getMembersData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    newGetMembers(query).then(info => {
      setMembers(info)
      getPagination(info)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (page === undefined && pageInfo.target.parentElement.ariaLabel === null) page = pagination.thePage
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const prevPage = parseInt(pagination.thePage, 10) - 1
      page = prevPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getMembersData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    console.log('holi')
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getMembersData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = theMembers?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getMembersData(query)
  }

  function setTeamFilter(value) {
    let queryTeam = ''
    theTeams.teams.map(team => {
      if (value !== undefined && value !== '' && value === team.name) {
        queryTeam = team.slug
      }
      return ''
    })
    if (queryTeam !== undefined && queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: queryTeam },
      }
      getMembersData(query)
    } else {
      setFilter({ ...theFilter, team: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: '' },
      }
      getMembersData(query)
    }
  }

  function filterByPresence(event) {
    const presenceValue = event.target.value
    if (presenceValue !== 'none') {
      setFilter({ ...theFilter, presence: presenceValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, presence: presenceValue },
      }
      getMembersData(query)
    } else {
      setFilter({ ...theFilter, presence: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, presence: '' },
      }
      getMembersData(query)
    }
  }

  function filterByRole(event) {
    const roleValue = event.target.value
    if (roleValue !== 'none') {
      setFilter({ ...theFilter, role: roleValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, role: roleValue },
      }
      getMembersData(query)
    } else {
      setFilter({ ...theFilter, role: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, role: '' },
      }
      getMembersData(query)
    }
  }

  useEffect(() => {
    getMembersData()
    setFilter({ ...theFilter, layoutOffice: layoutOffice.slug })
  }, [layoutOffice])

  useEffect(() => {}, [theFilter, pagination])

  useEffect(() => {
    if (theMembers === undefined) getMembersData()
  }, [theMembers])

  useEffect(() => {
    if (theTeams === undefined) getTeamsData()
  }, [theTeams])

  // TIMEOUTS

  const [searchTeam, setSearchTeam] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) setDataFilter(searchFilter.filter)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by member or team...'
          onChange={e => {
            setSearchFilter({ ...searchFilter, filter: e.target.value })
          }}
        />
        <FilterInput>
          <FormControl variant='outlined'>
            <Autocomplete
              options={theTeams?.teams}
              getOptionLabel={option => option.name}
              style={{ width: 150 }}
              renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
              onInputChange={(e, value) => {
                setSearchTeam({
                  team: value,
                  filter: e.target.innerText,
                })
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        </FilterInput>
        <FilterInput>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label'>Presence</InputLabel>
            <FilterSelect onChange={event => filterByPresence(event)} label='Type filter'>
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='out'>Out</MenuItem>
              <MenuItem value='network_on'>Connected</MenuItem>
              <MenuItem value='checked_in'>Check-In</MenuItem>
            </FilterSelect>
          </FormControl>
        </FilterInput>
        <FilterInput>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label'>Role</InputLabel>
            <FilterSelect onChange={event => filterByRole(event)} label='Role filter'>
              <MenuItem value=''>None</MenuItem>
              <MenuItem value='team_admin'>Admin</MenuItem>
              <MenuItem value='team_member'>Member</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='referral'>Referral</MenuItem>
            </FilterSelect>
          </FormControl>
        </FilterInput>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('user__first_name')}>
                    <strong>Member</strong>
                  </Button>
                  {theMembers?.order === 'user__first_name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('team__name')}>
                    <strong>Team</strong>
                  </Button>
                  {theMembers?.order === 'team__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitleLG>
                <ListTitle>
                  <Button onClick={() => setOrderData('user__email')}>
                    <strong>Email</strong>
                  </Button>
                  {theMembers?.order === 'user__email' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitleLG>
              <BoxItemTitleLG>
                <ListTitle>
                  <Button onClick={() => setOrderData('office')}>
                    <strong>Club</strong>
                  </Button>
                  {theMembers?.order === 'office' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitleLG>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('user__role')}>
                    <strong>Role</strong>
                  </Button>
                  {theMembers?.order === 'user__role' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('user__presence')}>
                    <strong>Presence</strong>
                  </Button>
                  {theMembers?.order === 'user__presence' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('user__last_login')}>
                    <strong>Last Login</strong>
                  </Button>
                  {theMembers?.order === 'user__last_login' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {theMembers === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            theMembers.members &&
            theMembers.members.map(member => {
              const elipses = '...'
              const theName = member?.user?.first_name + space + member?.user?.last_name
              let prettyRole = '-'
              if (member.role === 'team_admin') prettyRole = 'Admin'
              if (member.role === 'team_member') prettyRole = 'Member'
              if (member.role === 'pending') prettyRole = 'Pending'
              if (member.role === 'referral') prettyRole = 'Referral'
              let lastLogin = ''
              if (member.user.last_login !== undefined) {
                lastLogin = member.user.last_login?.split('.')
              }
              let prettyPresence = '-'
              if (member.presence === 'out') prettyPresence = 'Out'
              if (member.presence === 'checked_in') prettyPresence = 'Check In'
              if (member.presence === 'network_on') prettyPresence = 'Connected'
              const prettyMail =
                member.user.email.length < 28 ? member.user.email : member.user.email.slice(0, 28) + elipses
              return (
                <Link to={{ pathname: `/members/${member.slug}`, state: member }} key={member.slug} style={noLinkStyle}>
                  <ListRow>
                    <BoxRow>
                      <BoxItem>
                        <BoxText>{theName}</BoxText>
                      </BoxItem>
                      <BoxItem>
                        <BoxText>{member.team?.name}</BoxText>
                      </BoxItem>
                      <BoxItemLG>
                        <BoxText>{prettyMail}</BoxText>
                      </BoxItemLG>
                      <BoxItemLG>
                        <BoxText>{member?.office?.name}</BoxText>
                      </BoxItemLG>
                      <BoxItem>
                        <BoxText>{prettyRole}</BoxText>
                      </BoxItem>
                      <BoxItem>
                        <BoxText>{prettyPresence}</BoxText>
                      </BoxItem>
                      <BoxItem>
                        <BoxText>{lastLogin !== undefined ? `${lastLogin[0].replace('T', ' ')}` : '-'}</BoxText>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || pagination.thePage === '' || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={event => setPagePagination(event)}
            count={pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
            disabled={paginate === false}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

NewMemerList.propTypes = {
  layoutOffice: PropTypes.object,
}

NewMemerList.defaultProps = {
  layoutOffice: null,
}

export default NewMemerList
