const translation = {
  validations: {
    phone: 'Phone number is not valid',
  },
  login: {
    title: 'Log In',
    subtitle: 'Enter your email address and password to log in.',
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    forgot: 'Forgot?',
    logIn: 'Log In',
    join: "Don't have an account? Join",
    error: 'Error login',
    name: 'Name',
    team: 'Team',
    space: 'Space',
  },
  drawer: {
    sections: {
      dashboard: 'Dashboard',
      members: 'Members',
      community: 'Community',
      space: 'Space',
      bookings: 'Bookings',
      foodAndDrinks: 'Food & Drinks',
      marketing: 'Marketing',
    },
    notifications: 'Notifications',
  },
  periods: {
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'Last 7 days',
    month: 'Current month',
    monthLast: 'Last month',
    month3: 'Last 3 months',
    month6: 'Last 6 months',
    custom: 'Custom',
  },
  clubsRange: {
    this: 'This club',
    all: 'All clubs',
  },
  members: {
    tabs: {
      allMembers: 'All members',
      companies: 'Teams',
      guests: 'Guests',
      invoices: 'Invoices',
      memberProfile: 'Member Profile',
      memberships: 'Memberships',
      resources: 'Resources',
      space: 'Spaces',
    },
    addMember: 'Add member',
    addMembership: 'Add membership',
    addCompany: 'Add company',
    addTeam: 'Add team',
    addGuest: 'Add guest',
    createInvoice: 'Create invoice',
    form: {
      addMember: 'Add member',
      addMembership: 'Add membership',
      photo: 'Photo',
      firstName: 'First Name',
      company: 'company',
      jobTitle: 'Job title',
      contactEmailAddress: 'Contact email address',
      secondName: 'Second name',
      role: 'Role',
      team: 'Team',
      contactPhoneNumber: 'Contact phone mumber',
      startDate: 'start date',
      firstInvoiceDate: 'First invoice date',
      assignAssets: 'Assign assets',
      assingMembership: 'Assign membership',
      billingAddress: 'Billing address',
      streetAndNumber: 'Street and number',
      floorNumber: 'Floor number',
      ifApplicable: 'if applicable',
      townCity: 'Town/City',
      country: 'Country',
      doorName: 'Door name',
      postCode: 'Postcode',
      teamMember: 'Team member',
      teamAdmin: 'Team admin',
      referral: 'Referral',
      price: 'Price',
      dateStart: 'Date Start',
      dateEnd: 'Date End',
      freeDays: 'Free Days',
      freeCredits: 'Credits',
    },
  },
  profile: {
    tabs: {
      profile: 'Profile',
      company: 'Company',
      membership: 'Membership',
      staff: 'Staff',
      //payments: 'Payments',
      integrations: 'Integrations',
      theme: 'Theme',
      //referral: 'Referral',
    },
    theme: {
      primaryColor: 'Primary color',
      secondaryColor: 'Secondary color',
      font: 'Font',
      loginImage: 'Login image',
      favicon: 'favicon',
      loginPlaceholder: 'JPG or PNG, no larger than 10MB',
      name: 'name',
      save: 'Save',
    },
    addStaff: 'Add Staff',
    addMS: 'Add MS Plan',
    addProfile: 'Add Profile',
  },
  dropzoneInput: {
    placeholder: 'Add',
  },
  booking: {
    form: {
      team: 'Team',
      guest: 'Guest / Member',
      member: 'Member',
      referal: 'Referal',
      startAt: 'Set start date',
      finishAt: 'Set finish date',
      type: 'Booking Type',
      office: 'Club',
      workArea: 'Work Area',
      resource: 'Resource',
      membership: 'Membership',
      price: 'Price',
      comments: 'Comments',
      bookingBtn: 'Add Booking',
      number: 'Nº of Bookings',
      startHourAt: 'Set start time',
      finishHourAt: 'Set finish time',
      officeSpace: 'Office space',
      meetingRoom: 'Meeting room',
      lounge: 'Lounge',
      hotDesk: 'Hot Desk',
      autoComplete: 'Auto-Complete',
      autoLimited: 'Auto-Limited',
      origin: 'Origin',
      regular: 'Regular',
      trial: 'Trial',
      referral: 'Referral',
      deference: 'Deference',
      numberDesk: 'Set number of Desks',
      sendMail: 'Send Mail',
      sendPush: 'Send Push',
      payment: 'Payment Required',
      sendInvoice: 'Send Invoice',
    },
    success: 'Booking Success',
    error: 'Booking Failure',
    addMeetingRoom: 'Meeting Room',
    addDesk: 'Hot Desk',
    addMap: 'Floor Map',
    list: 'Booking List',
  },
  memberProfile: {
    notifyNow: 'Notify Now',
    allowMail: 'Allow Mail',
    allowPush: 'Allow Push',
    autoAsignDesk: 'Auto Asign Desk',
    autoCheckin: 'Auto Check-In',
    validationLevel: 'Validation level',
    onboardingLevel: 'On Boarding level',
    resetPassSuccess: 'Please check your email inbox for further instructions',
    resetPassError: 'Error',
    resetPassword: 'Reset Password',
    resendInvitation: 'Resend Invitation',
    resendInvitationSuccess: 'The invitation has been resent',
    resendInvitationError: 'The invitation has not been resent',
  },
  space: {
    addResource: 'Add Resource',
    addWA: 'Add Work Area',
    allResources: 'All Resources',
    offices: 'Offices',
    workAreas: 'Work Areas',
  },
  resource: {
    form: {
      name: 'Name',
      identifier: 'Id',
      type: 'Type',
      status: 'Status',
      capacity: 'Capacity',
      unit: 'Unit',
    },
    updateResource: 'Update',
    singleResource: 'Details',
    saveChanges: 'Save Changes',
  },
  marketing: {
    tabs: {
      offers: 'Offers',
      offerUser: 'Offers User',
    },
    error: 'Error',
    success: 'Success',
    addOffer: 'Add Offer',
    updateOffer: 'Edit Offer',
    addOfferUser: 'Add Offer User',
    updateOfferUser: 'Edit Offer User',
    form: {
      addOffer: 'Add Offer',
      updateOffer: 'Edit Offer',
      code: 'Code',
      title: 'Title',
      subtitle: 'Subtitle',
      description: 'Description',
      office: 'Offices',
      dateStart: 'Date Start',
      timeStart: 'Time Start',
      dateEnd: 'Date End',
      timeEnd: 'Time End',
      workOnly: 'Work Only',
      onlyTeamAdmin: 'Only Team Admin',
      limitPerCampaign: 'Limit Per Campaign',
      limitPerTeam: 'Limit Per Team',
      limitPerMember: 'Limit Per Member',
      screenAfterAccept: 'Screen After Accept',
      price: 'Price',
      discount: 'Discount',
      type: 'Type',
      status: 'Status',
      sendNotification: 'Send Notification',
    },
    campaigns: 'Campaigns',
    addCampaign: 'Add Campaign',
  },
  invoices: {
    success: 'Invoice Success',
    error: 'Invoice Error',
    form: {
      addInvoice: 'Create Invoice',
      addLine: 'Add Line',
      editInvoice: 'Edit Invoice',
    },
  },
  foodAndDrinks: {
    foodAndDrinks: 'Food & Drinks',
    order: 'Orders',
    stock: 'Stock',
    bar: 'Bar',
    categories: 'Product Categories',
    addProduct: 'Add Product',
    addCategory: 'Add Category',
  },
  community: {
    tickets: 'Tickets',
    notices: 'Notice Board',
    events: 'Events',
  },
  dashboard: {
    cards: 'Cards',
    occupations: 'Occupation Info',
    bookings: 'Bookings Info',
    revenue: 'Revenue Info',
  },
}

export default translation
