import React, { useEffect, useState } from 'react'
import { CircularProgress, Modal } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import PropTypes from 'prop-types'
import ModalInvoiceData from './modalInvoiceData'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItemTitle, BoxRow, BoxItem } from './scrollListStyles'

function SingleInvoicesDatasList(props) {
  const { invoicesData, team, modal, setModalInvoiceData } = props

  useEffect(() => {}, [modal])

  const center = { margin: '0 auto' }

  function toClose() {
    setModalInvoiceData({ ...modal, show: false, invData: null })
  }

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (invoicesData !== undefined) {
    return (
      <ListContainer>
        <Modal open={modal.show === true}>
          <ModalInvoiceData toClose={toClose} team={team} invoiceData={modal.invData} />
        </Modal>
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Name</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Address</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>City</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Post Code</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Status</strong>
                  </ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {invoicesData.length <= 0 ? (
              <ListRow>
                <ListItem style={center}>
                  <strong>No Payments Data</strong>
                </ListItem>
              </ListRow>
            ) : (
              invoicesData.map((invoice, index) => {
                const space = ' '
                const address1 = invoice.address_1 !== null ? invoice.address_1 : ''
                const address2 = invoice.address_2 !== null ? invoice.address_2 : ''
                const address = address1 + space + address2
                if (index >= 10) return ''
                return (
                  <ListRow key={index} onClick={() => setModalInvoiceData({ ...modal, show: true, invData: invoice })}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{invoice.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{address}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.town}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.post_code}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.status}</ListItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                )
              })
            )}
          </ListContainer>
        </ScrollView>
      </ListContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}
SingleInvoicesDatasList.propTypes = {
  invoicesData: PropTypes.array,
  team: PropTypes.string,
  modal: PropTypes.object,
  setModalInvoiceData: PropTypes.func,
}

SingleInvoicesDatasList.defaultProps = {
  invoicesData: [],
  team: '',
  modal: {},
  setModalInvoiceData: () => {},
}
export default SingleInvoicesDatasList
