import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
} from 'devextreme-react/chart'
import { ListContainer, ListHeader, ListRow, ListTitle, SpinnerContainer } from '../members/newListStyle'

function OfficeRevenue({ stats }) {
  return (
    <Grid>
      {stats?.stats?.length > 0 ? (
        <ListContainer>
          <Chart palette='Violet' dataSource={stats?.graph_revenue} title='Office space revenue'>
            <CommonSeriesSettings argumentField='month' type='spline' />
            <CommonAxisSettings>
              <Grid />
            </CommonAxisSettings>
            {stats?.graph_revenue_legend?.map(item => (
              <Series key={item.value} valueField={item.value} name={item.name} />
            ))}
            <Margin bottom={20} />
            <ArgumentAxis allowDecimals={false} axisDivisionFactor={60}>
              <Label>
                <Format type='decimal' />
              </Label>
            </ArgumentAxis>
            <Legend verticalAlignment='top' horizontalAlignment='right' />
            <Export />
            <Tooltip />
          </Chart>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Month</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Incomes</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Day Passes</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Packages</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Flex 4</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Flex 10</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Other Flex</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Portals</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Full Members</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Offices</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Meeting Rooms</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>F&D</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {stats?.stats?.map((row, i) => {
            const date = row?.date_to?.split(' ')
            return (
              <ListRow key={i + 1}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xs={1}>
                    <ListTitle>{date !== undefined ? date[0] : '-'}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_total).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_day_passes).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_packages).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.income_flex_4).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.income_flex_10).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.income_flex_other).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_listings).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_full_members).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_offices).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.incomes_mr).toFixed(2)}`}</ListTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <ListTitle>{`£${parseFloat(row?.income_bar_gross).toFixed(2)}`}</ListTitle>
                  </Grid>
                </Grid>
              </ListRow>
            )
          })}
        </ListContainer>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

OfficeRevenue.propTypes = {
  stats: PropTypes.object,
}

OfficeRevenue.defaultProps = {
  stats: {},
}

export default OfficeRevenue
