import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
//import Container from '@material-ui/core/Container'
//import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItemTitle, BoxRow, BoxItem } from '../invoices/scrollListStyles'

function SingleBookingList(props) {
  const { bookings /*, showViewAll*/, type } = props
  const center = { margin: '0 auto' }

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (bookings !== undefined) {
    return (
      <ListContainer>
        {type === 'noHead' ? '' : <SingleTitleSection>Related bookings</SingleTitleSection>}
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Start Date</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>End Date</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Member</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Resource</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Status</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Price</strong>
                  </ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {bookings.length > 0 ? (
              bookings
                .sort((a, b) => (a.planned_start < b.planned_start ? 1 : -1))
                .map((booking, index) => {
                  const startDate = booking.planned_start?.split('T')
                  const endDate = booking.planned_end?.split('T')
                  let memberName = '-'
                  if (booking.member) {
                    memberName = `${booking.member?.user?.first_name} ${booking.member?.user?.last_name}`
                  }
                  if (booking.guest) {
                    memberName = booking.guest.name
                  }
                  if (index >= 10) return ''
                  return (
                    <Link to={`/bookings/${booking.code}`} key={index} style={{ textDecoration: 'none' }}>
                      <ListRow>
                        <BoxRow>
                          <BoxItem>
                            <ListItem>
                              <strong>{startDate === undefined ? '-' : startDate[0]}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{endDate === undefined ? '-' : endDate[0]}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>
                                {memberName}
                              </strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>
                                {booking?.resource?.name}
                              </strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{booking.status}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{booking.single_price}</strong>
                            </ListItem>
                          </BoxItem>
                        </BoxRow>
                      </ListRow>
                    </Link>
                  )
                })
            ) : (
              <ListRow>
                <ListItem style={center}>
                  <strong>No bookings data</strong>
                </ListItem>
              </ListRow>
            )}
            {/*showViewAll && (
          <Container style={{ textAlign: 'right', padding: 10 }}>
            <Link to={routes.bookings} style={{ textDecoration: 'none' }}>
              Vie All
            </Link>
          </Container>
        )*/}
          </ListContainer>
        </ScrollView>
      </ListContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

SingleBookingList.propTypes = {
  bookings: PropTypes.array,
  //showViewAll: PropTypes.bool,
  type: PropTypes.string,
}

SingleBookingList.defaultProps = {
  bookings: [],
  //showViewAll: false,
  type: '',
}

export default SingleBookingList
