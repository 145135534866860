import { useEffect, useState } from 'react'
import {
  getDashboardWorkAreas,
  getGuestByTeam,
  getMembersByTeam,
  newGetTeams,
  calculateBookingPrice,
  setBooking,
  getClientsReferral,
} from 'api'
import { Grid, Button, FormControl, TextField, Checkbox, Modal } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import { PropTypes } from 'prop-types'
import { colors } from 'config/colors'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import formatDates from '../../atoms/formatDates/formatDates'
import FeedbackModal from '../../molecules/modals/feedbackModal'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalCreateDesk({ closeModal, deskData, office }) {
  const { theDay, theMember, theOffice } = deskData
  const today = new Date(theDay)
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const [theBooking, setBookingData] = useState({
    day: theDay === undefined ? new Date() : today,
    startTime: new Date().setHours(9, 0, 0),
    endTime: new Date().setHours(18, 0, 0),
    alreadyPaid: false,
    num_bookings: 1,
    price: 0,
    office,
    client: null,
    team: null,
    work_area: null,
    bookings: [
      {
        member: null,
        guest: null,
        pre_paid: null,
        complimentary: false,
        price: '',
      },
    ],
  })

  const [theFilter, setFilter] = useState({
    status: 'active',
    name: '',
    member: '',
    type: '',
    team: '',
    order: '-start',
  })
  //const [pagination] = useState({
  //  resultsTotal: ' ... ',
  //  resultsByPage: 15,
  //  thePage: 1,
  //  pagesTotal: '',
  //})

  const [emptySearch] = useState([{ name: 'Type something' }])
  const [teamMembers, setTeamMembers] = useState()
  const [numVisible, setNumVisible] = useState(true)
  const [waData, setWAData] = useState()
  const [teamGuests, setTeamGuests] = useState()
  const [teamPrices, setTeamPrices] = useState()
  const [lists, setLists] = useState(false)
  const [theClients, setClients] = useState()
  const [theTeams, setTeams] = useState()
  const [errors, setErrors] = useState({
    team: null,
    work_area: null,
    booking_size: null,
    day: null,
    start: null,
    end: null,
  })

  const [feedback, setFeedBack] = useState({
    show: false,
    data: null,
  })

  const [searchClient, setSearchClient] = useState('')
  const [searchTeam, setSearchTeam] = useState('')

  //
  // Filters
  //
  function setClientFilter(value) {
    console.log(value)
    let queryClient = ''
    theClients.clients.map(t => {
      if (value !== undefined && value !== '' && value !== null) {
        queryClient = t.slug
      }
      return ''
    })
    if (queryClient !== undefined || queryClient !== '') {
      setFilter({ ...theFilter, client: queryClient })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, client: queryClient },
      //}
    } else {
      setFilter({ ...theFilter, client: '' })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, client: '' },
      //}
    }
  }

  function setTeamFilter(value) {
    console.log(value)
    let queryTeam = ''
    theTeams.teams.map(t => {
      if (value !== undefined && value !== '' && value !== null) {
        queryTeam = t.slug
      }
      return ''
    })
    if (queryTeam !== undefined || queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, team: queryTeam },
      //}
    } else {
      setFilter({ ...theFilter, team: '' })
      //const query = {
      //  pageSize: pagination.resultsByPage,
      //  currentPage: pagination.thePage,
      //  filter: { ...theFilter, team: '' },
      //}
    }
  }

  function setDataBookingsRows(i, value, type) {
    const rowsArr = theBooking.bookings
    switch (type) {
      case 'complimentary':
        rowsArr[i].complimentary = value === 'true'
        rowsArr[i].pre_paid = null
        rowsArr[i].price = 0
        break
      case 'alreadyPaid':
        rowsArr[i].alreadyPaid = value === 'true'
        rowsArr[i].price = 0
        break
      case 'member':
        rowsArr[i].member = value
        break
      case 'guest':
        rowsArr[i].guest = value
        break
      case 'pre_paid':
        rowsArr[i].pre_paid = value
        rowsArr[i].price = 0
        break
      case 'single_price':
        rowsArr[i].price = value
        break
      default:
        return ''
    }
    console.log('rowsarr', rowsArr)
    return setBookingData({ ...theBooking, bookings: rowsArr })
  }

  function getMembersData(teamSlug) {
    getMembersByTeam(teamSlug)
      .then(r => {
        setTeamMembers(r)
        if (theMember !== undefined && theBooking.bookings[0].member === null) {
          const member = r.filter(m => m.slug === theMember.slug)
          console.log(member)
          setDataBookingsRows(0, member[0], 'member')
        }
      })
      .catch(e => console.log(e))
    getGuestByTeam(teamSlug).then(r => setTeamGuests(r))
  }

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => {
      //const client = r.clients.filter(t => t.slug === theClient?.slug)
      //const firstClient = client[0]
      //getMembersData(firstClient?.slug)
      //setBookingData({ ...theBooking, client: firstClient })
      setClients(r)
    })
  }

  function getTeamsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => {
      //getMembersData(firstTeam?.slug)
      setTeams(r)
    })
  }

  function cleanErrors() {
    console.log(errors)
    if (
      errors.team !== null ||
      errors.client !== null ||
      errors.work_area !== null ||
      errors.day !== null ||
      errors.end !== null
    ) {
      console.log('Set errors to null')
      setErrors({
        team: null,
        client: null,
        work_area: null,
        day: null,
        end: null,
      })
    }
  }
  //
  // Use effect
  //
  useEffect(() => {
    getClientsData()
  }, [])

  useEffect(() => {
    cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        //if (searchClient.filter) setClientFilter(searchClient.filter)
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  useEffect(() => {
    cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        //if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  function setNumRows(value) {
    console.log('Num rows ', value)
    if (value > 0) {
      const rowsArr = theBooking.bookings
      const deskBook = {
        member: null,
        guest: null,
        pre_paid: null,
        complimentary: false,
        price: 0,
      }
      if (value > 0 && value > rowsArr.length) {
        rowsArr.push(deskBook)
        setBookingData({ ...theBooking, bookings: rowsArr })
      } else {
        rowsArr.pop()
        setBookingData({ ...theBooking, bookings: rowsArr })
      }
    }
  }

  function getWAData() {
    console.log('Searching office ', office.slug)
    const query = {
      currentPage: 1,
      pageSize: 1000,
      filter: {
        office: office.slug,
        area_type: 'open_space,suite',
      },
    }
    getDashboardWorkAreas(query).then(r => {
      if (theBooking.work_area === null) {
        const was = r.work_areas.filter(wa => wa.office.slug === theOffice?.slug)
        const firstWA = was[0]
        setBookingData({ ...theBooking, work_area: firstWA })
      }
      setWAData(r)
    })
  }

  function calculatePrices() {
    console.log('Prices for calculate ', theBooking.bookings.length)
    //if (lists === true) {
    let resourceType = 'hot_desk'
    if (!numVisible) resourceType = 'suite'
    const query = {
      team: theBooking.team,
      client: theBooking.client,
      office: office.slug,
      workArea: theBooking.work_area,
      bookingType: resourceType,
      bookingSize: theBooking.bookings.length,
    }
    console.log('Sending information to calculate')
    calculateBookingPrice(query).then(r => {
      console.log('After calculating price')
      console.log(r)
      const bookingsArr = theBooking.bookings
      bookingsArr.map((b, i) => {
        if (r.broker_pending_payment.length >= i) {
          bookingsArr[i].price = r?.broker_pending_payment[i]?.price
        } else {
          bookingsArr[i].price = r?.pending_payment[i]
        }
        console.log(bookingsArr[i]?.price)
        return ''
      })
      setBookingData({ ...theBooking, bookings: bookingsArr })
      setTeamPrices(r)
    })
    //}
  }

  function validate(data) {
    console.log(data)
    let canCreate = false
    const textErrors = {
      team: 'Team or client is required',
      client: 'Broker or team is required',
      work_area: 'Work Area is required',
      day: 'Day is required',
      start: 'Start time is required',
      end: 'End time is required',
      badTime: 'End time can be before start time',
      badDate: 'Booking day can`t be in the past',
    }

    let teamRes = data.team === null || data.team === undefined ? textErrors.team : null
    let clientRes = data.client === null || data.client === undefined ? textErrors.client : null
    const waRes = data.work_area === null || data.work_area === undefined ? textErrors.work_area : null
    let dayRes = data.day === null || data.day === undefined ? textErrors.day : null
    if (data.day < yesterday) dayRes = textErrors.badDate
    const endRes = data.endTime <= data.startTime ? textErrors.badTime : null
    console.log(teamRes, clientRes)

    if (teamRes === null || clientRes === null) {
      teamRes = null
      clientRes = null
    }

    console.log(teamRes, clientRes)
    setErrors({
      team: teamRes,
      client: clientRes,
      work_area: waRes,
      day: dayRes,
      end: endRes,
    })
    if (clientRes === null && teamRes === null && waRes === null && dayRes === null && endRes === null) canCreate = true
    return canCreate
  }

  function createBookings(data) {
    if (!validate(data)) return
    setFeedBack({ show: true, data: null })
    const aDay = formatDates(theBooking.day)?.split('T')
    const theStart = formatDates(new Date(theBooking.startTime), 'time')?.split('T')
    const theEnd = formatDates(new Date(theBooking.endTime), 'time')?.split('T')
    const bookingsArr = []
    console.log(theBooking)
    console.log(theBooking.bookings)
    console.log(theBooking.work_area)
    let resourceType = 'hot_desk'
    if (!numVisible) resourceType = 'suite'
    theBooking.bookings.map(b => {
      const booking = {
        team: theBooking.team,
        client: theBooking.client,
        work_area: theBooking.work_area?.slug,
        price: b.price,
        start: { dateTime: `${aDay[0]}T${theStart[1]}` },
        end: { dateTime: `${aDay[0]}T${theEnd[1]}` },
        booking_type: resourceType,
        complimentary: b.complimentary,
        payNow: false,
        alreadyPaid: b.alreadyPaid || false,
      }
      if (b.member !== null) booking.member = b.member.slug
      if (b.pre_paid !== null) booking.pre_paid = b.pre_paid.code
      if (b.guest !== null) booking.guest = b.guest.slug
      bookingsArr.push(booking)
      return ''
    })
    setBooking(bookingsArr)
      .then(r => {
        setFeedBack({ ...feedback, data: r })
        closeModal()
      })
      .catch((e) => {
        console.log(e)
        setFeedBack({ show: false, data: 'Error' })
      })
  }

  useEffect(() => {
    if (waData === undefined) getWAData()
  }, [waData, teamMembers, teamGuests])

  useEffect(() => {
    console.log('Changes partial state ')
    if (theBooking.team !== null || theBooking.client !== null) calculatePrices()
  }, [theBooking.team, theBooking.client, theBooking.work_area, theBooking.bookings.length])

  useEffect(() => {
    console.log(theBooking)
    if ((theBooking.client !== null || theBooking.team !== null) && theBooking.work_area !== null) {
      setLists(true)
    }
    if ((theBooking.client === null || theBooking.team === null) && theBooking.work_area === null) setLists(false)
  }, [theBooking])

  useEffect(() => {
    console.log('Changes in bookings length', theBooking.bookings.length)
  }, [theBooking.bookings.length])

  useEffect(() => {}, [errors])

  return (
    <ModalCard>
      <Modal open={feedback.show === true}>
        <FeedbackModal type='booking' data={feedback.data} />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '18px' }}>
            <Grid item>
              <ModalTitle>New booking</ModalTitle>
            </Grid>
            <Grid item>
              <Button onClick={() => closeModal()}>x</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  options={theClients ? theClients?.clients : emptySearch}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label='Broker' variant='outlined' />}
                  onInputChange={(e, value) => {
                    cleanErrors()
                    setSearchClient({
                      client: value,
                      filter: e.target.innerText,
                    })
                  }}
                  onChange={(e, data) => {
                    if (data !== undefined && data !== null) {
                      setClientFilter(data.slug)
                      setBookingData({ ...theBooking, client: data.slug })
                    } else setBookingData({ ...theBooking, client: null })
                  }}
                  loading
                  loadingText='Searching...'
                  noOptionsText='No Results'
                  error={errors.client !== null}
                />
                {errors.client !== null ? <SmallError>{errors.client}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  options={theTeams ? theTeams?.teams : emptySearch}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
                  onInputChange={(e, value) => {
                    cleanErrors()
                    setSearchTeam({
                      team: value,
                      filter: e.target.innerText,
                    })
                  }}
                  onChange={(e, data) => {
                    console.log(e, data)
                    if (data !== undefined && data !== null) {
                      setTeamFilter(data.slug)
                      setBookingData({ ...theBooking, team: data.slug })
                      getMembersData(data.slug)
                    } else setBookingData({ ...theBooking, team: null })
                  }}
                  loading
                  loadingText='Searching...'
                  noOptionsText='No Results'
                  error={errors.team !== null}
                />
                {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Work Area'
                  name='work_area'
                  value={theBooking.work_area !== null ? theBooking.work_area : [{ name: 'No data' }]}
                  options={waData ? waData?.work_areas : emptySearch}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField {...params} label='Work Area' variant='outlined' error={errors.work_area !== null} />
                  )}
                  variant='outlined'
                  onChange={(e, data) => {
                    console.log(data)
                    if (data?.area_type === 'suite') {
                      setNumVisible(false)
                    } else {
                      setNumVisible(true)
                    }
                    cleanErrors()
                    if (data !== undefined) setBookingData({ ...theBooking, work_area: data })
                    else setBookingData({ ...theBooking, work_area: null })
                  }}
                />
              </FormControl>
              {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theBooking?.day ? theBooking.day : new Date()}
                  name='day'
                  inputVariant='outlined'
                  helperText={null}
                  format='yyyy-MM-dd'
                  onChange={value => setBookingData({ ...theBooking, day: value })}
                  label='Day'
                  autoOk
                  error={errors.day !== null}
                />
                {errors.day !== null ? <SmallError>{errors.day}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='Start'
                  inputVariant='outlined'
                  name='startTime'
                  minutesStep={5}
                  value={theBooking?.startTime}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  onChange={value => setBookingData({ ...theBooking, startTime: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='End'
                  name='endTime'
                  inputVariant='outlined'
                  minutesStep={5}
                  value={theBooking?.endTime}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  onChange={value => setBookingData({ ...theBooking, endTime: value })}
                  error={errors.end !== null}
                />
              </FormControl>
              {errors.end !== null ? <SmallError>{errors.end}</SmallError> : ''}
            </Grid>
            {numVisible &&
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    type='number'
                    value={theBooking.bookings.length}
                    variant='outlined'
                    name='num_bookings'
                    label='Number of Desk'
                    onChange={e => {
                      console.log(e.target.value >= 1)
                      setBookingData({
                        ...theBooking,
                        num_bookings: parseInt(e.target.value, 10) >= 1 ? parseInt(e.target.value, 10) : 1,
                      })
                      setNumRows(e.target.value)
                      //calculatePrices(parseInt(e.target.value, 10))
                    }}
                    onWheel={e => e.preventDefault()}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item>
          {lists === false ? (
            ''
          ) : (
            <Grid item style={{ marginBottom: '15px' }}>
              Desk Bookings
            </Grid>
          )}
          {lists === false
            ? ''
            : theBooking.bookings?.map((b, i) => {
                console.log()
                return (
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='space-between'
                    key={i}
                    spacing={1}
                    style={{ borderTop: `1px solid ${colors.softgray}`, marginBottom: '8px' }}
                  >
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          label='Member'
                          name='member'
                          value={b?.member}
                          options={
                            teamMembers !== undefined
                              ? teamMembers
                              : [{ user: { first_name: 'No', last_name: 'Data' } }]
                          }
                          getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                          renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                          variant='outlined'
                          onChange={(e, data) => {
                            if (data !== undefined) setDataBookingsRows(i, data, 'member')
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          label='Package'
                          name='pre_paid'
                          options={teamPrices?.selected_prepaids}
                          getOptionLabel={option =>
                            `${option.membership?.plan_membership.name} - £${option.single_price}`
                          }
                          renderInput={params => <TextField {...params} label='Package' variant='outlined' />}
                          variant='outlined'
                          disabled={b.complimentary === true}
                          onChange={(e, data) => {
                            if (data !== undefined) setDataBookingsRows(i, data, 'pre_paid')
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={b.price}
                          onChange={e =>
                            e.target.value >= 0 ? setDataBookingsRows(i, e.target.value, 'single_price') : 0
                          }
                          name='single-price'
                          label='Price'
                          variant='outlined'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container direction='column' alignItems='center' justify='center'>
                        <Grid item>
                          <FormControl>
                            <Checkbox
                              size='small'
                              checked={b.complimentary === true}
                              onChange={e => {
                                setDataBookingsRows(i, b.complimentary === true ? 'false' : 'true', e.target.name)
                              }}
                              name='complimentary'
                            />
                          </FormControl>
                        </Grid>
                        <Grid item style={{ fontSize: '10px' }}>
                          Complimentary
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container direction='column' alignItems='center' justify='center'>
                        <Grid item>
                          <FormControl>
                            <Checkbox
                              size='small'
                              checked={b.alreadyPaid === true}
                              onChange={e => {
                                setDataBookingsRows(i, b.alreadyPaid === true ? 'false' : 'true', e.target.name)
                              }}
                              name='alreadyPaid'
                            />
                          </FormControl>
                        </Grid>
                        <Grid item style={{ fontSize: '10px' }}>
                          Already Paid
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='center'>
            <Grid item>
              <Button color='secondary' variant='contained' onClick={() => createBookings(theBooking)}>
                Create Booking
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateDesk.propTypes = {
  closeModal: PropTypes.func,
  office: PropTypes.object,
  deskData: PropTypes.object,
}

ModalCreateDesk.defaultProps = {
  closeModal: () => {},
  office: {},
  deskData: { status: 'none' },
}

export default ModalCreateDesk
