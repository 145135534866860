import { useEffect, useState } from 'react'
import { getMemberships, setMembership } from 'api'
import PropType from 'prop-types'
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { DatePicker } from '@material-ui/pickers'
import formatDates from 'components/atoms/formatDates/formatDates'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard } from '../bookings/modalStyles'

function ModalCreateMS({ toClose, m, t }) {
  const [theMS, setMSData] = useState({
    member: m,
    team: t,
    price: 0,
    status: 'active',
    create_date: new Date(),
    start: new Date(),
    end: new Date(),
    plan: null,
    recurrent: false,
    numUnits: 1,
    free_credits: '0.00',
    free_days: 0,
    payNow: false,
  })

  useEffect(() => {}, [theMS])

  const [membershipsData, setMembershipsData] = useState()

  function getMembershipsData() {
    getMemberships().then(r => setMembershipsData(r))
  }

  useEffect(() => {
    if (membershipsData === undefined) getMembershipsData()
  }, [membershipsData])

  const [errors, setErrors] = useState({
    member: null,
    team: null,
    plan: null,
  })

  const [feedback, setFeedback] = useState({
    show: false,
    info: 'create-ms',
  })

  function createMS() {
    setFeedback({ ...feedback, show: true })
    const membership = {
      ...theMS,
      member: theMS.member.slug,
      team: theMS.team.slug,
      plan: theMS.plan.slug,
      start: formatDates(theMS.start, 'start'),
      end: formatDates(theMS.end),
      create_date: formatDates(theMS.create_date, 'time'),
    }
    console.log(membership)
    setMembership(membership).then(
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    )
  }

  function validate() {
    let canCreate = false
    const textErrors = {
      member: 'Member is mandatory',
      team: 'Team is mandatory',
      plan: 'Plan is mandatory',
    }
    const memberRes = theMS.member === null || theMS.member === undefined ? textErrors.member : null
    const planRes = theMS.plan === null || theMS.plan === undefined ? textErrors.plan : null
    const teamRes = theMS.team === null || theMS.team === undefined ? textErrors.team : null

    setErrors({
      member: memberRes,
      plan: planRes,
      team: teamRes,
    })

    if (memberRes === null && teamRes === null && planRes === null) canCreate = true

    if (canCreate === true) createMS()
  }

  useEffect(() => {}, [errors])

  return (
    <ModalCard>
      <Modal open={feedback.show === true}>
        <FeedbackModal type='ms-create-single' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between'>
            <Grid item>
              <h3>Create Membership</h3>
            </Grid>
            <Grid item>
              <Button onClick={toClose}>X</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField error={errors.team !== null} value={theMS.team.name} variant='outlined' label='Team' />
                {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  error={errors.member !== null}
                  value={`${theMS.member.user.first_name} ${theMS.member.user.last_name}`}
                  variant='outlined'
                  label='Member'
                />
                {errors.member !== null ? <SmallError>{errors.member}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  error={errors.plan !== null}
                  options={membershipsData !== undefined ? membershipsData : [{ name: 'No MS data' }]}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField {...params} label='Membership Plan' variant='outlined' error={errors.plan !== null} />
                  )}
                  onChange={(e, data) => {
                    if (data !== null) {
                      setMSData({
                        ...theMS,
                        plan: data,
                        free_credits: data.free_credits,
                        free_days: data.free_days,
                        price: data.base_price,
                      })
                    } else {
                      setMSData({
                        ...theMS,
                        plan: null,
                        free_credits: '',
                        free_days: 0,
                        price: 0,
                      })
                    }
                  }}
                />
                {errors.plan !== null ? <SmallError>{errors.plan}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theMS.start}
                  label='Date Start'
                  format='yyyy-MM-dd'
                  autoOk
                  inputVariant='outlined'
                  onChange={value => setMSData({ ...theMS, start: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theMS.end}
                  label='Date End'
                  format='yyyy-MM-dd'
                  autoOk
                  inputVariant='outlined'
                  onChange={value => setMSData({ ...theMS, end: value })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ marginLeft: '15px', marginTop: '-7px' }}>Recurrent</InputLabel>
                <Select
                  label='Recurrent'
                  value={theMS.recurrent}
                  variant='outlined'
                  onChange={value => {
                    let response = true
                    if (value === 'false') response = false
                    setMSData({ ...theMS, recurrent: response })
                  }}
                >
                  <MenuItem value='true'>Yes</MenuItem>
                  <MenuItem value='false'>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ marginLeft: '15px', marginTop: '-7px' }}>Status</InputLabel>
                <Select
                  label='Status'
                  value={theMS.status}
                  variant='outlined'
                  onChange={e => setMSData({ ...theMS, status: e.target.value })}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='active'>Active</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='cancelled'>Cancel</MenuItem>
                  <MenuItem value='finished'>Finish</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  value={theMS.numUnits}
                  label='Units'
                  type='number'
                  variant='outlined'
                  onChange={e => setMSData({ ...theMS, numUnits: e.target.value >= 0 ? e.target.value : 0 })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Free Days'
                  type='number'
                  value={theMS.free_days}
                  onChange={e => setMSData({ ...theMS, free_days: e.target.value >= 0 ? e.target.value : 0 })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField variant='outlined' label='Credits' value={theMS.free_credits} />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Price'
                  type='number'
                  value={theMS.price}
                  onChange={e => setMSData({ ...theMS, price: e.target.value >= 0 ? e.target.value : 0 })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='center'>
            <Grid item style={{ marginTop: '20px' }}>
              <Button variant='contained' color='secondary' onClick={() => validate()}>
                Create Membership Plan
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateMS.propTypes = {
  toClose: PropType.func,
  m: PropType.object,
  t: PropType.object,
  office: PropType.object,
}

ModalCreateMS.defaultProps = {
  toClose: () => {},
  m: {},
  t: {},
  office: {},
}

export default ModalCreateMS
