import React from 'react'
import PropTypes from 'prop-types'
import Selector from 'components/atoms/selector'
import Breadcrumb from 'components/molecules/breadcrumb'
import { Container, Content, Header, Sidebar } from './styled'

const LayoutView = ({
  children,
  theme,
  officesOptions,
  onChangeOffice,
  sections,
  selectOffice,
  breadcrumb,
  office,
  activeClient,
}) => (
  <Container>
    <Sidebar />
    <Content>
      <Header>
        {selectOffice && (
          <Selector
            value={office?.slug}
            type='title'
            color={theme.palette.primary.main}
            options={officesOptions}
            onChange={onChangeOffice}
          />
        )}
        {breadcrumb && <Breadcrumb />}
      </Header>
      {React.cloneElement(children, { office, theme, sections, activeClient })}
    </Content>
  </Container>
)

LayoutView.defaultProps = {
  children: <></>,
  officesOptions: [],
  sections: [],
  office: null,
  breadcrumb: true,
  activeClient: null,
}

LayoutView.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object.isRequired,
  officesOptions: PropTypes.array,
  sections: PropTypes.array,
  onChangeOffice: PropTypes.func.isRequired,
  office: PropTypes.object,
  selectOffice: PropTypes.bool.isRequired,
  breadcrumb: PropTypes.bool,
  activeClient: PropTypes.object,
}

export default LayoutView
