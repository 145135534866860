import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import Login from 'components/pages/login'
import Dashboard from 'components/pages/dashboard'
import Members from 'components/pages/members'
import Profile from 'components/pages/profile'
import MembersAdd from 'components/pages/membersAdd'
import InvoiceAdd from 'components/pages/invoiceAdd'
import MembershipsAdd from 'components/pages/membershipsAdd'
import ResourcesAdd from 'components/pages/resourcesAdd/resourcesAdd'
import ResourcesAddWA from 'components/pages/resourcesAdd/resourcesAddWA'
import MemberProfile from 'components/organisms/members/memberSingle'
import TeamProfile from 'components/organisms/teams/teamSingle'
import GuestSingle from 'components/organisms/guests/guestSingle'
import InvoiceProfile from 'components/organisms/invoices/invoiceSingle'
import MembershipProfile from 'components/organisms/membershipSingle/membershipSingle'
import ResourcesSingle from 'components/organisms/resources/resourcesSingle'
import SpaceDashboard from 'components/pages/space/spaceDashboard'
import BookingsList from 'components/pages/newBookings/bookingsList'
import WorkAreaSingle from 'components/organisms/workAreas/workAreaSingle'
import BookingSingle from 'components/pages/newBookings/bookingSingle'
import CommunityDashboard from 'components/pages/community/communityDashboard'
import FoodAndDrinksDashboard from 'components/pages/foodanddrinks/foodAndDrinksDashboard'
import CreateBookingDashboard from 'components/pages/newBookings/createBookingDashboard'
import CreateBookingMeeting from 'components/organisms/bookings/CreateBookingMeeting'
import BookingCreate from 'components/organisms/bookings/bookingCreate'
import OffersAdd from 'components/pages/offersAdd'
import OfferUserAdd from 'components/pages/offerUserAdd'
import Marketing from 'components/pages/marketing/'
import CampaignSingle from 'components/organisms/campaigns/campaignSingle'
import FADOrderSingle from 'components/organisms/foodAndDrinks/fadOrderSingle'
import SignUp from 'components/pages/signUp/signUp'
import OnBoarding from 'components/pages/signUp/onBoarding'
import UpdateWA from 'components/pages/resourcesAdd/updateWA'
import CheckMail from 'components/pages/checkMail/checkMail'
import Error404 from 'components/pages/errors/error404'
import RouteWrapper from './RouterWrapper'

const Routes = props => {
  const { office, sections, activeClient } = props
  const location = useLocation()
  const theUrl = location.pathname?.split('/')
  const loggedIn = localStorage.getItem('token')
  const permitedUrls = sections

  console.log(props)
  console.log(theUrl[1])
  return (
    <Switch>
      <RouteWrapper exact path={routes.login} component={Login} layoutType='noLogin' />
      <RouteWrapper path={routes.signUp} component={SignUp} layoutType='noLogin' />
      <RouteWrapper path={routes.checkMail} component={CheckMail} layoutType='noLogin' selectOffice={false} />
      <Route exact path='/'>
        {loggedIn ? <Redirect to={routes.dashboard} /> : <Redirect to={routes.login} />}
      </Route>
      <Route exact path={routes.fandd}>
        {loggedIn ? <Redirect to={routes.foodAndDrinks} /> : <Redirect to={routes.login} />}
      </Route>
      <RouteWrapper
        exact
        path={routes.error404}
        component={Error404}
        layoutType='noLogin'
        breadcrumb={false}
        selectOffice={false}
      />

      <RouteWrapper
        exact
        path={[routes.dashboard, routes.dashboardBookings, routes.dashboardOccupation, routes.dashboardRevenue]}
        component={Dashboard}
        layoutType='logged'
      />
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={[routes.bookings, routes.bookingsList, routes.bookingsAddMeeting]}
          component={CreateBookingDashboard}
          layoutType='logged'
          office={office}
          activeClient={activeClient}
          breadcrumb={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}

      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.bookingsList}
          component={BookingsList}
          layoutType='logged'
          office={office}
          activeClient={activeClient}
          breadcrumb={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.membersAdd}
          component={MembersAdd}
          layoutType='logged'
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.invoiceAdd}
          component={InvoiceAdd}
          layoutType='logged'
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper path={routes.onBoarding} component={OnBoarding} layoutType='noLogin' selectOffice={false} />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.membersMembershipAdd}
          component={MembershipsAdd}
          layoutType='logged'
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.spacesAdd}
          component={ResourcesAdd}
          layoutType='logged'
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.spacesAddWA}
          component={ResourcesAddWA}
          layoutType='logged'
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.spacesWAUpdate}
          component={UpdateWA}
          layoutType='logged'
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path={routes.bookingsAddDesk}
          component={BookingCreate}
          layoutType='logged'
          selectOffice
          office={office}
          activeClient={activeClient}
          breadcrumb={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path={routes.bookingsAddMeeting}
          component={CreateBookingMeeting}
          layoutType='logged'
          selectOffice
          office={office}
          activeClient={activeClient}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {console.log(activeClient)}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.bookingsAdd}
          component={CreateBookingDashboard}
          layoutType='logged'
          selectOffice
          office={office}
          selectActiveClient
          activeClient={activeClient}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path='/bookings/:code'
          component={BookingSingle}
          layoutType='logged'
          selectOffice
          office={office}
          activeClient={activeClient}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.offerAdd}
          component={OffersAdd}
          layoutType='logged'
          selectOffice
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.offerUserAdd}
          component={OfferUserAdd}
          layoutType='logged'
          selectOffice
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path={routes.spaces}
          component={SpaceDashboard}
          layoutType='logged'
          breadcrumb={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes('users') ? (
        <RouteWrapper
          exact
          path={[
            routes.members,
            routes.membersCompanies,
            routes.membersInvoices,
            routes.membersMembership,
            routes.membersGuests,
          ]}
          component={Members}
          layoutType='logged'
          breadcrumb={false}
          selectOffice
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes('fandd') ? (
        <RouteWrapper
          exact
          path={[
            routes.foodAndDrinks,
            routes.foodAndDrinksStock,
            routes.foodAndDrinksBar,
            routes.foodAndDrinksCategories,
          ]}
          office={office}
          selectOffice
          layoutType='logged'
          component={FoodAndDrinksDashboard}
          breadcrumb={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes('fandd') ? (
        <RouteWrapper
          path={`${routes.foodAndDrinks}/:slug`}
          component={FADOrderSingle}
          layoutType='logged'
          breadcrumb
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path={routes.community}
          office={office}
          selectOffice
          layoutType='logged'
          component={CommunityDashboard}
          breadcrumb={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/members/memberships/:slug'
          component={MembershipProfile}
          layoutType='logged'
          breadcrumb
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/members/companies/:slug'
          component={TeamProfile}
          layoutType='logged'
          breadcrumb
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/members/guests/:slug'
          component={GuestSingle}
          layoutType='logged'
          breadcrumb
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/members/invoices/:slug'
          component={InvoiceProfile}
          layoutType='logged'
          breadcrumb
          office={office}
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path={routes.memberProfile}
          component={MemberProfile}
          layoutType='logged'
          breadcrumb
          selectOffice={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes('account') ? (
        <RouteWrapper path={routes.profile} component={Profile} layoutType='logged' breadcrumb={false} />
      ) : (
        <Redirect to={routes.error404} />
      )}

      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper path='/resources/:slug' component={ResourcesSingle} layoutType='logged' breadcrumb={false} />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/work_area/:slug'
          component={WorkAreaSingle}
          layoutType='logged'
          breadcrumb={false}
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}

      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          path='/bookings/:slug'
          component={BookingSingle}
          layoutType='logged'
          breadcrumb={false}
          office={office}
          selectOffice={false}
          activeClient={activeClient}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      <RouteWrapper path='/campaigns/:slug' component={CampaignSingle} layoutType='logged' breadcrumb={false} />
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path='/marketing/offer_user/:slug'
          component={OfferUserAdd}
          layoutType='logged'
          selectOffice={false}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      <RouteWrapper exact path='/marketing/offer_user' component={Marketing} layoutType='logged' breadcrumb={false} />
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper
          exact
          path={routes.marketingOffer}
          component={Marketing}
          layoutType='logged'
          breadcrumb={false}
          office={office}
        />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper exact path='/marketing/:slug' component={OffersAdd} layoutType='logged' selectOffice={false} />
      ) : (
        <Redirect to={routes.error404} />
      )}
      {permitedUrls.includes(theUrl[1]) ? (
        <RouteWrapper exact path={routes.marketing} component={Marketing} layoutType='logged' breadcrumb={false} />
      ) : (
        <Redirect to={routes.error404} />
      )}
    </Switch>
  )
}

Routes.propTypes = {
  office: PropTypes.object,
  sections: PropTypes.array,
  activeClient: PropTypes.object,
}

Routes.defaultProps = {
  office: null,
  sections: [],
  activeClient: null,
}

export default Routes
