import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Modal } from '@material-ui/core'
import { getProfileInvoice, getSyncInvoice, getValidateInvoice } from 'api'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ModalCreateInvoiceLine from 'components/organisms/invoices/modalCreateInvoiceLine'
import PropTypes from 'prop-types'
import { colors } from 'config/colors'
import formatWords from 'components/atoms/formatWords/formatWords'
import InvoicesLinesList from './invoicesLinesList'
import { ProfileContainer, ProfileInfoContent } from '../members/profileStyle'
import { AddressText, DateText } from './invoicesStyles'
import { SpinnerContainer } from '../members/newListStyle'
import ModalUpdateInvoice from './modalUpdateInvoice'

function InvoiceProfile({ office }) {
  const params = useParams()
  const [theInvoice, setInvoice] = useState()
  // eslint-disable-next-line no-unused-vars
  const [selectedLine, setSelectedLine] = useState()
  const { slug } = params
  const { t } = useTranslation()
  const [modal, setModal] = useState(false)
  const [modalInvoice, setModalInvoice] = useState(false)

  function getDataInvoice() {
    getProfileInvoice(slug)
      .then(invoice => {
        setInvoice(invoice)
      })
      .catch(error => console.log(error))
  }

  function reloadPage() {
    window.location.reload(false)
  }

  function syncInvoice() {
    const invoice = {
      invoice: theInvoice.name,
    }
    getSyncInvoice(invoice).then(reloadPage())
  }

  function validateInvoice() {
    const invoice = {
      invoice: theInvoice.name,
    }
    getValidateInvoice(invoice).then(reloadPage())
  }

  useEffect(() => {
    if (theInvoice === undefined) {
      getDataInvoice()
    }
  }, [theInvoice])

  function handleClose() {
    setModal(false)
  }

  function handleCloseInvoice() {
    setModalInvoice(false)
  }

  if (theInvoice !== undefined) {
    const invoiceDate = theInvoice?.date?.split('T')
    const dateClosed = theInvoice?.date_closed?.split('T')
    const dateDue = theInvoice?.date_due?.split('T')
    const dateSend = theInvoice?.date_sent?.split('T')
    return (
      <ProfileContainer>
        <Modal open={modal} onClose={handleClose}>
          <ModalCreateInvoiceLine office={office.slug} invoice={theInvoice} line={selectedLine} toClose={handleClose} />
        </Modal>
        <Modal open={modalInvoice} onClose={handleCloseInvoice}>
          <ModalUpdateInvoice office={office.slug} invoice={theInvoice} toClose={handleCloseInvoice} />
        </Modal>
        <Grid container direction='column' spacing={2} style={{ borderBottom: '1px solid grey', marginBottom: '25px' }}>
          <Grid item>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              spacing={2}
              style={{ marginBottom: '10px', marginRight: '15px' }}
            >
              <Grid item>
                <Grid container direction='column'>
                  <Grid
                    item
                    style={{
                      fontSize: '35px',
                      fontWeight: 500,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    Invoice #{theInvoice?.name}
                  </Grid>
                  <Grid item>
                    <AddressText>Status:</AddressText>&nbsp;
                    <DateText>{theInvoice?.status !== 'open' ? formatWords(theInvoice?.status) : 'Draft'}</DateText>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                {theInvoice.status !== 'paid' ? (
                  <Grid container>
                    <Button
                      variant='contained'
                      color='secondary'
                      style={{ margin: '10px ' }}
                      onClick={() => syncInvoice()}
                    >
                      Sync
                    </Button>
                    <Button
                      color='secondary'
                      variant='contained'
                      style={{ margin: '10px ' }}
                      onClick={() => validateInvoice()}
                    >
                      Validate
                    </Button>
                    <Button
                      color='success'
                      variant='contained'
                      style={{ margin: '10px ' }}
                      onClick={() => {
                        setSelectedLine(null)
                        setModal(true)
                      }}
                    >
                      {t('invoices.form.addLine')}
                    </Button>
                    <Button
                      color='success'
                      style={{ margin: '10px ' }}
                      variant='contained'
                      onClick={() => {
                        setModalInvoice(true)
                      }}
                    >
                      {t('invoices.form.editInvoice')}
                    </Button>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            justify='space-between'
            spacing={2}
            direction='row'
            style={{ borderBottom: '1px solid grey', marginBottom: '5px' }}
          >
            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item style={{ fontWeight: 500, color: colors.darkgray }}>
                  {theInvoice?.team?.name}
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {theInvoice?.invoice_data?.address_1 ? theInvoice?.invoice_data?.address_1 : '-'}
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {theInvoice?.invoice_data?.address_2 ? theInvoice?.invoice_data?.address_2 : '-'}
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {`${theInvoice?.invoice_data?.town ? theInvoice?.invoice_data?.town : '-'} (${
                    theInvoice?.invoice_data?.country ? theInvoice?.invoice_data?.country : '-'
                  })`}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Date:</AddressText>&nbsp;<DateText>{invoiceDate ? invoiceDate[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Due:</AddressText>&nbsp;<DateText>{dateDue ? dateDue[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Send:</AddressText>&nbsp;<DateText>{dateSend ? dateSend[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Closed:</AddressText>&nbsp;<DateText>{dateClosed ? dateClosed[0] : '-'}</DateText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Type:</AddressText>&nbsp;<DateText>{formatWords(theInvoice?.invoice_type)}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Description:</AddressText>&nbsp;<DateText>{theInvoice?.short_description}</DateText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Payment Ref:</AddressText>&nbsp;
                  <DateText>{theInvoice.payment_reference !== null ? theInvoice.payment_reference : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Xero id:</AddressText>&nbsp;<DateText>£{theInvoice?.xero_id}</DateText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ProfileInfoContent>
          <InvoicesLinesList
            editInvoice={line => {
              setSelectedLine(line)
              setModal(true)
            }}
            invoice={theInvoice}
            invoicesLines={theInvoice?.invoice_lines}
          />
        </ProfileInfoContent>
      </ProfileContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

InvoiceProfile.propTypes = {
  office: PropTypes.object,
}

InvoiceProfile.defaultProps = {
  office: null,
}

export default InvoiceProfile
