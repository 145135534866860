import React from 'react'
import PropTypes from 'prop-types'
import StatCard from 'components/molecules/statCard'
import { MEASURES } from 'config/constants'
import { CircularProgress } from '@material-ui/core'
import { SpinnerContainer } from 'components/template/layout/styled'
import { Container, DashboardInfoList } from './styled'

function DashboardInfoView({ data }) {
  const DashBoardView = (
    <Container>
      <DashboardInfoList>
        {data.occupancy && <StatCard {...data.occupancy} isLoading={data[MEASURES.occupancy].isLoading} />}
        {data.bookings && <StatCard {...data.bookings} isLoading={data[MEASURES.bookings].isLoading} />}
        {data.bookings_mr && <StatCard {...data.bookings_mr} isLoading={data[MEASURES.bookings_mr].isLoading} />}
        {data.members && <StatCard {...data.members} isLoading={data[MEASURES.members].isLoading} />}
        {data.financial && <StatCard {...data.financial} isLoading={data[MEASURES.financial].isLoading} />}
        {data.memberships && <StatCard {...data.memberships} isLoading={data[MEASURES.memberships].isLoading} />}
      </DashboardInfoList>
    </Container>
  )
  if (data !== undefined) return DashBoardView
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

DashboardInfoView.propTypes = {
  data: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired,
}

DashboardInfoView.defaultProps = {}

export default DashboardInfoView
