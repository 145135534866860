import { useEffect, useState } from 'react'
import {
  getMembersByTeam,
  getTeamProfile,
  newGetTeams,
  setBooking,
  getMeetingRoomAvailability,
  updateBooking,
  getGuests,
  getGuestByTeam,
  getClientsReferral,
} from 'api'
import { colors } from 'config/colors'
import PropTypes from 'prop-types'
import { Grid, FormControl, TextField, Button, Checkbox, Modal } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { Autocomplete } from '@material-ui/lab'
import formatDates from 'components/atoms/formatDates/formatDates'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalSchedulerBooking({ closeModal, office, resourceData, reloadBookings }) {
  console.log('Uploaded content', resourceData?.uploadContent)
  const [theBooking, setBookingData] = useState({
    code: resourceData?.uploadContent?.code === undefined ? null : resourceData?.uploadContent?.code,
    client: resourceData?.uploadContent?.client === undefined ? null : resourceData?.uploadContent?.client,
    team: resourceData?.uploadContent?.team === undefined ? null : resourceData?.uploadContent?.team,
    member: resourceData?.uploadContent?.member === undefined ? null : resourceData?.uploadContent?.member,
    guest: resourceData?.uploadContent?.guest === undefined ? null : resourceData?.uploadContent?.guest,
    subject: null,
    complimentary: 'NO',
    resource: resourceData.resource,
    price: resourceData.uploadContent?.single_price === undefined ? resourceData.uploadContent?.single_price : 0,
    use_credits: 'NO',
    guest_name: '',
    guest_surname: '',
    guest_email: '',
  })
  const [errors, setErrors] = useState({
    start: null,
    end: null,
    client: null,
    team: null,
    member: null,
    guest: null,
    bookedStart: null,
    bookedEnd: null,
    badTime: null,
    guest_name: null,
    guest_surname: null,
    guest_email: null,
  })
  const [singleTeam, setSingleTeamData] = useState()
  const [fbModal, setfbModal] = useState(false)
  const [fbModalUpdate, setfbModalUpdate] = useState(false)
  const [confirmCancel, setCancelBtn] = useState(false)
  const [dataTeams, setTeamsData] = useState()
  const [dataClients, setClientData] = useState()
  const [dataMembers, setMembersData] = useState()
  const [dataGuest, setGuestsData] = useState()

  function getSingleTeamData() {
    getTeamProfile(theBooking.team.slug).then(r => setSingleTeamData(r))
  }

  function getMeetingRoomAvailabilityData(value, type) {
    const query = {
      resource: resourceData.resource,
      code: theBooking.code,
      start:
        theBooking.start === undefined
          ? formatDates(resourceData.start, 'time')
          : formatDates(theBooking.start, 'time'),
      end: theBooking.end === undefined ? formatDates(resourceData.end, 'time') : formatDates(theBooking.end, 'time'),
    }
    if (type === 'start') query.start = formatDates(value, 'time')
    if (type === 'end') query.end = formatDates(value, 'time')
    if (query.start > query.end) {
      setErrors({
        ...errors,
        badTime: 'End time cant be before start time',
      })
    }
    if (new Date(query.start) < new Date(query.end)) {
      setErrors({
        ...errors,
        badTime: null,
      })
      console.log('query before check ', query)
      getMeetingRoomAvailability(query).then(r => {
        if (r.status === 'busy') {
          setErrors({
            ...errors,
            bookedStart: 'Meeting room busy at that time',
            bookedEnd: 'Meeting room busy at that time',
          })
        }
        if (r.status === 'free') setErrors({ ...errors, bookedStart: null, bookedEnd: null })
        setBookingData({
          ...theBooking,
          price: r.price.toFixed(2),
          credits: r.credits.toFixed(2),
          start: type === 'start' ? value : theBooking.start,
          end: type === 'end' ? value : theBooking.end,
          resource: resourceData.resource,
          available_credits: r.available_credits,
        })
      })
    }
  }

  function getDataClients(data) {
    const query = {
      pageSize: 100,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => setClientData(r))
  }

  function getDataTeams(data) {
    const query = {
      pageSize: 100,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => setTeamsData(r))
  }

  function getDataMembers(data) {
    if (data !== undefined) getMembersByTeam(data).then(r => setMembersData(r))
  }

  function getDataGuests(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        layoutOffice: office.slug,
      },
    }
    if (data !== undefined) getGuestByTeam(data).then(r => setGuestsData(r))
    else getGuests(query).then(r => setGuestsData(r.guests))
  }

  function createBooking() {
    setfbModal(true)
    const bookingsArr = []
    const booking = {
      start: { dateTime: formatDates(theBooking.start, 'time') },
      end: { dateTime: formatDates(theBooking.end, 'time') },
      payNow: false,
      booking_type: 'meeting_room',
      member: theBooking?.member?.slug || '',
      team: theBooking?.team?.slug || '',
      resource: resourceData.resource,
      price: theBooking.price ? theBooking.price.toString() : '0',
      credits: theBooking.credits ? theBooking.credits.toString() : '0',
      use_credits: '',
      guest: theBooking?.guest?.slug || '',
      guest_name: theBooking.guest_name,
      guest_surname: theBooking.guest_surname,
      guest_email: theBooking.guest_email,
      client: theBooking?.client?.slug,
    }
    if (theBooking.complimentary === 'YES') booking.deference = true
    if (theBooking.complimentary === 'NO') booking.deference = false
    if (theBooking.use_credits === 'YES') booking.use_credits = true
    if (theBooking.use_credits === 'NO') booking.use_credits = false

    bookingsArr.push(booking)
    setBooking(bookingsArr)
      .then(() => {
        console.log('Booking success')
        setfbModalUpdate(false)
        reloadBookings()
      })
      .catch(() => {
        console.log('Error')
      })
  }

  function updateTheBooking(data) {
    const booking = {
      planned_start: formatDates(theBooking.start, 'time'),
      planned_end: formatDates(theBooking.end, 'time'),
      payNow: false,
      booking_type: 'meeting_room',
      member: theBooking?.member?.slug || '',
      team: theBooking?.team?.slug || '',
      resource: resourceData.resource,
      price: theBooking.price ? theBooking.price.toString() : resourceData.uploadContent.price,
      credits: theBooking.credits ? theBooking.credits.toString() : '0',
      use_credits: '',
      guest_name: theBooking.guest_name,
      guest_surname: theBooking.guest_surname,
      guest_email: theBooking.guest_email,
      client: theBooking.client?.slug,
    }
    if (theBooking.complimentary === 'YES') booking.deference = true
    if (theBooking.complimentary === 'NO') booking.deference = false
    if (theBooking.use_credits === 'YES') booking.use_credits = true
    if (theBooking.use_credits === 'NO') booking.use_credits = false
    if (data === 'cancel') booking.status = 'cancelled'

    updateBooking(resourceData.uploadContent.code, booking)
      .then(() => {
        console.log('Booking updated')
        setfbModal(false)
        reloadBookings()
      })
      .catch(() => {
        console.log('Error')
      })
  }

  function validate(data) {
    let canCreate = false
    const textErrors = {
      start: 'Start time is required',
      end: 'End time is required',
      room: 'Room is required',
      client: 'Client is required',
      team: 'Team is required',
      member: 'Member is required',
      guest: 'Guest is required',
      badChoose: 'Select referral, team, guest or guest mail is mandatory',
      guest_name: 'Name is required',
      guest_surname: 'Surname is required',
      guest_email: 'Email is required',
      badMail: 'Must be a valid email (example@mail.com)',
      price: 'Must have a positive value',
    }

    const startRes = data.start === null || data.start === undefined || data.start === '' ? textErrors.start : null
    const endRes = data.end === null || data.end === undefined || data.end === '' ? textErrors.end : null
    const memberRes = null
    let nameRes = data.guest_name === null || data.guest_name === '' ? textErrors.guest_name : null
    let surnameRes = data.guest_surname === null || data.guest_surname === '' ? textErrors.guest_surname : null
    const priceRes = data.price === null || data.price === '' || data.price <= 0 ? textErrors.price : null

    let emailRes = data.guest_email === null || data.guest_email === '' ? 'empty' : null
    let clientRes = data.client === null || data.client === undefined || data.client === '' ? 'empty' : null
    let teamRes = data.team === null || data.team === undefined || data.team === '' ? 'empty' : null
    let guestRes = data.guest === null || data.guest === undefined || data.guest === '' ? 'empty' : null

    if (clientRes === 'empty' && teamRes === 'empty' && guestRes === 'empty' && emailRes === 'empty') {
      teamRes = textErrors.badChoose
      clientRes = textErrors.badChoose
      guestRes = textErrors.badChoose
      emailRes = textErrors.badChoose
    } else {
      clientRes = null
      teamRes = null
      guestRes = null
      emailRes = null
    }

    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const testMail = regex.test(data.guest_email)
    emailRes = testMail === false ? textErrors.badMail : null

    if (theBooking.team !== null || theBooking.client !== null) {
      emailRes = null
      teamRes = null
      clientRes = null
      guestRes = null
      nameRes = null
      surnameRes = null
    }

    setErrors({
      start: startRes,
      end: endRes,
      client: clientRes,
      team: teamRes,
      member: memberRes,
      bookedStart: errors.bookedStart,
      bookedEnd: errors.bookedEnd,
      badTime: errors.badTime,
      guest: guestRes,
      guest_name: nameRes,
      guest_surname: surnameRes,
      guest_email: emailRes,
      price: priceRes,
    })

    if (
      startRes === null &&
      endRes === null &&
      teamRes === null &&
      clientRes === null &&
      memberRes === null &&
      errors.bookedStart === null &&
      errors.bookedEnd === null &&
      errors.badTime === null &&
      nameRes === null &&
      surnameRes === null &&
      emailRes === null &&
      priceRes === null &&
      guestRes === null
    ) canCreate = true

    console.log(canCreate)
    console.log(resourceData.uploadContent)
    if (canCreate === true) {
      if (resourceData.uploadContent.code !== undefined) updateTheBooking()
      else createBooking()
    }
  }

  useEffect(() => {
    getMeetingRoomAvailabilityData()
  }, [resourceData])

  useEffect(() => {
    if (theBooking.team === undefined) setBookingData({ ...theBooking, team: null })
    if (theBooking.start === undefined) setBookingData({ ...theBooking, start: resourceData.start, end: resourceData.end })
  }, [theBooking])

  useEffect(() => {
    if (theBooking.team !== undefined && theBooking.team !== null && singleTeam === undefined) getSingleTeamData()
  }, [singleTeam])

  useEffect(() => {
    if (dataClients === undefined) getDataClients()
    if (dataTeams === undefined) getDataTeams()
    if (dataGuest === undefined) getDataGuests()
  }, [dataClients, dataTeams, dataMembers, dataGuest])

  useEffect(() => {}, [errors])

  let guestSplit = []
  if (theBooking.guest !== null) guestSplit = theBooking.guest?.name?.split(' ')

  return (
    <ModalCard>
      <Modal open={fbModal}>
        <FeedbackModal type='booking' />
      </Modal>
      <Modal open={fbModalUpdate}>
        <FeedbackModal type='booking-update' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '15px' }}>
            <Grid item>
              <ModalTitle>
                {resourceData.uploadContent?.member === undefined ? 'New booking' : 'Update booking'}
              </ModalTitle>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={2}>
                <Grid item>
                  <Button variant='outlined' onClick={() => closeModal()}>
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label='Subject'
              variant='outlined'
              onChange={e => setBookingData({ ...theBooking, subject: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  name='autocomplete-clients'
                  value={
                    resourceData.uploadContent?.client === undefined
                      ? theBooking.client
                      : resourceData.uploadContent?.client
                  }
                  options={dataClients !== undefined ? dataClients.clients : [{ name: 'Type something...' }]}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField {...params} label='Referral' variant='outlined' error={errors.client !== null} />
                  )}
                  onChange={(e, data) => {
                    if (e !== undefined && data !== undefined) {
                      getDataClients(e.target.value)
                      setBookingData({ ...theBooking, client: data })
                    } else {
                      getDataClients()
                      setBookingData({ ...theBooking, client: null })
                    }
                    return ''
                  }}
                  onInputChange={(e, data) => getDataClients(data)}
                  error={errors.client !== null}
                />
                {errors.client !== null ? <SmallError>{errors.client}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  name='autocomplete-teams'
                  value={
                    resourceData.uploadContent?.team === undefined ? theBooking.team : resourceData.uploadContent?.team
                  }
                  options={dataTeams !== undefined ? dataTeams.teams : [{ name: 'No data team' }]}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField {...params} label='Team' variant='outlined' error={errors.team !== null} />
                  )}
                  onChange={(e, data) => {
                    if (e !== undefined && data !== undefined) {
                      getDataTeams(e.target.value)
                      getDataMembers(data?.slug)
                      getDataGuests(data?.slug)
                      setBookingData({ ...theBooking, team: data })
                    } else {
                      getDataTeams()
                      setBookingData({ ...theBooking, team: null })
                    }
                    return ''
                  }}
                  onInputChange={(e, data) => getDataTeams(data)}
                  error={errors.team !== null}
                />
                {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  disabled={theBooking.team === null}
                  value={
                    resourceData.uploadContent?.member === undefined
                      ? theBooking.member
                      : resourceData.uploadContent?.member
                  }
                  name='autocomplete-members'
                  error={errors.member !== null}
                  options={dataMembers !== undefined ? dataMembers : [{ name: 'No data Member' }]}
                  getOptionLabel={option =>
                    option?.user?.first_name !== undefined
                      ? `${option?.user?.first_name} ${option?.user?.last_name}`
                      : 'Member'
                  }
                  renderInput={params => (
                    <TextField {...params} label='Member' variant='outlined' error={errors.member !== null} />
                  )}
                  onChange={(e, data) => {
                    if (e !== undefined && data !== undefined) {
                      setBookingData({ ...theBooking, member: data })
                    } else {
                      setBookingData({ ...theBooking, member: null })
                    }
                  }}
                />
                {errors.member !== null ? <SmallError>{errors.member}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  value={
                    resourceData.uploadContent?.member === undefined
                      ? theBooking.guest
                      : resourceData.uploadContent?.guest
                  }
                  name='autocomplete-guest'
                  error={errors.guest !== null}
                  options={dataGuest !== undefined ? dataGuest : [{ name: 'No data Guest' }]}
                  getOptionLabel={option => `${option?.name}`}
                  renderInput={params => (
                    <TextField {...params} label='Guest' variant='outlined' error={errors.guest !== null} />
                  )}
                  onChange={(e, data) => {
                    if (data !== null) {
                      const g = data?.name?.split(' ')
                      setBookingData({
                        ...theBooking,
                        guest: data,
                        guest_name: g[0],
                        guest_email: data.email,
                        guest_surname: g[1],
                      })
                    } else {
                      setBookingData({
                        ...theBooking,
                        guest: null,
                        name: '',
                        email: '',
                        guest_name: '',
                        guest_email: '',
                        guest_surname: '',
                      })
                    }
                  }}
                />
                {errors.guest !== null ? <SmallError>{errors.guest}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  readOnly={theBooking.guest !== null}
                  variant='outlined'
                  label='Guest Name'
                  value={theBooking.guest !== null ? guestSplit[0] : null}
                  onChange={e => setBookingData({ ...theBooking, guest_name: e.target.value })}
                  error={errors.guest_name !== null}
                />
                {errors.guest_name !== null ? <SmallError>{errors.guest_name}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  readOnly={theBooking.guest !== null}
                  label='Guest Surname'
                  value={theBooking.guest !== null ? guestSplit[1] : null}
                  onChange={e => setBookingData({ ...theBooking, guest_surname: e.target.value })}
                  error={errors.guest_surname !== null}
                />
                {errors.guest_surname !== null ? <SmallError>{errors.guest_surname}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  readOnly={theBooking.guest !== null}
                  value={theBooking.guest !== null ? theBooking.guest_email : null}
                  label='Guest Email'
                  onChange={e => setBookingData({ ...theBooking, guest_email: e.target.value })}
                  error={errors.guest_email !== null}
                  type='mail'
                />
                {errors.guest_email !== null ? <SmallError>{errors.guest_email}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='Start'
                  inputVariant='outlined'
                  minutesStep={5}
                  onChange={value => {
                    setBookingData({ ...theBooking, start: value })
                    getMeetingRoomAvailabilityData(value, 'start')
                  }}
                  value={theBooking?.start === undefined ? resourceData.start : theBooking.start}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  error={errors.start !== null || errors.bookedStart !== null}
                />
                {errors.start !== null ? <SmallError>{errors.start}</SmallError> : ''}
                {errors.bookedStart !== null ? <SmallError>{errors.bookedStart}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  inputVariant='outlined'
                  label='End'
                  minutesStep={5}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='outlined'
                      error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                    />
                  )}
                  onChange={value => {
                    setBookingData({ ...theBooking, end: value })
                    getMeetingRoomAvailabilityData(value, 'end')
                  }}
                  value={theBooking?.end === undefined ? resourceData.end : theBooking.end}
                  error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                />
                {errors.end !== null ? <SmallError>{errors.end}</SmallError> : ''}
                {errors.bookedEnd !== null ? <SmallError>{errors.bookedEnd}</SmallError> : ''}
                {errors.badTime !== null ? <SmallError>{errors.badTime}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Price'
                  readOnly={false}
                  value={theBooking.price}
                  type='number'
                  variant='outlined'
                  onChange={e => setBookingData({ ...theBooking, price: e.target.value >= 0 ? e.target.value : 0 })}
                >
                  Price
                </TextField>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <FormControl>
                      <Checkbox
                        size='small'
                        checked={theBooking.complimentary === 'YES'}
                        onChange={value =>
                          setBookingData({ ...theBooking, complimentary: value.target.checked ? 'YES' : 'NO' })
                        }
                        name='complimentary'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>Complimentary</Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {theBooking.team !== null ? (
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <Grid container direction='row' justify='space-around'>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    Price: {theBooking.initialPrice}
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    Credits: {theBooking.credits}
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    Credits available: {theBooking.available_credits}
                  </Grid>
                  {theBooking.available_credits >= 0 ? (
                    <Grid item>
                      <Grid container direction='row' alignItems='center'>
                        <Grid item>
                          <FormControl>
                            <Checkbox
                              size='small'
                              onChange={value =>
                                setBookingData({ ...theBooking, use_credits: value.target.checked ? 'YES' : 'NO' })
                              }
                              name='complimentary'
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>Use Credits</Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Grid item justify='center'>
          <Grid container direction='row' justify='space-between' style={{ marginTop: '15px' }}>
            <Grid item>
              {resourceData.uploadContent?.member === undefined ? (
                ''
              ) : (
                <Grid container direction='row' spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      style={{ backgroundColor: colors.red, color: 'white' }}
                      onClick={() => (confirmCancel === false ? setCancelBtn(true) : setCancelBtn(false))}
                    >
                      Cancel Booking
                    </Button>
                  </Grid>
                  {confirmCancel === true ? (
                    <Grid item>
                      <Button
                        variant='contained'
                        style={{ backgroundColor: colors.red, color: 'white' }}
                        onClick={() => updateTheBooking('cancel')}
                      >
                        Confirm Cancel
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary' onClick={() => validate(theBooking)}>
                {resourceData.uploadContent?.code === undefined ? 'Create Booking' : 'Update Booking'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalSchedulerBooking.propTypes = {
  closeModal: PropTypes.func,
  rooms: PropTypes.array,
  office: PropTypes.object,
  resourceData: PropTypes.object,
  reloadBookings: PropTypes.func,
}

ModalSchedulerBooking.defaultProps = {
  closeModal: () => {},
  rooms: [],
  office: {},
  resourceData: {},
  reloadBookings: () => {},
}

export default ModalSchedulerBooking
