import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { DO_LOGIN } from 'api/mutations'
import { useHistory } from 'react-router-dom'

import View from './view'

const LoginForm = ({ ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const history = useHistory()
  const mutateLogin = useMutation(DO_LOGIN, {
    onSuccess: () => {
      history.push('/dashboard')
    },
    onError: () => {
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    mutateLogin.mutate(data)
  }

  return <View {...props} onSubmit={onSubmit} />
}

export default LoginForm
